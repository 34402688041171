import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Rekapnota	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 				= useNavigate();
	const uDispatch 				= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") && !uISDemoapp;
	const [uHtml500msg,setHtml500msg]= React.useState(); 
	const [uTimeelapse,setTimeelapse]= React.useState(0); 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 			= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit					= (localStorage.getItem("lastcabangid")||"0")
	const uCabanginitObj 				= useSelector(state => state.gCabangatribut) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--/

	//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--/

	//--DLGFORM--/
	//--END DLGFORM--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLGVIEW--/

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uHTMLstylenota		= useSelector(state => state.gHTMLstyleNota);
	const uLebarKertas			= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri			= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize				= parseInt(uSettingcetakObj.fontsize)||12;
	const uViewLogo				= uSettingcetakObj.view_image_nota ||"TIDAK";
	const uFontstyle			= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikCetaknota = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		initSelectrow(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vDatanotaObj 	= uDatamainArr[vArridx].nota_obj || {};

		const vRowidx		= UFunc.getRo

		if(JSON.stringify(vDatanotaObj)!=="{}") {
			initCetaknota(_IDTABEL); return;
		}

		uDatamainArr[vArridx].init_cetak	= "YA";
		apiLoadcetak(_IDTABEL);
	}
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return
		initSelectrow(_IDTABEL);

		const vArridx			= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vISloaddetil 		= !UFunc.isEmpty(uDatamainArr[vArridx].nota_obj)
		const vCaptionUpper 	= (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase();
		uDlgviewdataObj.header 	= "Detil Nota: "+vCaptionUpper;
		setIDselect(_IDTABEL);

		if(vISloaddetil) {
			uDlgviewdataObj.data_obj 	= uDatamainArr[vArridx].nota_obj || {};
			setDlgviewshow(true);
			return;
		}

		uDatamainArr[vArridx].init_cetak	= "TIDAK";
		apiLoadcetak(_IDTABEL);
	}
	const hdlKlikHapusnota = async(_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return
		initSelectrow(_IDTABEL);

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionUpper = (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase();

		if(await Konfirm("Hapus Nota: <b>"+vCaptionUpper+"</b>"+
			"<br/>Proses Hapus Hanya Dapat Dilakukan SEKALI, dan TIDAK Dapat DIBATALKAN.."+
			"<br/></br>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT_FUNC--/
	const initSelectrow = (_IDTABEL) => {
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);
	}
	const initCetaknota = (_IDTABEL) => {
		const vContent 	= contentCetaknota(_IDTABEL);
		if(vContent === "") return
		//console.log("(Lapkasir - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/
	const contentMain = () => {
		if(uHtml500msg) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_rekapnota || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		/*const vDataviewArr	= isMobile 
			? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));//--*/
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.page||1) === parseInt(uPageActive));
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
				<CCol md="2" className="text-start">{uBahasaObj.caption_tglnota||"Tgl Nota"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.word_nota||"Nota"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.word_pasien||"Pasien"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_nbayar||"N.Bayar"}<small className="d-block">(Rp)</small></CCol>
				<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				tgl_bayar,no_nota,jenis_bayar,pasien_caption,pasien_telp,
				kasirinput,payment_caption,dokter_caption,
				nilai_transaksi,nilai_bayar,id,nama_asuransi,id_asuransi,nilai_asuransi,
				is_prosessing
			} = vItems

			vNumber++;
			const vCaptionUpper		= (pasien_caption||"").toUpperCase();
			const vPasiencaption	= vKeyword==="" ? (pasien_caption||"UNDF") 
				: (pasien_caption||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vPasientelp		= vKeyword==="" ? (pasien_telp||"") 
				: (pasien_telp||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vDoktercaption	= vKeyword==="" ? (dokter_caption||"UNDF") 
				: (dokter_caption||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vPaymentcaption	= vKeyword==="" ? (payment_caption||"Tunai") 
				: (payment_caption||"Tunai").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglbayar			= vKeyword==="" ? UFunc.TglAngka(tgl_bayar) 
				: UFunc.TglAngka(tgl_bayar).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNonota			= vKeyword==="" ? (no_nota||"UNDF") 
				: (no_nota||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vAsuransi			= vKeyword==="" ? (nama_asuransi||"") 
				: (nama_asuransi||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKasirinput		= vKeyword==="" ? UFunc.Usercaptionfilter(kasirinput||"UNDF") 
				: UFunc.Usercaptionfilter(kasirinput||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
					<CCol xs="12" className="px-1 d-md-none text-startt d-flex justify-content-between">
						<b>{UFunc.renderHTML(vNonota)}</b>

						{(is_prosessing||false)===true ? (
						<CSpinner color="primary" size="sm"/>
						) : (
						<CDropdown>
							<CDropdownToggle color="transparent" caret={false} className="p-0">
							<CIcon icon="cilOptions" className="" height={20}/>
							</CDropdownToggle>

							<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikCetaknota(id)} className="classcursorpointer d-lg-none">
								<CIcon className="classikoncetak align-middle mx-0 me-2" height={25}/>
								Cetak Copy Nota
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)} className="classcursorpointer">
								<CIcon icon="cilSearch" className="classikonbox mx-0 me-2" height={25}/>
								Detil Nota
							</CDropdownItem>

							{uIsHapusallow && (
							<CDropdownItem onClick={()=>hdlKlikHapusnota(id)} className="classcursorpointer">
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								Hapus Nota
							</CDropdownItem>
							)}
							</CDropdownMenu>
						</CDropdown>
						)}
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
					
					<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglnota||"Tgl Nota"}</CCol>
					<CCol xs="8" md="2" className="px-1 text-md-start">
						<span className="d-block">{UFunc.renderHTML(vTglbayar)}</span>
						<span className="classfontsmaller d-none d-md-block">
							Kasir: <small className="text-danger">{UFunc.renderHTML(vKasirinput)}</small>
						</span>
					</CCol>
					
					<CCol xs="4" className="px-1 d-md-none text-start">Kasir</CCol>
					<CCol xs="8" className="px-1 d-md-none classfontsmaller">
						<small className="text-danger">{UFunc.renderHTML(vKasirinput)}</small>
					</CCol>

					<CCol xs="4" className="px-1 d-md-none text-start">Jenis Nota</CCol>
					<CCol xs="8" md="2" className="px-1 text-md-start">
						<b className="d-none d-md-block">{UFunc.renderHTML(vNonota)}</b>
						<span className="classfontsmaller text-uppercase">
							{(jenis_bayar||"KASIR")!=="KASIR" ? (
								<small className="text-primary">&middot; {jenis_bayar||"KASIR"} &middot;</small>
							) : (
								<small>&middot; {jenis_bayar||"KASIR"} &middot;</small>
							)}
						</span>
					</CCol>
					
					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_pasien}</CCol>
					<CCol xs="9" md="2" className="px-1 text-md-start">
						<div className="text-info">{UFunc.renderHTML(vPasiencaption)}</div>
						
						{(pasien_telp||"").trim().length >= 3 && (
						<div className="classfontsmaller d-none d-md-block">
							<small>Telp/HP: <b>{UFunc.renderHTML(vPasientelp)}</b></small>
						</div>
						)}
					</CCol>
					
					{(pasien_telp||"").trim().length >= 3 && (
					<>
					<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_telphp||"Telp/HP"}</CCol>
					<CCol xs="8" className="px-1 d-md-none classfontsmaller text-uppercase">
						<small><b>{UFunc.renderHTML(vPasientelp)}</b></small>
					</CCol>
					</>
					)}
					
					<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
					<CCol xs="8" className="px-1 d-md-none">
						<b>{UFunc.renderHTML(vDoktercaption)}</b>
					</CCol>

					<CCol md="3" className="px-1 d-none d-md-block classfontsmaller">
						<div className="d-flex justify-content-between">
							<span>{uBahasaObj.word_dokter||"Dokter"}</span>
							<b>{UFunc.renderHTML(vDoktercaption)}</b>
						</div>
						<div className="my-1 classborderbottom"/>
						<div className="d-flex justify-content-between">
							<span>{uBahasaObj.caption_ntagihan||"N.Tagihan"}</span>
							<span className="text-primary">{UFunc.formatAngka(nilai_transaksi)}</span>
						</div>
						<div className="my-1 classborderbottom"/>
						<div className="d-flex justify-content-between">
						{parseInt(id_asuransi||"0") > 0 ? (
						<>
							<span style={{color:"purple"}}>{uBahasaObj.word_asuransi||"Asuransi"}</span>
							<b style={{color:"purple"}}>{UFunc.renderHTML(vAsuransi)}</b>
						</>
						) : (
						<>
							<span>{uBahasaObj.caption_paymethod||"Metode"}</span>
							<b>{UFunc.renderHTML(vPaymentcaption)}</b>
						</>
						)}
						</div>
					</CCol>
					
					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_nbayar||"N.Bayar"} (Rp)</CCol>
					<CCol xs="7" md="2" className="px-1 text-success">{UFunc.formatAngka(nilai_bayar)}</CCol>
					
					<CCol md="1" className="px-0 d-none d-md-block text-center">
						{(is_prosessing||false)===true ? (
							<CSpinner color="primary" size="sm"/>
						) : (
						<>
						<CTooltip content="--Cetak Copy Nota">
							<CLink onClick={()=>hdlKlikCetaknota(id)} className="d-none d-lg-inline-block classcursorpointer classikontabel classikoncetak"/>
						</CTooltip>

						<CDropdown>
							<CDropdownToggle color="transparent" caret={false} className="p-0">
							<CIcon icon="cilChevronBottom" className="classikonbox align-middle" height={25}/>
							</CDropdownToggle>

							<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikCetaknota(id)} className="classcursorpointer d-lg-none">
								<CIcon className="classikoncetak align-middle mx-0 me-2" height={25}/>
								Cetak Copy Nota
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)} className="classcursorpointer">
								<CIcon icon="cilSearch" className="classikonbox mx-0 me-2" height={25}/>
								Detil Nota
							</CDropdownItem>

							{uIsHapusallow && (
							<CDropdownItem onClick={()=>hdlKlikHapusnota(id)} className="classcursorpointer">
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								Hapus Nota
							</CDropdownItem>
							)}
							</CDropdownMenu>
						</CDropdown>
						</>
						)}
					</CCol>
				</CRow>
				</td>
			</tr>
			);
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uIDselect);
		if(vArridx < 0) return (<></>);

		const vDatanotaObj 	= uDlgviewdataObj.data_obj || {};
		const vDataitemArr	= vDatanotaObj.items_arr || [];

		const vNilaiasuransi= parseFloat(vDatanotaObj.nilai_asuransi) || 0;
		const vNilainetto	= parseFloat(vDatanotaObj.nilai_bruto) - 
			parseFloat(vDatanotaObj.diskon) - vNilaiasuransi;
		const vBayarbefore	= parseFloat(vDatanotaObj.bayar_before) || 0;
		const vNilaiuang	= parseFloat(vDatanotaObj.nilai_uang) || 0;
		const vNilaitagihan	= vNilainetto - vBayarbefore;
		const vNilaisisa	= vNilaitagihan - vNilaiuang;

		return (
		<>
		<CRow>
			<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Nota</CCardHeader>
			<CCardBody>
				<CRow>
				<CCol xs="5">Tgl Bayar</CCol>
				<CCol xs="7" className="text-end">{UFunc.WaktuAngka(vDatanotaObj.tgl_bayar)}</CCol>
				<CCol xs="5">No.Nota</CCol>
				<CCol xs="7" className="text-end fw-bolder">{vDatanotaObj.nota}</CCol>
				<CCol xs="4">Kasir</CCol>
				<CCol xs="8" className="text-end text-danger">{UFunc.Usercaptionfilter(vDatanotaObj.kasir)}</CCol>
				</CRow>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Periksa</CCardHeader>
			<CCardBody>
				<CRow>
				<CCol xs="5">Tgl Periksa</CCol>
				<CCol xs="7" className="text-end">{UFunc.TglAngka(vDatanotaObj.tgl_periksa)}</CCol>
				<CCol xs="3">Pasien</CCol>
				<CCol xs="9" className="text-end text-info">{vDatanotaObj.pasien_caption||"Undf"}</CCol>
				<CCol xs="4">Dokter</CCol>
				<CCol xs="8" className="text-end">{vDatanotaObj.nama_dokter}</CCol>
				</CRow>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>

		<CCard className="mb-3">
		<CCardHeader className="py-1">Item Terapi</CCardHeader>
		<CCardBody className="px-0">
			<table className="table table-borderless table-striped" id="idtabelview">
			<thead>
				<tr className="text-center align-top classfontsmaller">
				<th width={35} className="px-1">No</th>
				<th className="px-1 text-start">Terapi</th>
				</tr>
			</thead>

			<tbody>
			{vDataitemArr.map((vItems,vKeys)=>{
				const {
					id,caption_terapi,tarif_terapi,qty,diagnosa
				} = vItems;

				return (
				<tr className="classfontsmaller text-start align-top"
					key={vKeys}>
				<td className="px-1 text-end">{vKeys+1}.</td>
				<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="9" className="px-1">
						{caption_terapi||"Undf"}
						{parseFloat(tarif_terapi||0) > 0 && (
						<>
							<span className="mx-1">&middot;</span>
							(<b>{UFunc.formatAngka(qty)}</b>unt x {UFunc.formatAngka(tarif_terapi)})
						</>
						)}
						<small className="d-block fst-italic">{diagnosa||""}</small>
					</CCol>
					<CCol xs="3" className="px-1 text-end fw-bolder">
						{UFunc.formatAngka(parseFloat(tarif_terapi||0) * parseInt(qty||1))}
					</CCol>
					</CRow>
				</td>
				</tr>
				)
			})}
			</tbody>

			<tfoot className="fw-bolder">
			<tr>
				<td className="px-1">&nbsp;</td>
				<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="9" className="px-1 text-center">JUMLAH (Rp)</CCol>
					<CCol xs="3" className="px-1 text-end">
						{UFunc.formatAngka(vDatanotaObj.nilai_bruto)}
					</CCol>
					</CRow>
				</td>
			</tr>
			</tfoot>
			</table>
		</CCardBody>
		</CCard>

		<CRow>
			<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Payment</CCardHeader>
			<CCardBody>
			<small>
				<CRow>
				<CCol xs="5">Metode</CCol>
				<CCol xs="7" className="text-end fw_bolder">{(vDatanotaObj.metode_payment || "TUNAI")}</CCol>
				{(vDatanotaObj.ket_payment||"") !== "" && (
				<>
				<CCol xs="3" className="classfontsmaller pe-0">Ket.Payment</CCol>
				<CCol className="text-end fst-italic classfontsmaller">{vDatanotaObj.ket_payment}</CCol>
				</>
				)}
				</CRow>

				{parseInt(vDatanotaObj.id_asuransi||"0") > 0 && (
				<>
				<div className="my-2 classborderbottom"/>

				<CRow>
				<CCol xs="5">Asuransi</CCol>
				<CCol xs="7" className="text-end" style={{color:"purple"}}>
					{(vDatanotaObj.nama_asuransi || "Undf")}
				</CCol>
				</CRow>
				</>
				)}
			</small>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-1">Sub Total</CCardHeader>
			<CCardBody>
			<small>
				{parseFloat(vDatanotaObj.diskon) > 0 && (
				<>
				<CRow>
				<CCol xs="5">Diskon (Rp)</CCol>
				<CCol xs="7" className="text-end text-danger">{UFunc.formatAngka(vDatanotaObj.diskon)}</CCol>
				</CRow>
				</>
				)}		

				{vNilaiasuransi > 0 && (
				<>
				<CRow>
				<CCol xs="5">Klaim Asuransi (Rp)</CCol>
				<CCol xs="7" className="text-end" style={{color:"purple"}}>
					{UFunc.formatAngka(vNilaiasuransi)}
				</CCol>
				</CRow>
				</>
				)}

				{(parseFloat(vDatanotaObj.diskon) > 0 && vNilaiasuransi > 0) && (
				<>
				<CCol className="my-2 classborderbottom"/>
				</>
				)}

				{(vDatanotaObj.status_bayar||"KASIR") !== "KASIR" && (
				<>
				<CRow>
				<CCol xs="5">N.Transaksi (Rp)</CCol>
				<CCol xs="7" className="text-end fw-bolder">{UFunc.formatAngka(vNilainetto)}</CCol>
				</CRow>

				<CRow>
				<CCol xs="5">Terbayar (Rp)</CCol>
				<CCol xs="7" className="text-end fw-bolder">{UFunc.formatAngka(vBayarbefore)}</CCol>
				</CRow>

				<CCol xs="12" className="my-2 classborderbottom"/>
				</>
				)}
				
				<CRow>
				<CCol xs="5">N.Bayar (Rp)</CCol>
				<CCol xs="7" className="text-end text-success fw-bolder">{UFunc.formatAngka(vDatanotaObj.nilai_bayar)}</CCol>
				</CRow>

				{vNilaisisa > 0 && (
				<CRow>
				<CCol xs="5">Sisa Cicilan (Rp)</CCol>
				<CCol xs="7" className="text-end text-danger">{UFunc.formatAngka(vNilaisisa)}</CCol>
				</CRow>
				)}
			</small>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentCetaknota = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return
		//console.log("(Lapkasir - contentCetaklaporan) vHTMLs => "+vHTMLs)

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vDatacetakObj	= uDatamainArr[vArridx].nota_obj || {};
		if(JSON.stringify(vDatacetakObj||{}) === "{}") return "";

		const vCabangObj	= uCabanginitObj;// vCabangArr[vCabangIdx];

		const vUrllogoDefault	= pjson.homepage+"api/images/header_nota.png";

		let vHTMLs 	= uHTMLstylenota;
		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= uViewLogo==="YA";// (uSystemsettingObj.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= (uSystemsettingObj.url_image_nota||"").trim() === "" ? vUrllogoDefault
			: uSystemsettingObj.url_image_nota;//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(vDatacetakObj);
		vHTMLs 			+=  vMainbody;
		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="20mm" style="height:20mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= `
			<tr valign="top">
			<td colSpan="4" align="center">
			<hr/>-- C O P Y --
			</td>
			</tr>
			`;
		if((vCabangObj.telp_hp || "") !== "")
			vTelpPerusahaan = `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				(uBahasaObj.caption_telphp||"Telp/WA")+". "+
				UFunc.capitalize(vCabangObj.telp_hp || "").toUpperCase()+
				//`<div>`+vIsWithlogo+`</div>`+
			`</td>
			</tr>
			` + vTelpPerusahaan;
		//---END TELP--/

		const vFooternota = (vCabangObj.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(vCabangObj.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(vCabangObj.nama_klinik || "KLINIK GIGI").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(vCabangObj.alamat_klinik || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_TRFOOTERNOTA_/g,vFooternota);

		return vHTMLs		
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||1;
		setHtml500msg();

		const vKeyword 		= uKeywordObj.kwd_rekapnota || "";

		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_page: _PAGE,
			send_jmlperpage: uMaxData,
			send_loadjml: (JSON.stringify(uLastinitObj||{}) !== "{}") ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekapnota/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//setSql((output_string.sql||"--EMPTY--"))
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems => vItems.page = _PAGE);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpArr.length);

				setDatamainArr([...vDatamainArr,...vTmpArr]);
				//console.log("(Pasien - apiLoaddata) uPageActive : "+(uPageActive));
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadcetak = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_prosessing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid	: _IDTABEL,
			send_cabangid	: localStorage.getItem("lastcabangid") || 0,//vHandelview,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_nota";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_prosessing = false;
			setEfekview2(!uEfekview2);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.datanota||"{}");
				if(JSON.stringify(vTmpObj) === "{}") {
					showToast("Expired Result !"); return;
				}

				uDatamainArr[vArridx].nota_obj = vTmpObj;
				if((uDatamainArr[vArridx].init_cetak||"TIDAK")==="YA")
					initCetaknota(_IDTABEL);
				else {
					uDlgviewdataObj.data_obj 	= vTmpObj;
					setDlgviewshow(true);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekapnota - apiLoadcetak) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_prosessing = false;
			setEfekview2(!uEfekview2);
			console.log("(Rekapnota - apiLoadcetak) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		setLoading(true);
		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid	: _IDTABEL,//vHandelview,
			send_cabangid	: localStorage.getItem("lastcabangid") || 0,//vHandelview,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapkasir - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))

		/*//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

		//--PAGE_LOAD_PER_PAGE--/
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}
		//--END PAGE_LOAD_PER_PAGE--/

    	return () => {
			setPageActive(0);
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setInitselectedObj({});
			setDlgviewshow(false);

    		setDatamainArr([]); 
			setJmlData(0);
			setIDselect(0);
			setRowselect(-1);
    	}
	},[])
	React.useEffect(()=>{ 
    	if(JSON.stringify(uLastinitObj||{}) !== "{}") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		setRowselect(-1); 
		const vDataviewArr	= uDatamainArr.filter(vItems=>parseInt(vItems.page||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{ 
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		const vKeyword 		= uKeywordObj.kwd_rekapnota || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData,uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//setElJmlData(null);
		setDatamainArr([]);
		setJmlData(0);
		setIDselect(0);
		setRowselect(-1);

		//console.log("(Pasien - [uHandelView]) uHandelView => "+uHandelView)
		if(parseInt(uPageActive) !== 1) {
			setPageActive(1);
		} else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uNavigate("/subpasien/pasieninput");
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewdataObj({}); return}
	},[uISDlgviewshow])
	/*React.useEffect(()=>{
		if((uSystemsettingObj.url_image_nota||"").trim() === "")
			uSystemsettingObj.url_image_nota = pjson.homepage+"api/images/header_nota.png";
	},[uSystemsettingObj,uSystemsettingObj.url_image_nota])//-*/
	React.useEffect(()=>{
		if(!uFrameExport) return;


		const pri 		= uFrameExport.contentWindow; if(!pri) return;
		const vURLlogo	= (uSystemsettingObj.url_image_nota||"").trim() === "" 
			? pjson.homepage+"api/images/header_nota.png"
			: uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])

	//console.log("(Rekapnota) uSystemsettingObj => "+JSON.stringify(uSystemsettingObj));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<b className="align-middle">{uBahasaObj.caption_listdata||"List Data"}</b>
		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			const vKeyword = (uKeywordObj.kwd_rekapnota||"").trim();
			if(vKeyword.length < 3) return (<>&#8734;</>);

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>
		<span className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)
}	

export default Rekapnota