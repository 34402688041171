import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { isMobile } from "react-device-detect";
import {
	MyDialogform,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Lapperawat= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDataperawatArr,setDataperawatArr]= React.useState([]); 
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}

		let vTanggal;
		vElfocus	= document.getElementById("inpbulanid");
		vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-01"; 
		if(!UFunc.isDateValid(vTanggal)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan-Tahun Laporan Tidak Valid !"); return
		}

		const vTanggalDT	= UFunc.toDate(vTanggal);
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Laporan Melewati Tanggal SAAT INI ! "); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoaddata();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetak();
		if(vContent === "") return
		//console.log("(Lapperawat - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikExcel = () => {
		if(uDatamainArr.length <= 0) return;

		if(parseInt(uFilterObj.perawat_id||"0") <= 0) 
			{ excelBulanall(); return; }

		if(parseInt(uFilterObj.perawat_id||"0") > 0)
			{ excelBulanperawat(); return; }
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({}); setDatamainArr([]);// setJmlData(0);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const excelBulanall = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vNurseIdx		= UFunc.getObjectIndeks(uDataperawatArr,uFilterObj.perawat_id);
		const vNursecaption	= vNurseIdx >= 0 ? uDataperawatArr[vNurseIdx].nama_perawat : "";

		const vBulanID		= parseInt((uFilterObj||{}).bln)||0;
		const vTahun		= parseInt((uFilterObj||{}).thn)||0;

		const vColspan		= 3;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			nama_perawat : "",
			nilai_poin : "",
		};
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				nama_perawat,nilai_poin
			} = vItems;

			vRowcount++;
			vColposwidth = (nama_perawat||"").length > vColposwidth 
				? (nama_perawat||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				nama_perawat: (nama_perawat||"Undf Terapi"),
				nilai_poin: { v:parseFloat(nilai_poin), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			nama_perawat: "",
			nilai_poin: { v:parseFloat(uDatasumArr.nilai_poin)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
			{wpx:100},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPPERAWAT_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+vTahun+
		"";
		const vSheetlabel 	= (cBulanPendek[vBulanID-1])+vTahun;
		const ws 			= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode Bulan "+(cBulanPendek[vBulanID-1]).toUpperCase()+" "+vTahun+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Nama Perawat",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Poin",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",vertical:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	const excelBulanperawat = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vNurseIdx		= UFunc.getObjectIndeks(uDataperawatArr,uFilterObj.perawat_id);
		const vNursecaption	= vNurseIdx >= 0 ? uDataperawatArr[vNurseIdx].nama_perawat : "";

		const vBulanID		= parseInt((uFilterObj||{}).bln)||0;
		const vTahun		= parseInt((uFilterObj||{}).thn)||0;

		const vColspan		= 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			tgl_periksa : "",
			tgl_lunas : "",
			pasien_caption : "",
			dokter_caption : "",
			nilai_poin : "",
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,dokter_caption,pasien_caption,
				nilai_poin,tgl_periksa,tgl_lunas
			} = vItems;

			vColposwidth = (pasien_caption||"").length > vColposwidth 
				? (pasien_caption||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				tgl_periksa : {v:tgl_periksa,t:"d",s:{alignment:{horizontal:"left"}}},
				tgl_lunas : {v:tgl_lunas,t:"d",s:{alignment:{horizontal:"left"}}},
				pasien_caption: (pasien_caption||"Undf Pasien"),
				dokter_caption: (dokter_caption||"Undf Dokter"),
				nilai_poin: { v:parseFloat(nilai_poin), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_periksa: "",
			tgl_lunas: "",
			pasien_caption: "",
			dokter_caption: "",
			nilai_poin: { v:parseFloat(uDatasumArr.nilai_poin)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:50}]
		const wscols	= [
			{wpx:40},
			{wpx:100},
			{wpx:100},
			{wch:vColposwidth},
			{wpx:250},
			{wpx:100},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPPERAWAT_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+vTahun+
		"";
		const vSheetlabel 	= (cBulanPendek[vBulanID-1])+vTahun;
		const ws 			= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode Bulan "+(cBulanPendek[vBulanID-1]).toUpperCase()+" "+vTahun+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			"\r\n"+vNursecaption+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tgl Periksa",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Tgl Lunas",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Nama Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Poin",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",vertical:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END INIT--/

	//--CONTENT--/
	//console.log("uDataperawatArr => "+JSON.stringify(uDataperawatArr))
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		const vJmlTahunlaporan	= parseInt(uSystemsettingObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vTahunnow			= (new Date()).getFullYear();
		const vPerawatviewArr	= uDataperawatArr;
			//uDataperawatArr.filter(vItems=>(vItems.cabang_arr || []).includes(vCabangID.toString()))

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
			<CCol xs="4">Cabang</CCol>
			<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.cabang_id||0}
				onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
				id="inpcabangid">
				{vCabangviewArr.map((vItems,vKeys)=>{
					const {
						id,cabang_nama
					} = vItems

					return (
					<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="4">Bulan</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				style={{width:"auto"}}
				value={uFormdataObj.bln || UFunc.leadZero((new Date()).getMonth() + 1)}
				onChange={(e)=>{uFormdataObj.bln = e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
				{cBulanPanjang.map((vItems,vKeys) => {
					return (
						<option value={UFunc.leadZero(vKeys+1)} key={vKeys}>
						{(vItems||"UNDF").toUpperCase()}
						</option>
					)					
				})}
			</CFormSelect>
			&nbsp;
			<CFormSelect size="sm"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				id="inpbulantahun">
				{[...new Array(vJmlTahunlaporan)].map((vItems,vKeys)=>{
					return (
					<option value={(vTahunnow - vKeys)} key={vKeys}>{(vTahunnow - vKeys)}</option>
					)
				})}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">{uBahasaObj.word_perawat||"Perawat"}</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.perawat_id || "0"}
				onChange={(e)=>{uFormdataObj.perawat_id = e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpperawatid">
				<option value="0">&middot;&middot; SEMUA PERAWAT &middot;&middot;</option>
				{vPerawatviewArr.map((vItems,vKeys)=>{
				return (
					<option value={vItems.id} key={vKeys}>
					{(vItems.nama_perawat||"UNDF").toUpperCase()}
					</option>
				)
				})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return (<></>)

		if(uJmlData <= 0) return (
			<div className="mx-md-5 px-lg-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)

		//console.log("(Lapperawat - contentLaporan) uFilterObj.jenis => "+uFilterObj.jenis);
		if(parseInt(uFilterObj.perawat_id||"0") <= 0) 
			return contentBulanall()

		if(parseInt(uFilterObj.perawat_id||"0") > 0)
			return contentBulanperawat()

		return (<></>)
	}
	const contentBulanperawat = () => {
		if(parseInt(uFilterObj.perawat_id||"0") <= 0) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 900;

		return (
		<div style={{overflowX:"auto"}}
			className=""
			id="iddivtabel">
		<table 
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="align-top text-center classfontsmaller">
			<th width={40} className="px-1" style={vStylekolfixed}>No</th>
			<th width={100} className="text-start px-1" style={{...vStylekolfixed,left:40}}>{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</th>
			<th width={100} className="text-start">{uBahasaObj.caption_tgllunas||"Tgl Lunas"}</th>
			<th className="px-1 text-start">
				{uBahasaObj.caption_namapasien||"Nama Pasien"}
			</th>
			<th style={{minWidth:200,width:"25%"}} className="px-1 text-start">{uBahasaObj.word_dokter||"Dokter"}</th>
			<th width={100} className="px-1">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_poin" ? " text-danger" : "")}>
				{uBahasaObj.caption_jmlpoin||"Jml Poin"}
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_poin")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_poin" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,dokter_caption,pasien_caption,
				nilai_poin,tgl_periksa,tgl_lunas
			} = vItems;

			vNumber++;
			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}
				 key={vKeys}>
			<td className="px-1" style={vStylekolfixed}>{vNumber}.</td>
			<td className="px-1 text-start" style={{...vStylekolfixed,left:40}}><small>{UFunc.TglAngka(tgl_periksa)}</small></td>
			<td className="px-1 text-start"><b>{UFunc.TglAngka(tgl_lunas)}</b></td>
			
			<td className="text-start px-1">
				<span className="text-capitalize text-info">{pasien_caption||"UNDF"}</span>
			</td>
			
			<td className="text-start px-1">
				<b>{(dokter_caption||"UNDF")}</b>
			</td>
			
			<td className="px-1 text-center">
				<big className="text-success fw-bolder">{UFunc.formatAngka(nilai_poin)}</big>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot>
		<tr className="fw-bolder bg-primary text-white">
			<td className="px-1 text-center bg-primary" colSpan={2} style={vStylekolfixed}>JUMLAH</td>
			<td className="px-1" colspan={3}/>
			<td md="2" className="text-center px-1">{UFunc.formatAngka(uDatasumArr.nilai_poin)}</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)
	}
	const contentBulanall = () => {
		if(parseInt(uFilterObj.perawat_id||"0") > 0) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		const vKolkasir		= (uFilterObj.perawat_id||"0")==="0" ? 0 : 1;
		
		return (
		<div className="mx-lg-5 px-lg-5">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={40} className="px-1 border">No</th>
			<th className="px-1 border text-start">
				{uBahasaObj.caption_namaperawat||"Nama Perawat"}
			</th>
			<th width="35%" className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_poin" ? " text-danger" : "")}>
				{uBahasaObj.caption_jmlpoin||"Jml Poin"}
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_poin")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_poin" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				nama_perawat,nilai_poin
			} = vItems;

			vNumber++;
			return (
			<tr className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
			<td className="px-1">{vNumber}.</td>
			<td className="px-1 text-start">
				<b>{nama_perawat||"UNDF"}</b>
			</td>
			<td className="px-1 text-center">
				<big className="text-success fw-bolder">{UFunc.formatAngka(nilai_poin)}</big>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot>
			<tr className="fw-bolder bg-primary text-white">
			<td className="px-1 text-center" colSpan={2}>JUMLAH</td>
			<td className="px-1 text-center">{UFunc.formatAngka(uDatasumArr.nilai_poin)}</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)
	}
	const contentCetak = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Lapperawat - contentCetak) vHTMLs => "+vHTMLs)

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vNurseIdx		= UFunc.getObjectIndeks(uDataperawatArr,uFilterObj.perawat_id);
		const vNursecaption	= vNurseIdx >= 0 ? uDataperawatArr[vNurseIdx].nama_perawat : "";

		let vColspan		= 3;
		if(parseInt(uFilterObj.perawat_id||"0") > 0) {
			vColspan = 6;
		}

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="`+vColspan+`">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>
			<div>Cabang: `+vCabangtext+`</div>
			`+(parseInt(uFilterObj.perawat_id)>0 ? "<div>Perawat: <b>"+vNursecaption+"</b></div>" : "")+`
			</td>
			</tr>
			<tr class="idtabelheader">
			`;
		if(parseInt(uFilterObj.perawat_id||"0") > 0)
			vHTMLs += `
				<th width="5%">No</th>
				<th width="10%" align="left">`+(uBahasaObj.caption_tglperiksa||"Tgl Periksa")+`</th>
				<th width="10%" align="left">`+(uBahasaObj.caption_tgllunas||"Tgl Lunas")+`</th>
				<th align="left">`+(uBahasaObj.caption_namapasien||"Nama Pasien")+`</th>
				<th width="25%" align="left">`+(uBahasaObj.word_dokter||"Dokter")+`</th>
				<th width="9%">`+(uBahasaObj.caption_jmlpoin||"Jml Poin")+`</th>
			`;
		else
			vHTMLs += `
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.caption_namaperawat||"Nama Perawat")+`</th>
				<th width="13%">`+(uBahasaObj.caption_jmlpoin||"Jml Poin")+`</th>
			`;
		vHTMLs += `
			</tr>
			</thead>
			<tbody>`;
		uDatamainArr.forEach((vItems,vKeys)=>{
			const {
				id,pasien_caption,dokter_caption,
				nama_perawat,nilai_poin,
				tgl_periksa,tgl_lunas,
			} = vItems;

			if(parseInt(uFilterObj.perawat_id||"0") > 0)
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
					<td align="left" style="font-size:smaller">`+UFunc.TglAngka(tgl_periksa)+`</td>
					<td align="left" style="font-size:smaller">`+UFunc.TglAngka(tgl_lunas)+`</td>
					<td align="left">`+(pasien_caption||"")+`</td>
					<td align="left">`+(dokter_caption||"")+`</td>
					<td align="center">`+UFunc.formatAngka(nilai_poin)+`</td>
				</tr>
				`;
			else
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="">`+(vKeys+1)+`.</td>
					<td align="left" style="font-weight:bolder">`+(nama_perawat||"UNDF")+`</td>
					<td align="center">`+UFunc.formatAngka(nilai_poin)+`</td>
				</tr>
				`;
		});
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="`+(vColspan-1)+`" align="center">JUMLAH</td>
			<td align="center">`+UFunc.formatAngka(uDatasumArr.nilai_poin)+`</td>
			</tr>
			</tfoot>
			</table>
		`;
		return vHTMLs		
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Lapperawat - apiLoadawal) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			setDataperawatArr(vTmpArr);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapperawat/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDataperawatArr(JSON.parse(output_string.dataperawat||"[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapperawat - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapperawat - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = () => {
		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;
		/*
		const vHandelview	= (_PAGE <= 0 || uFirstload==="YA")?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}//-*/

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			//send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			//send_maxdata	: uMaxData,
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapperawat/ld_laporan";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//setJmlData(vLoadArr.length);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				hdlToggleDlgform();
				setDataloaded(true);
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapperawat - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapperawat - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			setDataloaded(false)
			setDlgformshow(false);
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);setDataperawatArr([]);
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uDatamainArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...uDatamainArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
		if(uDatamainArr.length <= 0)
			{ setDatasumObj({}); return }

		const vSumtotal	= uDatamainArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_poin||"0"), 0);
		setDatasumObj({nilai_poin: vSumtotal});
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		/*//--LOAD_PER_PAGE--/
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END LOAD_PER_PAGE--*/

		//--LOAD_ALL_VIEW_PER_PAGE--/
		setIDselect(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;
		if(uHtml500msg) {
			uHeaderActionObj.isHeaderView = false;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			setEfekview(!uEfekview);
			return;
		}

		setHeaderDlgform("Pilih Filter Laporan")
		setDlgformshow(true)
		setFormdataObj({...uFilterObj, init_change: 1});

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		if(uFormdataObj.jenis === "TANGGAL") {
			vTitelmain += "<b>"+UFunc.HariAngka(uFormdataObj.thn+"-"+uFormdataObj.bln+"-"+uFormdataObj.tgl)+"</b>";
		} else {
			vTitelmain += "BULAN <b>"+cBulanPanjang[parseInt(uFormdataObj.bln)-1]+" "+uFormdataObj.thn+"</b>";
		}

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr.length > 1 ? uDatacabangArr[vCabangIdx].cabang_nama+" &middot; " : "";

		if((uFormdataObj.metode||"").toUpperCase() === "TUNAI") {
			vTitelsub	+= "<b>METODE TUNAI</b>";
		} else if((uFormdataObj.metode||"").toUpperCase() === "NONTUNAI") {
			vTitelsub	+= "<b>METODE NON TUNAI</b>";
		}
		if(vTitelsub !== "") vTitelsub += " &middot ";
		if(parseInt(uFormdataObj.perawat_id) > 0) {
			const vNurseIdx= UFunc.getObjectIndeks(uDataperawatArr,uFormdataObj.perawat_id);
			vTitelsub	+= ""+uDataperawatArr[vNurseIdx].nama_perawat;
		} else {
			vTitelsub	+= "[SEMUA PERAWAT]";
		}

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lapperawat - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		//console.log("(Lapperawat - [uIsDlgformshow]) uFormdataObj => "+JSON.stringify(uFormdataObj));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			const vElfocus = document.getElementById("inpcabangid");
			vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");
		if(uFormdataObj.perawat_id === undefined)
			uFormdataObj.perawat_id = "0";

		const vTanggalNow	= new Date();
		if(uFormdataObj.bln === undefined)
			uFormdataObj.bln = UFunc.leadZero(vTanggalNow.getMonth()+1);
		if(uFormdataObj.thn === undefined) //uFormdataObj.thn = "2021"
			uFormdataObj.thn = UFunc.leadZero(vTanggalNow.getFullYear());
	},[uFormdataObj,
	uFormdataObj.cabang_id,
	uFormdataObj.bln,
	uFormdataObj.thn,
	uFormdataObj.perawat_id,
	])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); setIDselect(0); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		const vISNumeric= UFunc.isNumeric(uDatamainArr[0][vNamasort]);
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (a[vNamasort] > b[vNamasort]) ? 1 : ((b[vNamasort] > a[vNamasort]) ? -1 : 0) 
					}) 
				: uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]) ;
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (b[vNamasort] > a[vNamasort]) ? 1 : ((a[vNamasort] > b[vNamasort]) ? -1 : 0) 
				})
				: uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Lapperawat) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
			<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
			<CCardBody className="mx-md-5 p-md-5">
			<div className="mx-lg-5 px-lg-5 fst-italic classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between">
			<div>
				<div>{UFunc.renderHTML(uCaptionObj.title)}</div>
				<small>
					<small className="classfontsmaller text-primary">
					{UFunc.renderHTML(uCaptionObj.title_sub)}
					</small>
				</small>
			</div>

			<div>
				<span className="d-none d-md-inline">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>

				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				</span>

				<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>

				<span className="mx-1">&middot;</span>

				<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)} ms</small>
				</span>
			</div>
			</CCardHeader>
		
			<CCardBody className="px-0">{contentLaporan()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)
}	

export default Lapperawat