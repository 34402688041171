import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CInputGroupText,
	CLink,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton
} from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Error404 	= (props) => {
	const {
		setToken, setLoading,showToast,prosesExpired
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
	},[])
	
	//console.log("(Error404) window.location.origin => "+(window.location.origin));

	if(!uTokenObj) { setLoading(true); prosesExpired(); return; }

	return (
	<>
	<CCard className="mx-lg-5 text-center classbgcard">
		<CCardHeader>
		<span className="classfontbrand align-middle">Error 404</span>
		<span className="mx-1 d-none d-md-inline">&middot;</span>
		<span className="d-block d-md-inline">Page not Found</span>
		</CCardHeader>
		<CCardBody className="px-2 px-lg-5">
		<CImage src={window.location.origin+"/images/img_error500.png"} height="250" />
		<div><small id="idcontentmsg" className="classfontsmaller fst-italic">{UFunc.renderHTML((pjson.mydefault.msg400page||"UNDF").join(""))}</small></div>
		<MyButton 
			iconname="cilHome"
			className="classfontsmaller"
			onClick={()=>uNavigate("/dashboard")}>Menu Utama</MyButton>
		</CCardBody>
	</CCard>
	</>
	)
}	

export default Error404