import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Kasir 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikBayar = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		uNavigate("/subbilling/kasir/bayar/"+_IDTABEL);
	}
	const hdlKlikPiutang = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vNamapasien = uDatamainArr[vArridx].pasien_caption||"";

		uKeywordObj.kwd_kasirpiutang = vNamapasien;
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		uNavigate("/subbilling/kasirpiutang");
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		return (
		<>
		{uDatamainArr.map((vItems,vKeys) => {
			const {
				id,pasien_caption,pasien_sex,status_piutang,
				keterangan_antrian,nilai_netto
			} = vItems;

			const vCaptionUpper	= (pasien_caption||"").toUpperCase();
			
			return (
			<CRow className="my-3 mx-md-0" key={vKeys}>
			<CCol xs="2" lg="1" className="d-grid text-center pe-1">
			{(status_piutang||"TIDAK")==="YA" ? (
				<CTooltip content="--Masih Ada CICILAN, Klik Untuk Lebih Detil..">
				<CButton color="light"
					className="border"
					onClick={()=>hdlKlikPiutang(id)}>
					<CImage src={pjson.homepage+"api/images/icon_piutang.png"} 
						className="classblinking align-middle"
						height={35}/>
				</CButton>
				</CTooltip>
			) : (
				<CImage src={pjson.homepage+"api/images/icon_check.png"} 
					className="mx-auto"
					height={35}/>
			)}
			</CCol>

			<CCol xs="10" lg="11" className="d-grid ps-1">
			<CButton 
				className="d-md-flex justify-content-between align-items-center px-lg-3"
				color="dark"
				onClick={()=>hdlKlikBayar(id)}
				id={"btnproseskasir"+id}>
				<div className="d-flex">
					<div className="me-2">
					<CImage src={pjson.homepage+"api/images/"+((pasien_sex||"L")==="L"?"icon_male":"icon_female")+".png"} height={30}/>
					</div>

					<div className="w-100 text-start">
						<big className="align-middle fw-bolder">{(pasien_caption||"")}</big>
						
						<div className="classfontsmaller">
							<small className="fst-italic">{keterangan_antrian||""}</small>
						</div>

						<div className="my-2 classborderbottom d-md-none"/>
						<div className="d-md-none">
							<b>Rp{UFunc.formatAngka(nilai_netto)}</b>
							<span className="mx-1 mx-md-2 ">&middot;</span>
							<CIcon icon="cilChevronDoubleRight" className="align-middle" height={18}/>
						</div>
					</div>
				</div>

				<div className="d-none d-md-block">
					<b>Rp{UFunc.formatAngka(nilai_netto)}</b>
					<span className="mx-1 mx-md-2 ">&middot;</span>
					<CIcon icon="cilChevronDoubleRight" className="align-middle" height={20}/>
				</div>
			</CButton>
			</CCol>
			</CRow>
			)
		})}
		</>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasir/ld_kasir";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasir - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kasir - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		uKeywordObj.kwd_kasirpiutang = undefined;
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

    	apiLoaddata();

    	return () => { 
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]); 
    	}
	},[])
	React.useEffect(()=>{
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])

	//console.log("Kasir - uPageActive => "+JSON.stringify(uPageActive));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	/*if(isMobile)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>&middot;&middot; Sorry Still Unavailable &middot;&middot;</CCardBody>
		</CCard>
	)//-*/

	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard mb-2">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>List Kasir Ready</strong>
		<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeelapse)}ms</small>
		</CCardHeader>
		
		<CCardBody className="px-1 px-md-3 px-lg-5">{contentMain()}</CCardBody>

		</CCard>
		</>
	)//>
}	

export default Kasir