import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Antrianruang 	= (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_tabel || 30);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	//const [uSystemconfigObj,setSystemconfigObj] = React.useState({});

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uFirstload,setFirstload]		= React.useState("YA");

	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	const [uIsDoktershow,setDoktershow]	= React.useState(true);
	const [uIsPerawatshow,setPerawatshow]	= React.useState(false);

	const uCabangselectedObj 				= useSelector(state => state.gCabangatribut) || {};
	const [uCabanginitObj,setCabanginitObj] = React.useState(uCabangselectedObj);
	//const uCabanginitObj 				= useSelector(state => state.gCabangatribut) || {};
	//const [uCabanginitObj,setCabanginitObj]	= React.useState(uDatacabangArr[UFunc.getObjectIndeks(uDatacabangArr,uTokenObj.user_cabangid)]);
	//--END VIEWS_VARS--/

	//---LOOKUP_VARS--/
	//---END LOOKUP_VARS--/

	//--DOM_VARS--/
	let uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikPasien = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vPasienID	= uDatamainArr[vArridx].pasien_id || 0;
		
		const vTmpObj	= { initID:_IDTABEL }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})

		if(uTokenObj.userhak === "DOKTER") {
			const vParamsObj 	= uKeywordobj.filter_antrianruang || {};
			const vTglantri		= vParamsObj.tgl_antri || UFunc.DbDate();
			const vTglnow		= UFunc.DbDate();

			if(vTglantri !== vTglnow) 
				{ return; }
			if((uDatamainArr[vArridx].dokter_id||"0").toString() !== (uTokenObj.user_dokterid||"0").toString())
				{ return; }

			uNavigate("/subpasien/pasien/rekammedis/"+vPasienID);
			return;
		}

		uNavigate("/subpasien/pasien/daftarantri/"+vPasienID);
	}
	//--END HANDEL--/

	//--INIT--/
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vParamsObj 	= uKeywordobj.filter_antrianruang || {};
		const vTglantri		= vParamsObj.tgl_antri || UFunc.DbDate();
		const vDataviewArr	= uDatamainArr;


		const vJmlruangan	= parseInt(uCabanginitObj.jml_ruangan) || 2;
		const vJamstartidx	= 8;		
		const vJamfinishidx	= 21;

		const vWidthno		= 50;
		const vWidthFirstkol= 175;
		const vWidthkolrg	= 220;

		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthFirstkol*vJmlruangan)

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "white",
			zIndex: 1,
		}

		return (
		<div style={{overflowX:"auto"}}
			className=""
			id="iddivtabel">
		<div className="d-none">uDatamainArr = {JSON.stringify(uDatamainArr)}</div>
		<table 
			style={{width:vWidthtabel,borderCollapse:"separate"}}
			className="table mx-auto" 
			id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width={vWidthno} className="bg-white" style={vStylekolfixed}>No</th>
			<th width={vWidthFirstkol} className="bg-white" style={vStylekolfixed}>Waktu Antrian</th>
			{(() => {
				let vHTMLth = [];
				for (var vIdx=1;vIdx<=vJmlruangan;vIdx++){
					vHTMLth.push(<th width={vWidthkolrg} key={vIdx}
						className="">Ruang {vIdx}</th>)
				}
				return vHTMLth;
			})()}
			</tr>
		</thead>
		<tbody>
		{(() => {
			let vHTMLtr = [];
			let vNumber	= 0;
			for (var vIdxrow=vJamstartidx;vIdxrow<=vJamfinishidx;vIdxrow++){
				vNumber++;
				const vJamAwalDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow)+":00:00"
					);
				const vJamAkhirDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow+1)+":00:00"
					);
				vHTMLtr.push(
				<tr className="align-middle" style={{height:100}}>
				<td className="border text-end bg-light" style={vStylekolfixed}>{vNumber}.</td>
				<td className="border text-center bg-light" style={vStylekolfixed}>
					{UFunc.JamMenit(vJamAwalDT)} - {UFunc.JamMenit(vJamAkhirDT)}
				</td>
				
				{(() => {
					let vHTMLtd = [];
					for (var vIdxkol=1;vIdxkol<=vJmlruangan;vIdxkol++){
						const vFiltercontentArr = uDatamainArr.filter(vItems=>{
							const vJamRgawalDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_awal
								);
							const vJamRgakhirDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_akhir
								);

							return (parseInt(vItems.no_ruang||0)===vIdxkol)
							&& ((vJamRgawalDT >= vJamAwalDT) && (vJamRgawalDT < vJamAkhirDT) 
								|| (vJamRgakhirDT <= vJamAkhirDT) && (vJamRgakhirDT > vJamAwalDT) 
								|| (vJamAkhirDT > vJamRgawalDT) && (vJamAkhirDT < vJamRgakhirDT) 
							)
						});

						let vStyleBGbox	= "#00008011";
						if(vFiltercontentArr.length > 0)
						if(uTokenObj.userhak==="DOKTER")
							if((vFiltercontentArr[0].dokter_id||"0").toString() === (uTokenObj.user_dokterid||"0").toString())
								vStyleBGbox = "#FE100111"

						vHTMLtd.push(
						<td className={"border text-center "+(vFiltercontentArr.length <= 0 ? "align-middle" : "align-top")}>
						{vFiltercontentArr.length <= 0 && (
							<b className="text-light">RG-{vIdxkol}</b>
						)}
						{vFiltercontentArr.map((vItems,vKeys)=>{
							const vPasienArr = (vItems.pasien_caption||"").split(" ");
							return (
							<div className="px-1 py-1 classfontsmaller text-center classcursorpointer"
								onClick={()=>hdlKlikPasien(vItems.id)}
								key={vKeys}
								style={{border:"3px dashed navy",borderRadius:7,backgroundColor:vStyleBGbox}}
								id={"idbox"+vItems.id}>
							<b className="text-primary">{(vPasienArr[0]+" "+(vPasienArr[1]||"")).trim()}</b>

							<div className="text-danger">
							({(vItems.jam_awal||"").substring(0,5)} - {(vItems.jam_akhir||"").substring(0,5)})
							</div>

							{uIsDoktershow && (
							<div className="text-success fst-italic">{UFunc.Usercaptionfilter(vItems.dokter_caption,3)}</div>
							)}

							{(uIsPerawatshow && (vItems.perawat_caption||"").trim()!=="") && (
							<div className="">&middot;&middot; {vItems.perawat_caption} &middot;&middot;</div>
							)}
							</div>
							)
						})}
						</td>
						);
					}
					return vHTMLtd;
				})()}
				</tr>
				);
			}
			return vHTMLtr;
		})()}
		</tbody>
		</table>
		</div>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vParamsObj = uKeywordobj.filter_antrianruang || {};
		const vDATAS	= JSON.stringify({
			send_tglantri : vParamsObj.tgl_antri || UFunc.DbDate(),
			send_cabangid : vParamsObj.cabang_id || uTokenObj.user_cabangid,
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_janjian";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr 		= JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);
				setJmlData((parseInt(vTmpArr.length)||0));

				/*const vSystemconfigObj 	= JSON.parse(output_string.dataconfig||JSON.stringify(uSystemconfigObj));
				setSystemconfigObj(vSystemconfigObj);//-*/
				
				const vTmpObj 	= JSON.parse(output_string.cabangatribut || JSON.stringify(uCabangselectedObj));
				setCabanginitObj(vTmpObj);

				setFirstload("TIDAK");

		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianruang - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianruang - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		uDispatch({type: "set", gBillingperiksaObj: undefined});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
			setCabanginitObj(uCabangselectedObj);
    	}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		const vElscroll	= document.getElementById("idbox"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])
	//console.log("Antrianruang - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaob.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard mb-3">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<b>{uBahasaob.caption_dataantrian||"Data Antrian"}</b>

		<small className="d-none d-md-block">
			<CInputGroup size="sm" className="">
			<CFormCheck size="sm" className=""
				label="Tampilkan DOKTER"
				checked={uIsDoktershow}
				onChange={(e)=>setDoktershow(!uIsDoktershow)}
				id="inpdoktershow"/>
			<span className="mx-2"/>
			<CFormCheck size="sm" className="py-0"
				checked={uIsPerawatshow}
				onChange={(e)=>setPerawatshow(!uIsPerawatshow)}
				label="Tampilkan Perawat"
				id="inpperawatshow"/>
			<span className="mx-2"/>
			<span className="align-middle classfontsmaller py-0 text-muted">
				{UFunc.formatAngka(uTimeelapse)}ms
			</span>
			</CInputGroup>
		</small>
		</CCardHeader>

		<CCardBody className="px-0">
		<div className="d-md-none px-3">
			<p>Maaf,</p>
			<p className="fw-bolder">Data Antrian Ruang Hanya Dapat Dilihat Melalui Mode Tampilan Tablet.</p>
			<p>Saat Ini Mode Tampilan Anda adalah Mode Tampilan Celuler.<br/><br/>Mohon Ubah Mode Tampilan Anda !</p>
		</div>
		<div id="idcontentarea" className="d-none d-md-block">{contentMain()}</div>
		</CCardBody>
		</CCard>
		</>
	)
}	

export default Antrianruang