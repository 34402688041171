import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CRow,
	CCol,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubpasieninput = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaob  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenob				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const hdlKliktoData = () => {
		uNavigate("/subpasien/pasien");
	}

	//console.log("Headersubpasieninput - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2 align-middle"
			height={30}/>
		<small>{uBahasaob["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<div className="pt-2 pt-md-0 me-3">
			<CTooltip content={"--Go-2-"+(uBahasaob.menus_pasien)}>
			<CLink onClick={()=>hdlKliktoData()} className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/menus_pasien.png"} height={32}/>
			</CLink>
			</CTooltip>
		</div>
		</>
	)
}

export default Headersubpasieninput
