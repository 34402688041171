import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Rekapnota	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikDlgsimpan = () => {}
	//--END HANDEL--/

	//--INIT_FUNC--/
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/
	const contentMain = () => {}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||1;
		setHtml500msg();

		const vKeyword 		= uKeywordObj.kwd_pasien || "";

		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_page: _PAGE,
			send_jmlperpage: uMaxData,
			send_loadjml: (JSON.stringify(uLastinitObj||{}) !== "{}") ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//setSql((output_string.sql||"--EMPTY--"))
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpObj.length);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				//console.log("(Pasien - apiLoaddata) uPageActive : "+(uPageActive));
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END API--/


	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))

		/*//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

		//--PAGE_LOAD_PER_PAGE--/
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}
		//--END PAGE_LOAD_PER_PAGE--/

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
    		setInitselectedObj({});
			setDlgformshow(false);
			setPageActive(0);
    	}
	},[])
	React.useEffect(()=>{ 
    	if(JSON.stringify(uLastinitObj||{}) !== "{}") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		setRowselect(-1); 
		const vDataviewArr	= uDatamainArr.filter(vItems=>parseInt(vItems.page||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{ 
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		const vKeyword 		= uKeywordObj.kwd_pasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData,uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//setElJmlData(null);
		setDatamainArr([]);
		setJmlData(0);
		setIDselect(0);
		setRowselect(-1);

		//console.log("(Pasien - [uHandelView]) uHandelView => "+uHandelView)
		if(parseInt(uPageActive) !== 1) {
			setPageActive(1);
		} else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uNavigate("/subpasien/pasieninput");
	},[uHandelTambah])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<b className="align-middle">{uBahasaObj.caption_listdata||"List Data"}</b>
		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			const vKeyword = (uKeywordObj.kwd_pasien||"").trim();
			if(vKeyword.length < 3) return (<>&#8734;</>);

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>
		<span className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		<small className="d-none">({uJmlData} - {uMaxData})</small>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Rekapnota