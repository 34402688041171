import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormInput,
	CInputGroup,
	CFormSelect,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubterapicabang = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaob  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit		= (localStorage.getItem("lastcabangid")||"0")
	const uCabangIDArr		= uTokenObj.user_cabangarr ||[];
	const uCabangallowArr	= uTokenObj.userhak!=="FREEROLE" ? uDatacabangArr.filter(vItems=>
		uCabangIDArr.includes((vItems.id||"").toString()) )
		: uDatacabangArr; 

	const [uInpkeyword,setInpkeyword]	= React.useState(uKeywordObj.kwd_terapicabang||"");
	const [uInpCabangid,setInpCabangid]	= React.useState(uCabangIDinit);

	const hdlKeydown = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_terapicabang = uInpkeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
    	localStorage.setItem("cabangidselect",uInpCabangid);
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_terapicabang = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	localStorage.removeItem("cabangidselect");
	    	setInpkeyword("");
	    	setInpCabangid(uCabangIDinit);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaob.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubterapicabang - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2" height={25}/>
		<small>{uBahasaob["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<CForm>
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			{uDatacabangArr.length > 1 && (
			<>
			<CFormSelect size="sm" className="d-none d-md-inline"
				value={uInpCabangid}
				onChange={(e)=>setInpCabangid(e.target.value)}
				id="inpcabangiddesktop">
				{uCabangallowArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
					)
				})}
			</CFormSelect>
			
			<CDropdown className="d-md-none">
				<CDropdownToggle color="light" className="py-0" carat={false}>{(()=>{
					const vArridx = UFunc.getObjectIndeks(uCabangallowArr,uInpCabangid);

					return uCabangallowArr[vArridx].cabang_kode||"UDF"
				})()}</CDropdownToggle>
				<CDropdownMenu className="py-0">
				{uCabangallowArr.map((vItems,vKeys)=>{
					return (
					<CDropdownItem onClick={()=>setInpCabangid(vItems.id)}
						key={vKeys}>
						{vItems.cabang_kode}
						<span className="mx-2">&middot;</span>
						{vItems.cabang_nama}
					</CDropdownItem>
					)
				})}
				</CDropdownMenu>
			</CDropdown>

			<span className="me-1"/>
			</>
			)}

			<CFormInput size="sm" 
				className="w-50"
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				placeholder={"--"+(uBahasaob.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light" className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1">
			<CTooltip content={"--"+(uBahasaob.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="d-none d-md-inline">
				<span className="me-1">&middot;</span>
				(<b id="idjmldata" className="text-primary">0</b> {uBahasaob.word_data||"Data"})
			</span>
		</div>
		</>
	)
}

export default Headersubterapicabang
