import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormInput,
	CInputGroup,
	CFormSelect,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubpayment = (props) => {
	const { setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uConfigappsObj		= useSelector(state => state.uConfigappsObj) || {}; 
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp			= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow			= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") && !uISDemoapp;

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit		= (localStorage.getItem("lastcabangid")||"0")
	const uCabangIDArr		= uTokenObj.user_cabangarr ||[];
	const uCabangallowArr	= uTokenObj.userhak!=="FREEROLE" ? uDatacabangArr.filter(vItems=>
		uCabangIDArr.includes((vItems.id||"").toString()) )
		: uDatacabangArr; 

	const [uInpkeyword,setInpkeyword]	= React.useState(uKeywordObj.kwd_payment||"");
	const [uInpCabangid,setInpCabangid]	= React.useState(uCabangIDinit);

	const hdlKeydown = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_payment = uInpkeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
    	localStorage.setItem("cabangidselect",uInpCabangid);
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah = () => {
		//console.log("Headersubpekerjaan - hdlKlikTambah uHeaderActionObj => "+JSON.stringify(uHeaderActionObj));

		uHeaderActionObj.isHeaderTambah = true;//!(vHeaderTmpObj.isHeaderTambah||false);
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_payment = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	localStorage.removeItem("cabangidselect");
	    	setInpkeyword("");
	    	setInpCabangid(uCabangIDinit);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubpayment - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-1" height={30}/>
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<CForm>
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			{uDatacabangArr.length > 1 && (
			<>
				<CFormSelect size="sm" className="d-none d-md-block me-1"
					value={uInpCabangid}
					onChange={(e)=>setInpCabangid(e.target.value)}
					id="inpcabangiddesktop">
					{uCabangallowArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
					)
					})}
				</CFormSelect>

				<CDropdown className="d-md-none me-1">
				<CDropdownToggle className="py-0 border" color="light">
					{(()=>{
						const vArridx = UFunc.getObjectIndeks(uDatacabangArr,uInpCabangid);
						if(vArridx < 0) return (<></>);

						return uDatacabangArr[vArridx].cabang_kode;
					})()}
				</CDropdownToggle>
				<CDropdownMenu>
					{uCabangallowArr.map((vItems,vKeys)=>{
					return (
					<CDropdownItem onClick={()=>setInpCabangid(vItems.id)}>
						{vItems.cabang_kode}
						<span className="mx-1">&middot;</span>
						{vItems.cabang_nama}
					</CDropdownItem>
					)
					})}
				</CDropdownMenu>
				</CDropdown>
			</>
			)}

			<CFormInput size="sm" 
				value={uInpkeyword} 
				className="w-auto"
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1">
			{(uIsHapusallow) && (
			<>
			<CLink onClick={()=>hdlKlikTambah()} 
				className="classikon classikontambah classcursorpointer"/>
			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			</>
			)}

			<span className="d-none d-md-inline">
				<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
				</CTooltip>
				
				<span className="me-1">&middot;</span>
				(<b id="idjmldata" className="text-primary">0</b>&nbsp;{uBahasaObj.word_data||"Data"})
			</span>
		</div>

		<CDropdown className="d-none">
		<CDropdownToggle caret={false} className="p-0" color="transparent">
			<CIcon icon="cilChevronBottom" className="classikonbox" height={30}/>
		</CDropdownToggle>
		<CDropdownMenu>
			{(uIsHapusallow) && (
			<CDropdownItem  onClick={()=>hdlKlikTambah()}>
			<CIcon className="classikontambah align-middle mx-0 me-1" height={25}/>Tambah Data
			</CDropdownItem>
			)}

			<CDropdownItem onClick={()=>hdlKlikReset()}>
			<CIcon className="classikonreset align-middle mx-0 me-1" height={25}/>Reset Tampilan
			</CDropdownItem>
		</CDropdownMenu>
		</CDropdown>
		</>
	)
}

export default Headersubpayment
