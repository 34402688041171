import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormSelect,CInputGroup,CButton,CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyDialogviewfullscreen,
	MyDialoglookup,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
import useSatusehat from '../helpers/useSatusehat'

const pjson 		= require('../../package.json')
const Antrianpasien = (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props; 

	//--DOM--/
	const { loadToken } 		= useSatusehat(props);
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISHapusallow				= (uTokenObj.userhak!=="DOKTER") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  			= useSelector(state => state.gInitHeaderAction);
	const uHandelView 					= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 				= uHeaderActionObj.isHeaderTambah || false;
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});

	//--VIEWS_VARS--/
	const uTokensatusehatObj			= JSON.parse(localStorage.getItem("tokensatusehat") || "{}");
	const uFilterObj 					= uKeywordObj.filter_antrianpasien || {};
	const uTglAntri 					= uFilterObj.tgl_antri || UFunc.DbDate();
	const uCabangid 					= uFilterObj.cabang_id || (parseInt(uTokenObj.user_cabangid)||0);
	const uTglNow 						= UFunc.DbDate();
	const [uFirstload,setFirstload]		= React.useState("YA"); 
	const [uDatamainArr,setDatamainArr]			= React.useState([]);
	const [uRowumumselect,setRowumumselect]		= React.useState(-1);
	const [uRowantrianselect,setRowantrianselect]= React.useState(-1);
	const [uIDselect,setIDselect]				= React.useState(0);

	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangselectedObj 				= useSelector(state => state.gCabangatribut) || {};
	const [uCabanginitObj,setCabanginitObj] = React.useState(uCabangselectedObj);
	//--END VIEWS_VARS--/

	//---DLGFORM_VARS--/
	const [uDatadokterArr,setDatadokterArr]		= React.useState([]);
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformObj,setDlgformObj]			= React.useState({});
	//---END DLGFORM_VARS--/

	//---DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLG_LOOKUP--/

	//--DLG_FULLSCREEN--/
	const [uISDlgfullscreenshow,setDlgfullscreenshow]		= React.useState(false);
	const [uDlgfullscreenshowObj,setDlgfullscreenshowObj]	= React.useState({});
	//--END DLG_FULLSCREEN--/

	//--DOM_VARS--/
	const vContent	= document.getElementById("idmaincontent");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikPasien = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		//console.log("(Antrianpasien - hdlKlikPasien) uDatamainArr => "+JSON.stringify(uDatamainArr))
		//console.log("(Antrianpasien - hdlKlikPasien) _IDTABEL => "+(_IDTABEL))
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const uPasienID	= uDatamainArr[vArridx].pasien_id || 0;

		const vTmpObj	= { initID:_IDTABEL }
		if(uTokenObj.userhak === "DOKTER") {
			const vISHadir = (uDatamainArr[vArridx].jam_hadir||"") !== "";

			if(vISHadir)
				uNavigate("/subantrian/statuspasien");
			else {
				uDispatch({type: "set", gInitTabelSelected: vTmpObj})
				uNavigate("/subpasien/pasien/rekammedis/"+uPasienID);
			}
			return;
		}

		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subpasien/pasien/daftarantri/"+uPasienID);
	}
	const hdlKlikKehadiran = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionUpper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}

		if((uDatamainArr[vArridx].jam_hadir||"")!=="") {
			if(await(Konfirm("Pembatalan Kehadiran: <b>"+vCaptionUpper+"</b>.."+
				"<br/>Apakah Anda Yakin ?"+
				""))) {
				uDlgformObj.tabelid = _IDTABEL;
				apiProseshadir();//-*/
			}
			return;
		}

		if(uDatadokterArr.length <= 0) {
			apiLoaddokter(_IDTABEL);
			return;
		}

		initSetkehadiran(_IDTABEL);
	}
	const hdlKDownDoktercaption = (_EV) => {
		uDlgformObj.dokter_id 		= "0";
		if(_EV.keyCode !== 13)  return;
		if((uDlgformObj.dokter_caption||"").trim() === "") return;

		_EV.preventDefault()
		_EV.stopPropagation()

		const vElfocus = document.getElementById("btnDialogSimpan");
		const vfilterArr = uDatadokterArr.filter(vItems => 
			(vItems.dokter_caption||"").toUpperCase().
				includes((uDlgformObj.dokter_caption||"").trim().toUpperCase())
		)

		if(vfilterArr.length === 1) {
			uDlgformObj.dokter_id 		= (vfilterArr[0].id||"0");
			uDlgformObj.dokter_caption 	= (vfilterArr[0].dokter_caption||"");
			uDlgformObj.dokter_ihs 		= (vfilterArr[0].ihs_dokter||"");

			vElfocus && vElfocus.focus();
			return
		}

		hdlKlikDokterlookup();
	}
	const hdlKlikDokterlookup = () => {
		const vDatalookupArr = uDatadokterArr.map(vItems =>{ 
			return {id: vItems.id,caption:vItems.dokter_caption} 
		})

		uDlglookupObj.items_arr		= vDatalookupArr;
		uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
			(uBahasaObj.caption_datadokter||"Data Dokter");

		uDlglookupObj.inputvalue 	= uDlgformObj.dokter_caption||"";
		uDlgformObj.dokter_caption 	= "";
		uDlgformObj.dokter_id 		= "0";
		uDlgformObj.dokter_ihs 		= "";

		setDlglookupshow(true)
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		setDlglookupshow(false);

		const vArridx 	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM)
		uDlgformObj.dokter_id 		= (uDatadokterArr[vArridx].id||"0");
		uDlgformObj.dokter_caption 	= (uDatadokterArr[vArridx].dokter_caption||"");
		uDlgformObj.dokter_ihs 		= (uDatadokterArr[vArridx].ihs_dokter||"");

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)

			const vElfocus = document.getElementById("btnDialogSimpan");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	} 
	const hdlKlikBatalperiksa = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setIDselect(_IDTABEL);

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}
	
		if(await(Konfirm("Pembatalan Status PERIKSA: <B>"+vCaptionUpper+"</B>.."+
			"<br/>Pasien Tersebut Masih Dalam Status Periksa oleh Perangkat Lain, "+
			"dan Perangkat Tersebut Sudah Tidak Lagi Aktif Untuk melakukan BILLING PERIKSA."+
			"<br/><br/>Apakah Anda Yakin ?"+
			""))) apiProsesbatalperiksa(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		
		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}
	
		if(await(Konfirm("Hapus Antrian: <B>"+vCaptionUpper+"</B>.."+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikRuangan = () => {
		uDlgfullscreenshowObj.dlg_headers	= "View Reserved Ruangan";
		uDlgfullscreenshowObj.tgl_ruangan	= uTglAntri;
		uDlgfullscreenshowObj.cabang_id		= uCabangid;
		uDlgfullscreenshowObj.data_arr		= uDatamainArr.filter(vItems => 
			parseInt(vItems.no_ruang||"0") > 0).
			map(vItems => {
				vItems.tgl_awal = UFunc.toDatetime(UFunc.DbDate()+" "+vItems.jam_awal);
				return vItems;
			}).
			sort((a,b) => a.tgl_awal - b.tgl_awal).map(vItems => {
				vItems.tgl_awal = undefined;
				return vItems;
			});

		setDlgfullscreenshow(true);
	}
	const hdlKlikViewruangan = () => {
		//--VALIDASI_TANGGAL--/
		//--END VALIDASI_TANGGAL--/

		apiLoadruangan();
	}
	const hdlKlikRuangpasien = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vDataviewArr	= uDlgfullscreenshowObj.data_arr || [];
		const vArridx	= UFunc.getObjectIndeks(vDataviewArr,_IDTABEL)
		const vPasienID	= vDataviewArr[vArridx].pasien_id || 0;
		
		/*const vTmpObj	= { initID:_IDTABEL }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})//-*/

		if(uTokenObj.userhak === "DOKTER") {
			const vTglantri		= uDlgfullscreenshowObj.tgl_ruangan || UFunc.DbDate();
			const vTglnow		= UFunc.DbDate();

			if(vTglantri !== vTglnow) 
				{ return; }
			if((vDataviewArr[vArridx].dokter_id||"0").toString() !== (uTokenObj.user_dokterid||"0").toString())
				{ return; }

			uNavigate("/subpasien/pasien/rekammedis/"+vPasienID);
			return;
		}

		uNavigate("/subpasien/pasien/daftarantri/"+vPasienID);
	}
	//--END HANDEL--/
	const hdlKlikDlgformsimpan = async() => {
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uDlgformObj.tabelid);

		let vElfocus	= document.getElementById("inpdoktercaption");
		if((parseInt(uDlgformObj.dokter_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Dokter Rujukan Harus Ditentukan Terlebih Dahulu !"); return;
		}//-*/
		
		if((uDlgformObj.is_satusehat||false)===true) {
			if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") === ""
				&& (uDlgformObj.dokter_ihs||"") === "" 
			) {
				if(await Konfirm(
				"<b>Pasien dan Dokter Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS Number  Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS NUMBER) ?"+
				"")) apiProseshadir();
				return;
			}

			if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") === "") {
				if(await Konfirm(
				"<b>Pasien Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS Pasien Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"<p>Anda Dapat melakukan Setting IHS Pasien melalui Menu: Pasien >> Data Pasien >> <b>EDIT DATA</b>.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS PASIEN) ?"+
				"")) apiProseshadir();

				return;
			}
			
			if((uDlgformObj.dokter_ihs||"") === "") {
				if(await Konfirm(
				"<b>Dokter Rujukan Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS DOKTER Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"<p>Anda Dapat melakukan Setting IHS Dokter melalui Menu: Master Data >> Data Dokter >> <b>EDIT DATA</b>.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS DOKTER) ?"+
				"")) apiProseshadir();

				return;
			}
		}

		apiProseshadir();
	}

	//--END HANDEL--/

	//--INIT--/
	const initSetkehadiran = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionUpper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		uDlgformObj.dlg_headers	= "SET Kehadiran: <b>"+vCaptionUpper+"</b>..";
		uDlgformObj.tabelid 	= _IDTABEL;

		if((parseInt(uDatamainArr[vArridx].dokter_id)||0) > 0) {
			uDlgformObj.dokter_id 		= uDatamainArr[vArridx].dokter_id;
			uDlgformObj.dokter_caption 	= uDatamainArr[vArridx].dokter_caption;
			uDlgformObj.dokter_ihs 		= uDatamainArr[vArridx].dokter_ihs;
		}
		/*uDlgformObj.is_satusehat 	= (parseInt(uDatamainArr[vArridx].dokter_id)||0) > 0 &&
			((uTokensatusehatObj.access_token||"")!=="");//-*/

		uDlgformObj.is_satusehat 	= false
		uDlgformObj.status_init 	= "HADIR";
		setDlgformshow(true);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentDataumum = () => {
		if(uHtml500msg) return "";

		const vTglnow		= UFunc.TglAngka();
		const vCabangiduser	= (parseInt(uTokenObj.user_cabangid)||0);
		const vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)<=0);
		const vTglfilter	= UFunc.TglAngka(uTglAntri);
		const vCabangidfilter= parseInt(uCabangid);

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabelumum">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="10%" className="px-1">No Urut</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="10" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="2" className="">Jam Hadir</CCol>
			</CRow>
			</th>
			<th width="3%" className="px-1 d-none d-md-table-cell">&nbsp;</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,pasien_id,pasien_caption,pasien_telp,no_urut,jam_hadir,keterangan,
				proses_init,periksa_init,status_piutang
			} = vItems;

			const vNumber 		= vKeys+1;
			const vCaptionUpper	= (pasien_caption||"").toUpperCase();

			const vKehadirancaption = (jam_hadir||"")==="" ? "BELUM HADIR"
				: jam_hadir.substring(0,5);
			const vKehadiranclass = (jam_hadir||"")==="" ? " text-primary"
				: " text-success classfontlarger";

			return (
			<tr id={"idtr"+id} key={vKeys} 
				className={"align-top text-end "+(uRowumumselect===vKeys?"classrowselect border border-warning":"")}>
				<td className="px-1 text-md-center"><b>{(no_urut)}</b></td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="10" className="px-1 text-start d-flex justify-content-between">
					<div>
						<b onClick={()=>hdlKlikPasien(id)}
							className="text-capitalize text-info classcursorpointer d-none d-md-inline">
							{(pasien_caption||"UNDF")}
						</b>
						<b className="text-capitalize text-info d-md-none">
							{(pasien_caption||"UNDF")}
						</b>
						{(status_piutang||"TIDAK")==="YA" && (
						<>
							<span className="mx-1">&middot;</span>
							<CTooltip content={"--Status ADA PIUTANG"}>
							<CImage src={pjson.homepage+"api/images/icon_piutang.png"} className="align-top" height={30}/>
							</CTooltip>
						</>
						)}

						<small className="fst-italic text-capitalize d-none d-md-block classfontsmaller">{keterangan}</small>						
						
						{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
						<small className="text-success d-none d-md-block">
						Telp/HP. <b>{pasien_telp||"-"}</b>
						</small>
						)}
					</div>

					{uISHapusallow && (
					<div className="d-none d-md-block">
						<CTooltip content={"--"+(uBahasaObj.caption_hapusantrian||"Hapus Antrian")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
					</div>
					)}

					<div className="d-md-none">
					{(UFunc.isEmpty(periksa_init)===false 
						&& (vTglnow===vTglfilter)
						) && (
						<>
						{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") ? (
						<CLink onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CLink>
						) : (
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						)}

						<span className="mx-1">&middot;</span>
						</>
					)}

					<CDropdown>
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="me-0" height={18}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikPasien(id)}>
							{uTokenObj.userhak==="DOKTER" ? (
							<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
							) : (
							<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
							)}
							Proses Antrian
						</CDropdownItem>

						{uISHapusallow && (
						<>
						<CDropdownDivider/>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikKehadiran(id)}>
							<CImage src={pjson.homepage+"api/images/menus_pasien.png"} className="align-middle mx-0 me-2" height={25}/>
							Set/Unset Kehadiran
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					</div>
				</CCol>

				{(keterangan||"")!=="" && (
				<>
				<CCol xs="12" className="px-1 d-md-none my-2 classborderbottom"/>
				<CCol xs="12" className="px-1 d-md-none text-start">
					<small className="fst-italic classpetunjuk ps-0">{keterangan}</small>
				</CCol>
				</>
				)}
				<CCol xs="12" className="px-1 d-md-none my-2 mb-3 classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_notelp||"No.Telp"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<b className="text-success">{pasien_telp||"-"}</b>
				</CCol>

				{(vTglnow===vTglfilter && vCabangiduser===vCabangidfilter) && (
				<CCol xs="5" className="px-1 d-md-none text-start">Jam Hadir</CCol>
				)}
				<CCol xs="7" md="2" className="px-1 text-md-center classfontsmaller">
				{(vTglnow===vTglfilter && vCabangiduser===vCabangidfilter) ? (
					<>
					<span className={"d-md-none "+vKehadiranclass}>{vKehadirancaption}</span>
					<div className="d-none d-md-block">
					{(!uISHapusallow) ? (
						<small className={vKehadiranclass}>{vKehadirancaption}</small>
					):(
					<CTooltip content="--Klik Untuk SET KEHADIRAN">
					<CLink onClick={()=>hdlKlikKehadiran(id)} 
						className="classcursorpointer classlinkinherit">
						<small className={vKehadiranclass}>{vKehadirancaption}</small>
					</CLink>
					</CTooltip>
					)}
					</div>
					</>
				) : (
					<span className="d-none d-md-block">&middot;</span>
				)}
				</CCol>
				</CRow>
				</td>

				<td className="px-1 text-center d-none d-md-table-cell">
				{(UFunc.isEmpty(periksa_init)===false 
					&& (vTglnow===vTglfilter)
					) && (
					<>
					{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") ? (
						<CTooltip content={"--Status: SEDANG DIPERIKSA. (Klik Untuk Batalkan)"}>
						<CLink onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CLink>
						</CTooltip>
					) : (
						<CTooltip content={"--Status: SEDANG DIPERIKSA. (Hubungi PENGELOLA Untuk Batalkan)"}>
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CTooltip>
					)}
					</>
				)}
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDatajanjian = () => {
		if(uHtml500msg) return ""

		const vTglnow		= UFunc.TglAngka();
		const vCabangiduser	= (parseInt(uTokenObj.user_cabangid)||0);
		const vTglfilter	= UFunc.TglAngka(uTglAntri);
		const vCabangidfilter= parseInt(uCabangid);
		const vArrcolor  	= ["#FE100111","#33FE0111","#AA01FE11","#0174FE11"] 

		let vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)>0);
		if(uTokenObj.userhak==="DOKTER")
			vDataviewArr	= uDatamainArr.filter(vItems=>
				(parseInt(vItems.dokter_id)||0)===(parseInt(uTokenObj.user_dokterid)||0)
			);

		let vNumber 		= 0;
		let vDokteridbefore	= 0;
		let vColoridx		= -1;
		return (
		<table className="table table-borderless table-hover" id="idtabeljanjian">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="5" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
			<CCol md="2" className="">Jam<br/>Janjian</CCol>
			<CCol md="2" className="">Jam<br/>Hadir</CCol>
			</CRow>
			</th>
			<th width="3%" className="px-1 d-none d-md-table-cell">&nbsp;</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,pasien_id,pasien_caption,pasien_telp,dokter_id,dokter_caption,
				jam_awal,jam_hadir,keterangan,status_piutang,
				proses_init,periksa_init,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (pasien_caption||"").toUpperCase();

			const vKehadirancaption = (jam_hadir||"")==="" ? "BELUM HADIR"
				: jam_hadir.substring(0,5);
			const vKehadiranclass = (jam_hadir||"")==="" ? " text-primary"
				: " text-success classfontlarger";

			if(vDokteridbefore !== (dokter_id||"0").toString()) vColoridx++;
			vColoridx		= vColoridx%(vArrcolor.length);

			const vBgcolor 	= vArrcolor[vColoridx];

			vDokteridbefore	= (dokter_id||"0").toString();

			return (
			<tr id={"idtr"+id} key={vKeys} 
				style={{backgroundColor:vBgcolor}}
				className={"align-top text-end"+(uRowantrianselect===vKeys?" bg-light border border-warning":" classborderbottom")}>
				<td className="px-1 ">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="5" className="px-1 text-start d-flex justify-content-between">
					<div>
						<b onClick={()=>hdlKlikPasien(id)}
							className="text-capitalize text-info classcursorpointer d-none d-md-inline">
							{(pasien_caption||"UNDF")}
						</b>
						<b className="text-capitalize text-info d-md-none">
							{(pasien_caption||"UNDF")}
						</b>
						{(status_piutang||"TIDAK")==="YA" && (
						<>
							<span className="mx-1">&middot;</span>
							<CTooltip content={"--Status ADA PIUTANG"}>
							<CImage src={pjson.homepage+"api/images/icon_piutang.png"} 
								className="align-top" height={30}/>
							</CTooltip>
						</>
						)}

						<small className="fst-italic text-capitalize d-none d-md-block classfontsmaller">{keterangan}</small>
						
						{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
						<small className="text-success d-none d-md-block">
						Telp/HP. <b>{pasien_telp||""}</b>
						</small>
						)}
					</div>

					{uISHapusallow && (
					<div className="d-none d-md-block">
						<CTooltip content={"--"+(uBahasaObj.caption_hapusantrian||"Hapus Antrian")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
					</div>
					)}

					<div className="d-md-none">
					{(UFunc.isEmpty(periksa_init)===false 
						&& (vTglnow===vTglfilter)
						) && (
						<>
						{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") ? (
						<CLink onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CLink>
						) : (
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						)}

						<span className="mx-1">&middot;</span>
						</>
					)}

					<CDropdown>
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="me-0" height={18}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikPasien(id)}>
							{uTokenObj.userhak==="DOKTER" ? (
							<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
							) : (
							<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
							)}
							Proses Antrian
						</CDropdownItem>

						{uISHapusallow && (
						<>
						<CDropdownDivider/>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikKehadiran(id)}>
							<CImage src={pjson.homepage+"api/images/menus_pasien.png"} className="align-middle mx-0 me-2" height={25}/>
							Set/Unset Kehadiran
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					</div>
				</CCol>

				{(keterangan||"")!=="" && (
				<>
				<CCol xs="12" className="px-1 d-md-none my-2 classborderbottom"/>
				<CCol xs="12" className="px-1 d-md-none text-start">
					<small className="fst-italic classpetunjuk ps-0">{keterangan}</small>
				</CCol>
				</>
				)}
				<CCol xs="12" className="px-1 d-md-none my-2 mb-3 classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_notelp||"No.Telp"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<b className="text-success">{pasien_telp||"-"}</b>
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.Usercaptionfilter((dokter_caption||"UNDF"),3)}</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">Jam Janjian</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center text-danger fw-bolder">{(jam_awal||"").substring(0,5)}</CCol>

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				{(vTglnow===vTglfilter) && (
				<CCol xs="5" className="px-1 d-md-none text-start">Jam Hadir</CCol>
				)}

				<CCol xs="7" md="2" className="px-1 px-md-0 text-md-center classfontsmaller">
				{(vTglnow===vTglfilter && vCabangiduser===vCabangidfilter) ? (
					<>
					<span className={"d-md-none "+vKehadiranclass}>{vKehadirancaption}</span>
					<div className="d-none d-md-block">
					{(!uISHapusallow) ? (
						<small className={vKehadiranclass}>{vKehadirancaption}</small>
					):(
					<CTooltip content="--Klik Untuk SET KEHADIRAN">
					<CLink onClick={()=>hdlKlikKehadiran(id)} 
						className="classcursorpointer classlinkinherit">
						<small className={vKehadiranclass}>{vKehadirancaption}</small>
					</CLink>
					</CTooltip>
					)}
					</div>
					</>
				) : (
					<>
					<span className="d-none d-md-block">&middot;</span>
					</>
				)}
				</CCol>
				</CRow>
				</td>

				<td className="px-1 text-center d-none d-md-table-cell">
				{(UFunc.isEmpty(periksa_init)===false 
					&& vTglnow===vTglfilter
					) && (
					<>
					{((uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN")
						&& vCabangiduser===vCabangidfilter
					) ? (
						<CTooltip content={"--Status: SEDANG DIPERIKSA. (Klik Untuk Batalkan)"}>
						<CLink onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CLink>
						</CTooltip>
					) : (
						<CTooltip content={"--Status: SEDANG DIPERIKSA. (Hubungi PENGELOLA Untuk Batalkan)"}>
						<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={25} className="classblinking"/>
						</CTooltip>
					)}
					</>
				)}
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CRow className="my-2">
			<CCol xs="5" className="">
				<span className="d-none d-md-block">{uBahasaObj.caption_confirmkehadiran||"Konfirmasi Kehadiran"}</span>
				<small className="d-md-none">{uBahasaObj.caption_confirmkehadiran||"Konfirmasi Kehadiran"}</small>
			</CCol>
			<CCol className="fst-italic">
			<CFormCheck size="sm" label="KIRIMKAN ENCOUNTER ke SATUSEHAT"
				value="YA"
				className="d-none"
				checked={uDlgformObj.is_satusehat || false}
				onChange={(e)=>{
					uDlgformObj.is_satusehat = !(uDlgformObj.is_satusehat || false);
					setEfekview(!uEfekview);
				}}
				id="inpcheckkehadiran"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="5" className="fw-bolder">
				<span className="d-none d-md-block">{uBahasaObj.caption_dokterrujukan||"Dokter Rujukan"}</span>
				<small className="d-md-none">{uBahasaObj.caption_dokterrujukan||"Nama Rujukan"}</small>
			</CCol>
			<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder="--Pilih Dokter Rujukan"
				value={uDlgformObj.dokter_caption||""}
				onChange={(e)=>{uDlgformObj.dokter_caption=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownDoktercaption(e)}
				id="inpdoktercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikDokterlookup()}
				id="btndokterlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			</CCol>
		</CRow>

		</CForm>
		)//>
	}
	const contentDlgfullscreen = () => {
		if(!uISDlgfullscreenshow) return (<></>)//>

		const vDataviewArr	= uDlgfullscreenshowObj.data_arr || [];
		const vTglantri		= uDlgfullscreenshowObj.tgl_ruangan || UFunc.DbDate();
		const vIsDoktershow	= (uDlgfullscreenshowObj.status_doktershow || "YA")==="YA";
		const vIsPerawatshow= (uDlgfullscreenshowObj.status_perawatshow || "TIDAK")==="YA";

		const vJmlruangan	= parseInt(uCabanginitObj.jml_ruangan) || 2;
		const vJamstartidx	= 8;		
		const vJamfinishidx	= 21;

		const vWidthno		= 50;
		const vWidthFirstkol= 175;
		const vWidthkolrg	= 220;

		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthFirstkol*vJmlruangan)

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "white",
			zIndex: 1,
		}

		return (
		<>
		<CCard className="classbgcard d-md-none">
		<CCardHeader className="fw-bolder">Invalid View</CCardHeader>
		<CCardBody>
			<p>Maaf,</p>
			<p className="fw-bolder">Data Antrian Ruang Hanya Dapat Dilihat Melalui Mode Tampilan Tablet.</p>
			<p>Saat Ini Mode Tampilan Anda adalah Mode Tampilan Celuler.<br/><br/>Mohon Ubah Mode Tampilan Anda !</p>
		</CCardBody>
		</CCard>

		<CCard className="classbgcard d-none d-md-block">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
			<CForm className="d-inline-block">
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			<CFormInput size="sm" type="date"
				value={uDlgfullscreenshowObj.tgl_ruangan}
				onChange={(e)=>{uDlgfullscreenshowObj.tgl_ruangan=e.target.value;setEfekview(!uEfekview)}}
				id="inptglruangan"/>
			<CButton size="sm" color="dark" onClick={()=>hdlKlikViewruangan()} id="btnCariruangan">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			</CForm>
			</div>

			<div className="classfontsmaller">
			<small>
			<CInputGroup size="sm">
			<span className="text-info d-none d-md-inline">{uCabanginitObj.nama}</span>

			<span className="mx-2 d-none d-md-inline">&middot;</span>

			<CFormCheck	value="YA" size="sm"
				label="Tampilkan DOKTER"
				checked={vIsDoktershow}
				onChange={(e)=>{uDlgfullscreenshowObj.status_doktershow=((uDlgfullscreenshowObj.status_doktershow||"YA")==="YA"?"TIDAK":"YA");setEfekview(!uEfekview);}}
				id="chbwithdokter"/>

			<span className="mx-2">&middot;</span>

			<CFormCheck	value="YA" size="sm"
				label="Tampilkan PERAWAT"
				checked={vIsPerawatshow}
				onChange={(e)=>{uDlgfullscreenshowObj.status_perawatshow=((uDlgfullscreenshowObj.status_perawatshow||"TIDAK")==="YA"?"TIDAK":"YA");setEfekview(!uEfekview);}}
				id="chbwithnurse"/>
			</CInputGroup>
			</small>
			</div>
		</CCardHeader>
		
		<CCardBody>
		<div className="d-none">vDataviewArr = {JSON.stringify(vDataviewArr)}</div>
		{(uDlgfullscreenshowObj.is_loading||false)===true ? (
			<div className="py-5 text-center">
			<CSpinner color="primary" className="mx-auto my-5"/>
			</div>
		) : (
			<div style={{overflowX:"auto"}}
				className=""
				id="iddivtabel">
			<table 
				style={{width:vWidthtabel,borderCollapse:"separate"}}
				className="table mx-auto" 
				id="idtabeldata">
			<thead>
				<tr className="d-none d-md-table-row text-center">
				<th width={vWidthno} className="bg-white" style={vStylekolfixed}>No</th>
				<th width={vWidthFirstkol} className="bg-white" style={{...vStylekolfixed,left:vWidthno}}>Waktu Antrian</th>
				{(() => {
					let vHTMLth = [];
					for (var vIdx=1;vIdx<=vJmlruangan;vIdx++){
						vHTMLth.push(<th width={vWidthkolrg} key={vIdx}
							className="">Ruang {vIdx}</th>)
					}
					return vHTMLth;
				})()}
				</tr>
			</thead>
			<tbody>
			{(() => {
			let vHTMLtr = [];
			let vNumber	= 0;
			for (var vIdxrow=vJamstartidx;vIdxrow<=vJamfinishidx;vIdxrow++){
				vNumber++;
				const vJamAwalDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow)+":00:00"
					);
				const vJamAkhirDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow+1)+":00:00"
					);
				vHTMLtr.push(
				<tr className="align-middle" style={{height:100}}>
				<td className="border text-end bg-light" style={vStylekolfixed}>{vNumber}.</td>
				<td className="border text-center bg-light" style={{...vStylekolfixed,left:vWidthno}}>
					{UFunc.JamMenit(vJamAwalDT)} - {UFunc.JamMenit(vJamAkhirDT)}
				</td>
				
				{(() => {
					let vHTMLtd = [];
					for (var vIdxkol=1;vIdxkol<=vJmlruangan;vIdxkol++){
						const vFiltercontentArr = vDataviewArr.filter(vItems=>{
							const vJamRgawalDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_awal
								);
							const vJamRgakhirDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_akhir
								);

							return (parseInt(vItems.no_ruang||0)===vIdxkol)
							&& ((vJamRgawalDT >= vJamAwalDT) && (vJamRgawalDT < vJamAkhirDT) 
								|| (vJamRgakhirDT <= vJamAkhirDT) && (vJamRgakhirDT > vJamAwalDT) 
								|| (vJamAkhirDT > vJamRgawalDT) && (vJamAkhirDT < vJamRgakhirDT) 
							)
						});

						let vStyleBGbox	= "#00008011";
						if(vFiltercontentArr.length > 0)
						if(uTokenObj.userhak==="DOKTER")
							if((vFiltercontentArr[0].dokter_id||"0").toString() === (uTokenObj.user_dokterid||"0").toString())
								vStyleBGbox = "#FE100111"

						vHTMLtd.push(
						<td className={"border text-center "+(vFiltercontentArr.length <= 0 ? "align-middle" : "align-top")}>
						{vFiltercontentArr.length <= 0 && (
							<b className="text-light">RG-{vIdxkol}</b>
						)}
						{vFiltercontentArr.map((vItems,vKeys)=>{
							const vPasienArr = (vItems.pasien_caption||"").split(" ");
							return (
							<div className="px-1 py-1 classfontsmaller text-center classcursorpointer"
								onClick={()=>hdlKlikRuangpasien(vItems.id)}
								key={vKeys}
								style={{border:"3px dashed navy",borderRadius:7,backgroundColor:vStyleBGbox}}
								id={"idbox"+vItems.id}>
							<b className="text-primary">{(vPasienArr[0]+" "+(vPasienArr[1]||"")).trim()}</b>

							<div className="text-danger">
							({(vItems.jam_awal||"").substring(0,5)} - {(vItems.jam_akhir||"").substring(0,5)})
							</div>

							{vIsDoktershow && (
							<div className="text-success fst-italic">{UFunc.Usercaptionfilter(vItems.dokter_caption,3)}</div>
							)}

							{(vIsPerawatshow && (vItems.perawat_caption||"").trim()!=="") && (
							<div className="">&middot;&middot; {vItems.perawat_caption} &middot;&middot;</div>
							)}
							</div>
							)
						})}
						</td>
						);
					}
					return vHTMLtd;
				})()}
				</tr>
				);
			}
			return vHTMLtr;
			})()}
			</tbody>
			</table>
			</div>
		)}
		</CCardBody>
		</CCard>
		</>
		)//>
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatamainArr([]);
		setIDselect(0);
		setRowumumselect(-1);
		setRowantrianselect(-1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Antrianpasien - apiLoaddata) uTglAntri => "+uTglAntri);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uCabangid,
			send_tglantri : uTglAntri,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);
				
				const vTmpObj 	= JSON.parse(output_string.cabangatribut || JSON.stringify(uCabangselectedObj));
				setCabanginitObj(vTmpObj);

				if(JSON.stringify(uLastinitObj||{}) !== "{}") 
					setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddokter = (_IDTABEL) => {
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Antrianpasien - apiLoaddata) uTglAntri => "+uTglAntri);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_dokterlookup";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatadokterArr(JSON.parse(output_string.datadokter || JSON.stringify(uDatadokterArr)));

				initSetkehadiran(_IDTABEL);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiLoaddokter) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiLoaddokter) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadruangan = () => {
		const vElbutton	= document.getElementById("btnCariruangan");
		uDlgfullscreenshowObj.data_arr 		= [];
		uDlgfullscreenshowObj.is_loading 	= true;
		vElbutton && (vElbutton.disabled = true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tglantri : uDlgfullscreenshowObj.tgl_ruangan || uTglAntri,
			send_cabangid : uCabangid || uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_janjian";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDlgfullscreenshowObj.is_loading 	= false;
			vElbutton && (vElbutton.disabled = false);
			if(output_string.tampil) {
				const vTmpArr 					= JSON.parse(output_string.dataobject||"[]");
				uDlgfullscreenshowObj.data_arr 	= vTmpArr;

				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiLoadruangan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDlgfullscreenshowObj.is_loading 	= false;
			vElbutton && (vElbutton.disabled = false);
			console.log("(Antrianpasien - apiLoadruangan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshadir = () => {
		//_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vTabelID	= parseInt(uDlgformObj.tabelid)||0; if(vTabelID <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		let vAccesstoken	= "";
		if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") !== ""
			&& (uDlgformObj.dokter_ihs||"") !== "" 
			&& (uDlgformObj.is_satusehat||false)===true
		) {//-*/
			vAccesstoken 		= uTokensatusehatObj.access_token;
		
	 		if(!vAccesstoken) {
	 			loadToken(apiProseshadir); return;
	 		}
			const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
			const vTimestampnow 	= new Date();
			const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
			const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

			if(vSelisih > vLimitedsec) {
	 			loadToken(apiProseshadir); return;
			}

			uDlgformObj.ihs_pasien 		= (uDatamainArr[vArridx]||{}).ihs_pasien ||"";
			uDlgformObj.caption_pasien 	= (uDatamainArr[vArridx]||{}).caption_pasien ||"";
		}

		uDlgformObj.status_satusehat = vAccesstoken!=="" ? "YA" : "TIDAK";

		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_cabangid : uCabangid,
			send_accesstoken : vAccesstoken,
			send_datajson : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hadir";//--SEKALIAN_ENCOUNTER_SATUSEHAT--/

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].jam_hadir = (uDatamainArr[vArridx].jam_hadir||"") === ""
					? UFunc.JamMenit() : "";
			
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProseshadir) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Antrianpasien - apiProseshadir) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbatalperiksa = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].pasien_id || 0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_prosesbatal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].periksa_init = "";
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProsesbatalperiksa) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiProsesbatalperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid : uCabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowumumselect(-1);
				setRowantrianselect(-1);
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		uDispatch({type: "set", gBillingperiksaObj: undefined});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);setDatadokterArr([]);setInitselectedObj({});
    		setDlgformshow(false);setDlglookupshow(false);
    		setFirstload("YA"); setDlgfullscreenshow(false);
			vContent && (vContent.style.border = "none");
    	}
	},[])
	React.useEffect(()=>{
		if(!vContent) return;

		vContent.style.padding 	= "100px !important";
		vContent.style.border 	= "1px solid lime";
	},[vContent])
	React.useEffect(()=>{
		if(!uHandelView) return;

		apiLoaddata();

		/*const vIntervalMS	= 1 * 60 * 1000;
		const vTimeinterval	= setInterval(()=>{ 
			console.log("(Antrianpasien - [uHandelView]) vTimeinterval 1");
			if(uTglNow === uTglAntri) {
				console.log("(Antrianpasien - [uHandelView]) vTimeinterval 2");
				apiLoaddata(); 
			}
		},vIntervalMS)//-*/

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+(uInitselectedObj.initID||0))
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+(uInitselectedObj.initID||0))
			setRowantrianselect(vRowidx);
		}

		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) {
				document.getElementById("inpdoktercaption") &&
					document.getElementById("inpdoktercaption").focus();
			}
		},350);
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) { setDlglookupObj({}); return }
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if(!uISDlgfullscreenshow) 
			{ setDlgfullscreenshowObj({}); return }
	},[uISDlgfullscreenshow])

	//console.log("Antrianpasien - uPageActive => "+JSON.stringify(uPageActive));
	/*if(isMobile)  return (
		<CCard>
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>
			Maaf,<br/>Fitur Ini Belum Bisa Diproses Melalui Perangkat Mobile...
			<br/><br/>
			Terima kasih
		</CCardBody>
		</CCard>
	)//-*/

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard mb-3">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<div style={{margin:isMobile?"": "auto -30px"}}>
		<CRow className="">
		<CCol xs="12" lg="7" className="pe-lg-1 position-lg-relative">
			<CCard className="classbgcard mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center text-white"
				style={{backgroundColor:"#666699"}}>
			<b>{uBahasaObj.caption_reservasijanjian||"Reservasi PERJANJIAN"}</b>
			
			<div>
			<CTooltip className="d-none d-lg-inline" content="--Lihat Reservasi Ruangan">
			<CLink onClick={()=>hdlKlikRuangan()} className="classcursorpointer">
				<CImage src={pjson.homepage+"api\/images\/menus_antrianruang.png"} className="classikonbox" height={30}/>
			</CLink>
			</CTooltip>

			<span className="mx-1">&middot;</span>

			<small>(
				{(()=>{
					let vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)>0);
					if(uTokenObj.userhak==="DOKTER")
						vDataviewArr	= uDatamainArr.filter(vItems=>
							(parseInt(vItems.dokter_id)||0)===(parseInt(uTokenObj.user_dokterid)||0)
						);
					return UFunc.formatAngka(vDataviewArr.length)
				})()}
			)</small>

			<span className="mx-1">&middot;</span>

			<span className="classfontsmaller"><small>{UFunc.formatAngka(uTimeelapse)}ms</small></span>
			</div>
			</CCardHeader>
			<CCardBody className="px-0">
			{contentDatajanjian()}
			</CCardBody>
			</CCard>
		</CCol>

		<CCol xs="12" lg="5" className="ps-lg-1 position-lg-relative">
			<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center text-white"
				style={{backgroundColor:"#993366"}}>
			<b>{uBahasaObj.caption_reservasiumum||"Reservasi Umum"}</b>

			<CImage src={pjson.homepage+"api/images/img_spacer.png"} className="align-middle" height={30}/>
			<small>(
				{(()=>{
					const vDataviewArr = uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)<=0);
					return UFunc.formatAngka(vDataviewArr.length)
				})()}
			)</small>
			</CCardHeader>
			<CCardBody className="px-0">
			{contentDataumum()}
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_headers||"Lookup Data"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.items_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.dlg_headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogviewfullscreen
			show={uISDlgfullscreenshow} 
			dialogHeader={uDlgfullscreenshowObj.dlg_headers}
			toggle={()=>setDlgfullscreenshow(false)}
			renderContent={contentDlgfullscreen()}
			{...props}/>
		</>
	)//>
}	

export default Antrianpasien