import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialoglookup,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Perawatjadwal 	= (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	const [uFirstload,setFirstload]		= React.useState("YA");

	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDataperawatArr,setDataperawatArr]= React.useState([]);
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END VIEWS_VARS--/

	//---LOOKUP_VARS--/
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uInpDlglookup,setInpDlglookup] 		= React.useState("");
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	//---END LOOKUP_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggleDlgLookup = () =>{ setDlglookupshow(false) }

	const hdlKlikEditantrian = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setRowselect(vRowidx);

		const uPasienID	= uDatamainArr[vArridx].pasien_id || 0;
		const vTmpObj	= { initID:_IDTABEL,initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})

		uNavigate("/subpasien/pasien/daftarantri/"+uPasienID);
	}
	const hdlKlikPerawat = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setRowselect(vRowidx);

		const vPerawatID	= parseInt(uDatamainArr[vArridx].perawat_id || 0)
		if(vPerawatID > 0) {
			if(await(Konfirm("Batalkan Perawat Untuk: <B>"+vCaptionupper+"</B>"+
				"<br/>Apakah Anda Yakin ?"+
				""))) apiProsesperawat(_IDTABEL); return
		}

		const vDatalookupArr = uDataperawatArr.map(vItems =>{ 
			return {id: vItems.id,caption:vItems.perawat_caption} 
		})

		//console.log("(Forminvoice - hdlKlikCustomerlookup) vDatalookupArr => "+JSON.stringify(vDatalookupArr)); 
		setIDselect(_IDTABEL);
		setDataDlglookupArr(vDatalookupArr)
		setDlglookupheader((uBahasaObj.word_pilih||"Pilih")+" "+
			(uBahasaObj.caption_dataperawat||"Data Perawat"))
		setInpDlglookup("")
		setDlglookupshow(true)
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0
		if(_IDITEM <= 0) return

		const vPerawatidx 	= UFunc.getObjectIndeks(uDataperawatArr,_IDITEM)
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,uIDselect);

		apiProsesperawat(uIDselect,uDataperawatArr[vPerawatidx].id);
	}
	//--END HANDEL--/

	//--INIT--/
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="5" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="3" className="text-center">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</CCol>
			<CCol md="2" className="text-center">{uBahasaObj.caption_noruang||"No.Ruang"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,dokter_id,dokter_caption,pasien_id,pasien_caption,jam_awal,jam_akhir,
				no_ruang,perawat_id,perawat_caption,
				init_process,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (pasien_caption||"UNDF").toUpperCase();

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="5" className="px-1 text-start d-flex justify-content-between">
					<div>
						<span className="text-capitalize text-info">{pasien_caption||"UNDF"}</span>
						<small className="text-success fst-italic d-none d-md-block">{dokter_caption||""}</small>
					</div>

					<div className="d-none d-md-block">
					<CTooltip content={"--Edit Antrian: "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEditantrian(id)}
						className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle" height={28}/>
					</CLink> 
					</CTooltip> 
					</div> 

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="me-0" height={18}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikEditantrian(id)}>
							<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle ms-0 me-2" height={25}/>
							{uBahasaObj.caption_editantrian||"Edit Antrian"}
						</CDropdownItem>

						{uTokenObj.userhak!=="DOKTER" && (
						<>
						<CDropdownItem onClick={()=>hdlKlikPerawat(id)}>
						{parseInt(perawat_id||0) <= 0 ? (
							<>
							<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_setperawat||"Set Perawat"}
							</>
						) : (
							<>
							<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
							{uBahasaObj.caption_batalperawat||"Batalkan Perawat"}
							</>
						)}
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="px-1 my-3 classborderbottom d-md-none"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_dokter}</CCol>
				<CCol xs="8" className="px-1 d-md-none text-success fst-italic">
					{dokter_caption||""}
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</CCol>
				<CCol xs="8" md="3" className="px-1 text-md-center">
					{(jam_awal||"").substring(0,5)} - {(jam_akhir||"").substring(0,5)}
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_noruang||"Ruangan"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-center text-primary">
					<span className="d-md-none">No-</span><big className="text-primary fw-bolder">{no_ruang||1}</big>
				</CCol>

				<CCol xs="12" className="px-1 my-3 classborderbottom d-md-none"/>

				<CCol xs="4" className="px-1 text-start d-md-none text-primary">{uBahasaObj.word_perawat||"Perawat"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					{parseInt(perawat_id||0) <= 0 ? (
					<span className="text-muted fst-italic">(Unselect Perawat)</span>
					) : (
					<b className="text-primary">{perawat_caption}</b>
					)}
				</CCol>

				<CCol md="2" className="px-1 text-md-center d-none d-md-block">
				{uTokenObj.userhak==="DOKTER" ? (
					<>
					{parseInt(perawat_id||0) <= 0 ? (
					<span className="text-muted fst-italic">(Unselect Perawat)</span>
					) : (
					<b className="text-primary">{perawat_caption}</b>
					)}
					</>
				) : (
					<>
					{parseInt(perawat_id||0) <= 0 ? (
					<CButton size="sm"
						onClick={()=>hdlKlikPerawat(id)}
						color="light"
						className="border-dark"
						id={"btnsetperawat"+id}>
						<small>Pilih Perawat</small>
					</CButton>
					) : (
					<CTooltip content={"--Batalkan Perawat: "+vCaptionupper}>
					<CLink 
						onClick={()=>hdlKlikPerawat(id)}
						className="classcursorpointer classlinkinherit text-primary">{perawat_caption||"UNDF"}</CLink>
					</CTooltip> 
					)}
					</>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vParamsObj = uKeywordObj.filter_perawatjadwal || {};
		const vDATAS	= JSON.stringify({
			send_tglantri : vParamsObj.tgl_antri || UFunc.DbDate(),
			send_cabangid : vParamsObj.cabang_id || uTokenObj.user_cabangid,
			send_firstload: uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_janjian";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr 		= JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);
				setJmlData((parseInt(vTmpArr.length)||0));

				const vPerawatArr 	= JSON.parse(output_string.dataperawat||JSON.stringify(uDataperawatArr));
				setDataperawatArr(vPerawatArr);
				setFirstload("TIDAK");

		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Perawatjadwal - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Perawatjadwal - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesperawat = (_IDTABEL,_IDPERAWAT) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return
		_IDPERAWAT 	= parseInt(_IDPERAWAT)||0;

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vPerawatidx 	= UFunc.getObjectIndeks(uDataperawatArr,_IDPERAWAT)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Perawatjadwal - apiProsessimpan) uDataperawatArr => "+JSON.stringify(uDataperawatArr)); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_perawatid : _IDPERAWAT,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_simpanperawat";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].perawat_id  		= _IDPERAWAT > 0
					? (uDataperawatArr[vPerawatidx].id||0) : 0;
				uDatamainArr[vArridx].perawat_caption   =  _IDPERAWAT > 0
					? (uDataperawatArr[vPerawatidx].perawat_caption || "") : "";
				hdlToggleDlgLookup();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Perawatjadwal - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlglookupshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Perawatjadwal - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			setDlglookupshow(false);
    		setDatamainArr([]);
    		setDataperawatArr([])
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ 
		if(JSON.stringify(uInitselectedObj||{}) === "{}") 
			{ setRowselect(-1);return; }

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
		
		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uPageActive])//-->Unselect_ROW_UNTUK_JENIS_DATA_LOAD_ALL
	React.useEffect(()=>{
		if(!uHandelView) return;

		const vKeyword = (uKeywordObj.kwd_dokternote||"").trim();
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		if(parseInt(uInitselectedObj.initPage||1) > 1)
			{ setPageActive((uInitselectedObj.initPage||1)); return }

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
			
		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])//-->UNTUK_JENIS_DATA_LOAD_ALL
	//console.log("Perawatjadwal - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{uBahasaObj.caption_dataantrianjanjian||"Data Antrian Janjian"}</strong>
		<div>
			<small className="">({uDatamainArr.length})</small>
			<span className="mx-1">&middot;</span>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>
		
		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlgLookup()}
			dialogHeader={uDlglookupheader||"Lookup Data"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>
		</>
	)
}	

export default Perawatjadwal