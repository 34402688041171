import React from "react"
import { useSelector,useDispatch } from 'react-redux'

import { UFunc } from '../helpers/functions'
const pjson 		= require('../../package.json')

const useSatusehat = (props) => {
	const { 
		setLoading,showToast
	} = props; 

	const uDispatch 		= useDispatch();
	const uEfekapp			= useSelector(state => state.effectApp);
	const uConfigappsObj	= useSelector(state => state.uConfigappsObj) || {}; 
	const uCabangattrObj	= useSelector(state => state.gCabangatribut) || {}; 

	//console.log("(useSatusehat) uCabangattrObj => "+JSON.stringify(uCabangattrObj));
	const apiGettoken = (_NEXTFunction) => {
		const vAttrObj	= uCabangattrObj;
		//console.log("(useSatusehat - apiGettoken) vAttrObj => "+JSON.stringify(vAttrObj));

 		if(!vAttrObj.satusehat_clientkey) {
			showToast("Satusehat token Unset.."); 
			if (_NEXTFunction && {}.toString.call(_NEXTFunction) === '[object Function]') {
				_NEXTFunction();
			}
			return;
 		}
 		setLoading(true);

		const vDATAS	= //--HARUS_STRING_-/
			"client_id="+vAttrObj.satusehat_clientkey+
			"&client_secret="+vAttrObj.satusehat_secretkey+
		"";
		const vURLs		= uConfigappsObj.satusehat_authurl+
			"accesstoken?grant_type=client_credentials"+
			"";
		//console.log("(useSatusehat - apiGettoken) vURLs => "+vURLs);

		fetch(vURLs,{
			method: "POST",
			headers: { 
				Accept: "*/*", 
				"Content-Type": "application/x-www-form-urlencoded",
				Connection:"keep-alive",
			},
			body: vDATAS,
		}).then((response)=> { 
			if(response.status === 200) { return response.json(); }
		}).then((output_string) => {
 			setLoading(false);
 			if((output_string.access_token||"")!=="")	{
 				//const vTimestamp	= UFunc.DbDatetime(new Date());
 				const vTmpObj		= output_string;//{...output_string,timestamp_start: vTimestamp};
				localStorage.setItem("tokensatusehat", JSON.stringify(vTmpObj));
				//saveToken(output_string);
				/*setaccessToken(getToken());//-*/

				/*if (_NEXTFunction && {}.toString.call(_NEXTFunction) === '[object Function]') {
					_NEXTFunction();
				}//-*/
			} else {
				console.log("(useSatusehat - apiGettoken) output_string => "+JSON.stringify(output_string));
				showToast("Satusehat token Failed (1)..","ERROR");
			}

			if (_NEXTFunction && {}.toString.call(_NEXTFunction) === '[object Function]') {
				_NEXTFunction();
			}//-*/
		})
		.catch((error) =>{
 			setLoading(false);
			console.log("(useSatusehat - apiGettoken) catch-error : "+error);
			showToast("Satusehat token Failed (2)..","ERROR");
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");

			if (_NEXTFunction && {}.toString.call(_NEXTFunction) === '[object Function]') {
				_NEXTFunction();
			}//-*/
		});
	};
	const apiLoadtoken = (_NEXTFunction) => {
		const vAttrObj	= uCabangattrObj;
		//console.log("(useSatusehat - apiLoadtoken) vAttrObj => "+JSON.stringify(vAttrObj));

 		if(!vAttrObj.satusehat_clientkey) {
			showToast("Satusehat token Unset.."); return;
 		}
 		setLoading(true);

		const vDATAS	= //--HARUS_STRING_-/
			"client_id="+vAttrObj.satusehat_clientkey+
			"&client_secret="+vAttrObj.satusehat_secretkey+
		"";
		const vURLs		= uConfigappsObj.satusehat_authurl+
			"accesstoken?grant_type=client_credentials"+
			"";
		//console.log("(useSatusehat - apiLoadtoken) vURLs => "+vURLs);

		fetch(vURLs,{
			method: "POST",
			headers: { 
				Accept: "*/*", 
				"Content-Type": "application/x-www-form-urlencoded",
				Connection:"keep-alive",
			},
			body: vDATAS,
		}).then((response)=> { 
			if(response.status === 200) { return response.json(); }
		}).then((output_string) => {
 			setLoading(false);
 			if((output_string.access_token||"")!=="")	{
 				//const vTimestamp	= UFunc.DbDatetime(new Date());
 				const vTmpObj		= output_string;//{...output_string,timestamp_start: vTimestamp};
				localStorage.setItem("tokensatusehat", JSON.stringify(vTmpObj));
				//saveToken(output_string);
				/*setaccessToken(getToken());//-*/

				if (_NEXTFunction && {}.toString.call(_NEXTFunction) === '[object Function]') {
					_NEXTFunction();
				}//-*/
			} else {
				console.log("(useSatusehat - apiLoadtoken) output_string => "+JSON.stringify(output_string));
				showToast("Satusehat token Failed (1)..","ERROR");
			}
		})
		.catch((error) =>{
 			setLoading(false);
			console.log("(useSatusehat - apiLoadtoken) catch-error : "+error);
			showToast("Satusehat token Failed (2)..","ERROR");
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	};

	const clearToken = () => {
		localStorage.removeItem("tokensatusehat");
	}

	/*React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
	},[accessToken])//*/

	return {
		getToken: apiGettoken,
		loadToken: apiLoadtoken,
		removeToken: clearToken,
	}
}

export default useSatusehat;