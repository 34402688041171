import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CRow,
	CCol,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CFormSelect,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc,cBulanPanjang } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubantrianruang = (props) => {
	const { showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uSystemsetting		= useSelector(state => state.gSystemsetting)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangfilterArr		= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
		: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
	
	const uBulanIDdefault		= UFunc.leadZero((UFunc.toDate()).getMonth() + 1);
	const uTahundefault			= (UFunc.toDate()).getFullYear();
	const vFilterview			= uKeywordObj.filter_antrianexpired || {};
	const [uInpCabangid,setInpCabangid]	= React.useState(vFilterview.cabang_id||uTokenObj.user_cabangid);
	const [uInpBulanID,setInpBulanID]	= React.useState(uBulanIDdefault);
	const [uInpTahun,setInpTahun]		= React.useState(uTahundefault);

	const hdlKDowntglantri = (_EV) => {
		if(_EV.which==13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		if(parseInt(uInpCabangid) <= 0) {
			showToast("[] UNDEFINED.."); return
		}
		if(parseInt(uInpBulanID) <= 0) 
			{ showToast("[] UNDEFINED.."); return }
		if(parseInt(uInpTahun) <= 0) 
			{ showToast("[] UNDEFINED.."); return }

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_antrianexpired = {
			cabang_id: uInpCabangid,
			bulan_id: uInpBulanID,
			tahun: uInpTahun,
		}
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.filter_antrianexpired = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpBulanID(uBulanIDdefault);
	    	setInpTahun(uTahundefault);
	    	setInpCabangid(uTokenObj.user_cabangid);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if(uInpBulanID !== uBulanIDdefault
			|| uInpTahun !== uTahundefault
			|| uInpCabangid !== uTokenObj.user_cabangid
		) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubantrianruang - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			<CInputGroup 
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			{uDatacabangArr.length > 1 && (
			<>
			{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
			<>
			<CFormSelect size="sm"
				value={uInpCabangid} 
				onChange={(e) => setInpCabangid(e.target.value)} 
				id="inpcabangid">
				{uCabangfilterArr.map((vItems,vKeys)=>{
					return (<option value={vItems.id} key={vKeys}>{vItems.cabang_nama.toUpperCase()}</option>)
				})}
			</CFormSelect>
			</>
			)}
			</>
			)}
			<CFormSelect size="sm"
				value={uInpBulanID}
				onChange={(e)=>setInpBulanID(e.target.value)}
				id="inpbulanid">
				{cBulanPanjang.map((vItems,vKeys)=>{
					return (<option key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems}</option>)
				})}
			</CFormSelect>
			<CFormSelect size="sm"
				value={uInpTahun}
				onChange={(e)=>setInpTahun(e.target.value)}
				style={{maxWidth:90}}
				id="inptahun">
				{(()=>{
					let vTahunArr = []
					for(let vTahunidx=uTahundefault;vTahunidx>uTahundefault-(parseInt(uSystemsetting.jml_tahun_laporan||5));vTahunidx--) {
						vTahunArr.push(<option>{vTahunidx}</option>)
					}
					return vTahunArr;
				})()}
			</CFormSelect>
			<CButton size="sm" color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="me-1">&middot;</span>
			(<b id="idjmldata" className="text-primary">0</b> {uBahasaObj.word_data||"Data"})
		</div>
		</>
	)//>
}

export default Headersubantrianruang
