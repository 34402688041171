import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CImage,
  CForm,
  CRow,
  CCol,
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLink,
  CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubuserakses = (props) => {
	//const {  showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaob  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uEfekapp				= useSelector(state => state.effectApp);
	
	const [uInpkeyword,setInpkeyword]= React.useState(uKeywordObj.kwd_userakses||"");

	const hdlKeydown = (_EV) => {
		if(_EV.which==13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();

			return;
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_userakses = uInpkeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_userakses = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpkeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaob.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubuserakses - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaob["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			<CInputGroup 
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			<CFormInput size="sm" 
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				placeholder={"--"+(uBahasaob.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" className="border"
				color="light" 
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="d-none d-md-block ms-1">
			<CTooltip content={"--"+(uBahasaob.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			&middot;
			(<strong id="idjmldata" className="text-primary">0</strong>&nbsp;
			{uBahasaob.word_data||"Data"})
		</div>
		</>
	)
}

export default Headersubuserakses
