import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CButton,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Terapi 	= (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 50);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlTerapi,setJmlTerapi]					= React.useState(0);
	const [uPgTerapiactive,setPgTerapiactive]		= React.useState(0);
	const [uJmlterapiHal,setJmlterapiHal]			= React.useState(1);
	const [uRowterapiselect,setRowterapiselect]		= React.useState(-1);
	const [uIDterapiselect,setIDterapiselect]		= React.useState(0);
	const [uIsTerapiload,setTerapiload]				= React.useState(false);
	const [uTerapilabel,setTerapilabel]				= React.useState("");

	const [uJmlTerapisub,setJmlTerapisub]			= React.useState(0);
	const [uPgTerapisubactive,setPgTerapisubactive]	= React.useState(1);
	const [uJmlterapisubHal,setJmlterapisubHal]		= React.useState(1);
	const [uRowterapisubselect,setRowterapisubselect]	= React.useState(-1);
	const [uIDterapisubselect,setIDterapisubselect]	= React.useState(0);
	const [uIsTerapisubload,setTerapisubload]		= React.useState(false);

	const [uDatamainArr,setDatamainArr]				= React.useState([]);
	const [uDataterapisubArr,setDataterapisubArr]	= React.useState([]);
	const uDatacabangArr							= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uCabangID 								= parseInt(localStorage.getItem("lastcabangid")||"0");
	//--END VIEWS_VARS--/

	//--SEARCH--/
	const [uInpkwdterapi,setInpkwdterapi]			= React.useState(uKeywordObj.kwd_terapi||"");
	const [uInpkwdterapisub,setInpkwdterapisub]		= React.useState("");
	const [uInpkwdterapisubget,setInpkwdterapisubget]= React.useState("");
	//--END SEARCH--/

	//---FORM_VARS--/
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uDlgformjenis,setDlgformjenis]	= React.useState("");
	const [uDlgformheader,setDlgformheader]	= React.useState("");

	const [uInpterapiID,setInpTerapiID] 	= React.useState(0);
	const [uInpnamaterapi,setInpnamaterapi] = React.useState("");
	const [uInppoinperawat,setInppoinperawat]= React.useState(0);

	const [uInpnamaterapisub,setInpnamaterapisub] = React.useState("");
	const [uInpBiayabahan,setInpBiayabahan] 	= React.useState(0);
	const [uInpBiayajasa,setInpBiayajasa] 		= React.useState(0);

	const [uInpPersenbahan,setInpPersenbahan] 	= React.useState(0);
	const [uInpPersenjasa,setInpPersenjasa] 	= React.useState(0);
	const [uInpPersentotal,setInpPersentotal] 	= React.useState(0);

	const [uInpCabangIDArr,setInpCabangIDArr] 	= React.useState([]);
	//---END FORM_VARS--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	const uWindowheight	= window.innerHeight;
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKDownTerapicari = (_EV) => {
		if(_EV.which==13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			hdlKlikTerapicari()
		}//-*/
	}
	const hdlKlikTerapicari = () => {
		uKeywordObj.kwd_terapi = uInpkwdterapi; 
	    localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		apiLoadterapi(uIDterapiselect)
	}
	const hdlKlikTerapitambah = () => {
		setDlgformjenis("TERAPI");
		setInpTerapiID(0);
		setDlgformheader((uBahasaObj.word_tambah||"Tambah")+" "+(uBahasaObj.word_terapi||"Terapi"));
		setShowForm(true)
	}
	const hdlKlikTerapiedit = (_IDTERAPI) => {
		_IDTERAPI = parseInt(_IDTERAPI)||0;
		if(_IDTERAPI <= 0) return;

		setIDterapiselect(_IDTERAPI);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapi","idtr"+_IDTERAPI)
		const vCaptionUpper = (uDatamainArr[vIdxObj].terapi_caption||"").toUpperCase();
		setRowterapiselect(vIdxRow);

		setInpTerapiID(_IDTERAPI);
		setInpnamaterapi(uDatamainArr[vIdxObj].terapi_caption||"");
		setInppoinperawat(uDatamainArr[vIdxObj].poin_perawat||"0");

		setDlgformjenis("TERAPI")
		setDlgformheader("Edit Terapi: "+vCaptionUpper);
		setShowForm(true)
	}
	const hdlKlikTerapihapus = async(_IDTERAPI) => {
		_IDTERAPI = parseInt(_IDTERAPI)||0;
		if(_IDTERAPI <= 0) return;

		setIDterapiselect(_IDTERAPI);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapi","idtr"+_IDTERAPI)
		const vCaptionUpper = (uDatamainArr[vIdxObj].terapi_caption||"").toUpperCase();
		setRowterapiselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiHapusterapi(_IDTERAPI);
	}
	const hdlKlikTerapipilih = (_IDTERAPI) => {
		_IDTERAPI	= parseInt(_IDTERAPI||0); if(_IDTERAPI<=0) return

		if(isMobile) { return; }

		setIDterapiselect(_IDTERAPI);
		const vIdxObj 	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI);
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapi","idtr"+_IDTERAPI)
		setRowterapiselect(vIdxRow);

		const vDataterapisubArr	= uDatamainArr[vIdxObj].terapisub_arr || [];
		if(vDataterapisubArr.length > 0) {

			setTerapilabel(uDatamainArr[vIdxObj].terapi_caption||"");
			setDataterapisubArr(vDataterapisubArr);
			setJmlTerapisub(vDataterapisubArr.length);
			return;
		}

		apiLoadterapisub(_IDTERAPI)
	}
	const hdlKlikTerapilihatmobile = (_IDTERAPI) => {
		_IDTERAPI	= parseInt(_IDTERAPI||0); if(_IDTERAPI<=0) return

		setIDterapiselect(_IDTERAPI);
		const vIdxObj 	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI);
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapi","idtr"+_IDTERAPI)
		setRowterapiselect(vIdxRow);
		
		uDlgviewdataObj.header = "Data Sub Terapi";
		const vDataterapisubArr	= uDatamainArr[vIdxObj].terapisub_arr || [];
		if(vDataterapisubArr.length > 0) {
			setDataterapisubArr(vDataterapisubArr);
			setJmlTerapisub(vDataterapisubArr.length);

			setDlgviewshow(true);
			return;
		}

		setDlgviewshow(true);
		apiLoadterapisub(_IDTERAPI)
	}
	const hdlKDownTerapisubcari = (_EV) => {
		if(_EV.which==13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			hdlKlikTerapisubcari()
		}//-*/
	}
	const hdlKlikTerapisubcari = () => {
		setInpkwdterapisubget(uInpkwdterapisub)
		setEfekview(!uEfekview);
	}
	const hdlKlikTerapisubtambah = () => {
		if(uIDterapiselect <= 0) return;
		setDlgformjenis("TERAPISUB")
		setDlgformheader("Tambah Jenis Terapi");
		setShowForm(true)
	}
	const hdlKlikTerapisubedit = (_IDTERAPISUB) => {
		_IDTERAPISUB = parseInt(_IDTERAPISUB)||0; if(_IDTERAPISUB <= 0) return;

		setIDterapisubselect(_IDTERAPISUB);
		const vIdxObj	= UFunc.getObjectIndeks(uDataterapisubArr,_IDTERAPISUB)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapisub","idtr"+_IDTERAPISUB)
		const vCaptionUpper = (uDataterapisubArr[vIdxObj].terapisub_caption||"").toUpperCase();
		setRowterapisubselect(vIdxRow);

		setInpnamaterapisub(uDataterapisubArr[vIdxObj].terapisub_caption||"");
		setInpBiayabahan(uDataterapisubArr[vIdxObj].biaya_bahan||0);
		setInpBiayajasa(uDataterapisubArr[vIdxObj].biaya_jasa||0);

		setDlgformjenis("TERAPISUB")
		setDlgformheader("Edit: "+vCaptionUpper);
		setShowForm(true)
	}
	const hdlKlikTerapisubhapus = async(_IDTERAPISUB) => {
		_IDTERAPISUB = parseInt(_IDTERAPISUB||0);
		if(_IDTERAPISUB <= 0) return;

		setIDterapisubselect(_IDTERAPISUB);
		const vIdxObj	= UFunc.getObjectIndeks(uDataterapisubArr,_IDTERAPISUB)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapisub","idtr"+_IDTERAPISUB)
		const vCaptionUpper = (uDataterapisubArr[vIdxObj].terapisub_caption||"").toUpperCase();
		setRowterapisubselect(vIdxRow);

		if(await(Konfirm("Hapus Jenis Terapi: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiHapusterapisub(_IDTERAPISUB);
	}
	const hdlKlikCabangaksesall = () => {
		let vIscheckedall = true;
		uDatacabangArr.forEach(vItems => {
			if(!uInpCabangIDArr.includes(vItems.id)) {
				vIscheckedall = false;
			} 
		});

		uInpCabangIDArr.splice(0,uInpCabangIDArr.length); 
		if(vIscheckedall) {
			setEfekview(!uEfekview); return;
		}

		let vTmpAll	= []
		uDatacabangArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems.id]);
			
		setInpCabangIDArr(vTmpAll);
		setEfekview(!uEfekview);
	}
	const hdlChangeCabangakses = (_EV) => {
		const vValueID	= _EV.target.value;
		const vIschecked= _EV.target.checked;

		//showToast("vValueID = "+vValueID+" | vIschecked = "+vIschecked)
		if(vIschecked) {
			setInpCabangIDArr([...uInpCabangIDArr,vValueID])
		} else {
			const vIdx = uInpCabangIDArr.findIndex(vItems => vItems===vValueID);
			uInpCabangIDArr.splice(vIdx,1);
		}
		setEfekview(!uEfekview);
	}

	const hdlKlikTerapisubharga = () => {
		setDlgformjenis("PERSENHARGA")
		setDlgformheader("Edit Harga by Persentase");
		setShowForm(true)
	}

	const hdlDlgformtoggle = () =>{ setShowForm(false) }
	const hdlKlikDlgsimpan = () => {
		if(uDlgformjenis==="TERAPI") {initSimpanterapi(); return}
		if(uDlgformjenis==="TERAPISUB") {initSimpanterapisub(); return}
		if(uDlgformjenis==="PERSENHARGA") {initSimpanharga(); return}
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(Terapi - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		//setIDterapiselect(0)

		setInpTerapiID(0)
		setInpnamaterapi("")
		setInppoinperawat(0)

		setInpnamaterapisub("")
		setInpBiayabahan(0)
		setInpBiayajasa(0)
		setInpCabangIDArr([uCabangID.toString()])

		setInpPersenbahan(0);
		setInpPersenjasa(0);
		setInpPersentotal(0);
	}
	const initSimpanterapi = () => {
		let vElFocus = document.getElementById("inpnamaterapi");
		if(uInpnamaterapi.trim()==="") {
			vElFocus.focus();
			showToast("Nama Terapi Harus Diisi !");return;
		}

		apiSimpanterapi(uInpterapiID);
	}
	const initSimpanterapisub = () => {
		let vElFocus = document.getElementById("inpnamaterapisub");
		if(uInpnamaterapisub.trim()==="") {
			vElFocus.focus();
			showToast("Nama Jenis Terapi Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpbiayabahan");
		if(parseInt(uInpBiayabahan) < 0) {
			vElFocus.focus();
			showToast("Biaya Bahan Tidak Valid !");return;
		}
		vElFocus = document.getElementById("inpbiayajasa");
		if(parseInt(uInpBiayajasa) < 0) {
			vElFocus.focus();
			showToast("Biaya Jasa Tidak Valid !");return;
		}

		if(parseInt(uIDterapisubselect) > 0)
			if(uInpCabangIDArr.length <= 0) {
				showToast("Cabang Harus Ada yg Dipilih !");return;
			}

		apiSimpanterapisub(uIDterapisubselect);
	}
	const initSimpanharga = () => {
		if(parseInt(uIDterapiselect) <= 0) return;

		let vElFocus;
		if(parseFloat(uInpPersenbahan)===0
			&& parseFloat(uInpPersenjasa)===0
			&& parseFloat(uInpPersentotal)===0
			) {

			if(parseFloat(uInpPersenbahan)===0)
				vElFocus = document.getElementById("inppersenbahan");
			else if(parseFloat(uInpPersenjasa)===0)
				vElFocus = document.getElementById("inppersenjasa");
			else if(parseFloat(uInpPersentotal)===0)
				vElFocus = document.getElementById("inppersentotal");

			vElFocus && vElFocus.focus();
			showToast("Persentase Perubahan Harga Harus Ditentukan !");return;
		}

		if(uInpCabangIDArr.length <= 0) {
			showToast("Cabang Harus Ada yg Dipilih !");return;
		}

		apiSimpanharga();
	}
	//--END INIT--/

	//--CONTENT--/
	const contentTerapiheader = () => {
		if(uHtml500msg) return (<></>)//>

		return (
		<div className="d-flex justify-content-between">
		<CForm 
			style={{minWidth:200,width:"73%"}}>
			<CInputGroup size="sm">
			<CFormInput size="sm" 
				value={uInpkwdterapi} 
				onChange={(e) => setInpkwdterapi(e.target.value)} 
				onKeyDown={(e) => hdlKDownTerapicari(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keywordterapi||"Keyword Terapi")+".."} 
				id="inpkeyword"/>
			<CInputGroupText className="p-0">
			<CButton size="sm" 
				color="transparent" 
				className="py-0"
				onClick={()=>hdlKlikTerapicari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass" className="text-dark" />
			</CButton>
			</CInputGroupText>
			</CInputGroup>
		</CForm>
		<div className="pt-2 pt-md-0">
			{((uTokenObj.userhak||"")==="FREEROLE" && !uISDemoapp) && (
			<>
			<CTooltip content={"--"+(uBahasaObj.caption_tambahterapi||"Tambah Terapi")}>
			<CLink onClick={()=>hdlKlikTerapitambah()} className="classikon classikontambah classcursorpointer" />
			</CTooltip>
			<span className="mx-1 d-none">&middot</span>
			</>
			)}

			<small>
			(<strong id="idjmldata" className="text-primary classfontsmaller">0</strong>)
			</small>
		</div>
		</div>
		)//>
	}
	const contentTerapimain = () => {
		if(uHtml500msg) return (<></>)//>

		if(uIsTerapiload) return (
			<div align="center" className="my-5">
			<CSpinner color="warning" size="sm"/>
			</div>
		);//>
		//if(uIsTerapiload) return (<div align="center" className="mt-5 text-warning classfontsmaller">{uBahasaObj.caption_pleasewait||"Mohon Ditunggu"}..</div>);

		const vMin			= (uPgTerapiactive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_terapi || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabelterapi">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="8" className="text-start">{uBahasaObj.caption_namaterapi||"Nama Terapi"}</CCol>
			<CCol md="4" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,terapi_caption,poin_perawat,
				is_loading,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (terapi_caption||"").toUpperCase();

			const vTerapicaption	= vKeyword==="" ? (terapi_caption||"") 
				: (terapi_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowterapiselect===vKeys?" classrowselect":"")}>
				<td className="px-1"><small>{vNumber}.</small></td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="8" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div className="text-capitalize text-info">{UFunc.renderHTML(vTerapicaption)}</div>
						
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikTerapilihatmobile(id)}>
								<CIcon icon="cilFindInPage" className="classikonbox ms-0 align-middle me-2" height={25}/>
								{uBahasaObj.caption_viewdata||"Lihat Data Jenis Terapi"}
							</CDropdownItem>
							<CDropdownItem onClick={()=>hdlKlikTerapiedit(id)}>
								<CIcon className="classikonedit ms-0 align-middle me-2" height={25}/>
								{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							
							{(uIsHapusallow && !uISDemoapp) && (
							<CDropdownItem onClick={()=>hdlKlikTerapihapus(id)}>
								<CIcon className="classikonhapus ms-0 align-middle me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
					</div>

					<div className="d-none d-md-block classfontsmaller">
						{uBahasaObj.caption_poinperawat||"Poin Perawat"}:{" "}
						<b>{UFunc.formatAngka(poin_perawat)}</b>
					</div>

					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>[{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
						</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_poinperawat||"Poin Perawat"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<b>{UFunc.formatAngka(poin_perawat)}</b>
					<small className="ms-1 d-md-none">POIN</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="7" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				<CCol md="4" className="px-0 text-md-center d-none d-md-block">
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_viewdata||"Lihat Data")+" "+
						(uBahasaObj.caption_terapisub||"Jenis Terapi")+": "+vCaptionUpper}>
					<CLink onClick={()=>{
							if(isMobile) { hdlKlikTerapilihatmobile(id); return } 
							hdlKlikTerapipilih(id)
						}} className="classcursorpointer">
						<CIcon icon="cilFindInPage" className="classikonbox" height={25}/>
					</CLink>
					</CTooltip>

					{(uIsHapusallow) && (
						<>
						<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikTerapiedit(id)} className="classikontabel classikonedit classcursorpointer"/>
						</CTooltip>

						{(!uISDemoapp) && (
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikTerapihapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
						)}
						</>
					)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentTerapisubheader = () => {
		if(uHtml500msg) return (<></>)//>

		return (
		<div className="d-flex justify-content-between">
		<CForm 
			style={{minWidth:300,width:"auto"}}>
			<CInputGroup size="sm">
			<CFormInput size="sm" 
				value={uInpkwdterapisub} 
				onChange={(e) => setInpkwdterapisub(e.target.value)} 
				onKeyDown={(e) => hdlKDownTerapisubcari(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keywordterapisub||"Keyword Jenis Terapi")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light"
				className="border"
				onClick={()=>hdlKlikTerapisubcari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass" className="text-dark" />
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="pt-2 pt-md-0">
			{(uTerapilabel||"")!=="" && (
			<span className="classfontoswald">{UFunc.Usercaptionfilter(uTerapilabel,3)} &middot; </span>
			)}

			{((uTokenObj.userhak||"")==="FREEROLE") && (
			<>
				{(!uISDemoapp) && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_tambahjenisterapi||"Tambah Jenis Terapi")}>
				<CLink onClick={()=>hdlKlikTerapisubtambah()} className="classikon classikontambah classcursorpointer" />
				</CTooltip>

				<span className="mx-1 d-none">&middot;</span>
				</>
				)}

				<CTooltip content={"--Set Harga by Persentase.."}>
				<CLink onClick={()=>hdlKlikTerapisubharga()} 
					className="classcursorpointer">
					<CIcon icon="cilCalculator" className="classikonbox" height={30}/>
				</CLink>
				</CTooltip>
				<span className="me-1">&middot;</span>
			</>
			)}

			<span className="classfontsmaller">
			<small>
			(<b className="text-primary" id="idjmlterapisub">{UFunc.formatAngka(uJmlTerapisub)}</b>)
			</small>
			</span>
		</div>
		</div>
		)//>
	}
	const contentTerapisubmain = () => {
		if(uHtml500msg) return (<></>)//>

		//console.log("(Terapi - contentTerapisubmain) uIsTerapisubload = "+uIsTerapisubload)
		if(uIsTerapisubload) return (
			<div align="center" className="my-5">
			<CSpinner color="warning" size="sm"/>
			</div>
		)//>

		const vMin			= (uPgTerapisubactive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= uInpkwdterapisubget.trim();// uKeywordObj.kwd_terapi || "";
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataterapisubArr = vKeyword==="" ? uDataterapisubArr
			: uDataterapisubArr.filter(vItems => {
					return ((vItems.terapisub_caption||"").toUpperCase().indexOf(vKeyword.toUpperCase())>=0
						|| (vItems.userinput||"").toUpperCase().indexOf(vKeyword.toUpperCase())>=0
					)
				});

		//console.log("(Terapi - contentTerapisubmain) vDataterapisubArr = "+JSON.stringify(vDataterapisubArr))
		const vDataviewArr	= vDataterapisubArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabelterapisub">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={40} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md={isMobile?"6":"4"} className="text-start">{uBahasaObj.caption_namaterapijenis||"Nama Jenis Terapi"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_bbahan||"B.Bahan"} (Rp)</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_bjasa||"B.Jasa"} (Rp)</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_btotal||"B.Total"} (Rp)</CCol>
			<CCol md="2" className={isMobile?"d-none":"d-md-block"}>&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,terapisub_caption,biaya_bahan,biaya_jasa,
				init_process,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (terapisub_caption||"").toUpperCase();

			const vTerapisubcaption	= vKeyword==="" ? (terapisub_caption||"") 
				: (terapisub_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowterapisubselect===vKeys?" classrowselect":"")}>
				<td className="px-1">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md={isMobile?"6":"4"} className="px-1 text-start">
					<div className="text-capitalize text-info">{UFunc.renderHTML(vTerapisubcaption)}</div>
					
					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>[{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
						</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_bbahan||"Biaya Bahan"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1">{UFunc.formatAngka(biaya_bahan)}</CCol>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_bjasa||"Biaya Jasa"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1">{UFunc.formatAngka(biaya_jasa)}</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_btotal||"Tarif Total"} (Rp)</CCol>
				<CCol xs="6" md="2" className="px-1 fw-bolder text-success">
					{UFunc.formatAngka(parseFloat(biaya_jasa) + parseFloat(biaya_bahan))}
				</CCol>

				<CCol md="2" className={"px-0 "+(isMobile?"d-none":"d-md-block")}>
					{(uIsHapusallow) ? (
						<>
						<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikTerapisubedit(id)} className="classikontabel classikonedit classcursorpointer"/>
						</CTooltip>

						{!uISDemoapp && (
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikTerapisubhapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
						)}
						</>
					) : (<>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uIDterapiselect);
		if(vArridx < 0) return (<></>);

		return (
		<CCard>
		<CCardHeader className="py-1">{uDatamainArr[vArridx].terapi_caption}</CCardHeader>
		
		<CCardBody className="px-0">{contentTerapisubmain()}</CCardBody>

		{(uJmlTerapisub > uMaxData) && (
		<CCardFooter className="py-2">
			<MyPagination className="my-0"
				activePage={uPgTerapisubactive}
				pages={uJmlterapisubHal}
				onActivePageChange={(i)=>setPgTerapisubactive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		)//>
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>)//>

		if(uDlgformjenis==="TERAPI") return (<>{contentTerapiform()}</>)//>
		if(uDlgformjenis==="TERAPISUB") return (<>{contentTerapisubform()}</>)//>
		if(uDlgformjenis==="PERSENHARGA") return (<>{contentTerapiharga()}</>)//>

		return (<></>)//>
	}
	const contentTerapiform = () => {
		if(uDlgformjenis!=="TERAPI") return (<></>)//>

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_namaterapi||"Nama Terapi"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpnamaterapi}
				onChange={(e)=>setInpnamaterapi(e.target.value)}
				id="inpnamaterapi"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_poinperawat||"Poin Perawat"}</CCol>
		<CCol xs="4" md="3" lg="2" className="">
			<CFormInput size="sm" type="number"
				className="text-end"
				value={uInppoinperawat}
				onChange={(e)=>setInppoinperawat(e.target.value)}
				onFocus={(e)=>e.target.select()}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inppoinperawat"/>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentTerapisubform = () => {
		if(uDlgformjenis!=="TERAPISUB") return (<></>)//>

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol className="fw-bolder text-primary text-center">&middot;{uTerapilabel}&middot;</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_namaterapijenis||"Nama Jenis Terapi"}</CCol>
		<CCol md="8" className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpnamaterapisub}
				onChange={(e)=>setInpnamaterapisub(e.target.value)}
				id="inpnamaterapisub"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_biayabahan||"Biaya Bahan"}</CCol>
		<CCol md="5" lg="4" className="">
			<NumberFormat 
				value={uInpBiayabahan}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => setInpBiayabahan(values.value)}
				renderText={values => (
				<CFormInput size="sm"
					value={values}
					className="text-end"
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>setInpBiayabahan(e.target.value)}
					id="inpbiayabahan"/>
				)} 
				prefix={'Rp'}/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_biayajasa||"Biaya Jasa"}</CCol>
		<CCol md="5" lg="4" className="">
			<NumberFormat 
				value={uInpBiayajasa}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => setInpBiayajasa(values.value)}
				renderText={values => (
				<CFormInput size="sm"
					value={values}
					className="text-end"
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>setInpBiayajasa(e.target.value)}
					onKeyDown={(e)=>{
						if(uIDterapisubselect <= 0)
							UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
					}}
					id="inpbiayajasa"/>
				)} 
				prefix={'Rp'}/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_biayatoal||"Biaya Total"}</CCol>
		<CCol md="5" lg="4" className="fw-bolder text-success text-end">
			Rp{UFunc.formatAngka(parseInt(uInpBiayabahan)+parseInt(uInpBiayajasa))}</CCol>
		</CRow>

		{((uIDterapisubselect||0) > 0 && uDatacabangArr.length > 1) && (
		<>
		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">
			<CLink
				onClick={()=>hdlKlikCabangaksesall()}
				className="classcursorpointer">
			{uBahasaObj.caption_pilihcabang||"Pilih Cabang"}
			</CLink>
			<div className="classpetunjuk">*) Pilih Cabang yg Akan Diubah Tarifnya</div>
		</CCol>
		<CCol md="8" className="">
			<CInputGroup size="sm">
			{uDatacabangArr.map((vItems,vKeys)=>{
			return (
				<CInputGroupText
					key={vKeys}
					className="p-0 ps-2 pt-1 me-3 mb-2 bg-light text-dark"
					style={{minWidth:175}}>
				<CFormCheck inline size="sm"
					value={vItems.id}
					id={"inpcabangid"+vItems.id} 
					label={vItems.cabang_nama}
					onChange={(e)=>hdlChangeCabangakses(e)}
					checked={uInpCabangIDArr.includes(vItems.id)}
					className="classcursorpointer"/>
				</CInputGroupText>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		</>
		)}
		</CForm>
		)//>
	}
	const contentTerapiharga = () => {
		if(uDlgformjenis!=="PERSENHARGA") return (<></>)

		return (
		<CForm className="">
		<CRow className="my-2">
		<CCol className="fw-bolder text-primary text-center">&middot;{uTerapilabel}&middot;</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol md="4">
			<CCard>
			<CCardHeader><small>Persen B.Bahan</small></CCardHeader>
			<CCardBody className="px-lg-5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					pattern="^\d*(\,\d{0,2})?$"
					max={100}
					min={-100}
					value={uInpPersenbahan}
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>{setInpPersenbahan(e.target.value);setInpPersentotal(0)}}
					className="text-end"
					id="inppersenbahan"/>
				<CInputGroupText>%</CInputGroupText>
				</CInputGroup>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol md="4">
			<CCard>
			<CCardHeader><small>Persen B.JASA</small></CCardHeader>
			<CCardBody className="px-lg-5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					pattern="^\d*(\,\d{0,2})?$"
					max={100}
					min={-100}
					value={uInpPersenjasa}
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>{setInpPersenjasa(e.target.value);setInpPersentotal(0)}}
					className="text-end"
					id="inppersenjasa"/>
				<CInputGroupText>%</CInputGroupText>
				</CInputGroup>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol md="4">
			<CCard>
			<CCardHeader><small>Persen Total</small></CCardHeader>
			<CCardBody className="px-lg-5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					pattern="^\d*(\,\d{0,2})?$"
					max={100}
					min={-100}
					value={uInpPersentotal}
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>setInpPersentotal(e.target.value)}
					className="text-end"
					id="inppersentotal"/>
				<CInputGroupText>%</CInputGroupText>
				</CInputGroup>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow> 

		{(uDatacabangArr.length > 1) && (
		<>
		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">
			<CLink
				onClick={()=>hdlKlikCabangaksesall()}
				className="classcursorpointer">
			{uBahasaObj.caption_pilihcabang||"Pilih Cabang"}
			</CLink>
			<div className="classpetunjuk">*) Pilih Cabang yg Akan Diubah Tarifnya</div>
		</CCol>
		<CCol md="8" className="">
			<CInputGroup size="sm">
			{uDatacabangArr.map((vItems,vKeys)=>{
			return (
				<CInputGroupText
					key={vKeys}
					className="p-0 ps-2 pt-1 me-3 mb-2 bg-light text-dark rounded"
					style={{minWidth:175}}>
				<CFormCheck inline size="sm"
					value={vItems.id}
					id={"inpcabangid"+vItems.id} 
					label={vItems.cabang_nama}
					onChange={(e)=>hdlChangeCabangakses(e)}
					checked={uInpCabangIDArr.includes(vItems.id)}
					className="classcursorpointer"/>
				</CInputGroupText>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		</>
		)}
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoadterapi = () => {
		setHtml500msg();

		setJmlTerapi(0);
		setDatamainArr([]);
		setDataterapisubArr([]);
		setInpkwdterapisub("");setInpkwdterapisubget("");
		setIDterapiselect(0);
		setRowterapiselect(-1);
		setPgTerapiactive(1);
		setTerapiload(true); 
		setTerapisubload(true);

		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_terapi || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true); 
			setTerapiload(false); 
			setLoading(false);
			if(output_string.tampil) {
				setTerapisubload(false);
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlTerapi((parseInt(vTmpObj.length)||0));
				setEfekview(!uEfekview);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else {
				setTerapisubload(false);
				if(output_string.info) {
					setHtml500msg(output_string.info);
				} else if(output_string.errors) {
					console.log("(Terapi - apiLoadterapi) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					setHtml500msg("<font color='red'>"+vMsg+"</font>");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			setTerapiload(false); setTerapisubload(false);
			setLoading(false);
			console.log("(Terapi - apiLoadterapi) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadterapisub = (_IDTERAPI) => {
		_IDTERAPI	= parseInt(_IDTERAPI||0); if(_IDTERAPI<=0) return;

		const vIdxObj 	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI); if(vIdxObj<0) return;

		uDatamainArr[vIdxObj].is_loading = true;
		setTerapilabel(uDatamainArr[vIdxObj].terapi_caption||"");
		setIDterapiselect(_IDTERAPI);
		setJmlTerapisub(0);
		setDataterapisubArr([]);
		setIDterapisubselect(0);
		setRowterapisubselect(-1);
		setPgTerapisubactive(1);
		setTerapisubload(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Terapi - apiLoadterapisub) uDatamainArr = "+JSON.stringify(uDatamainArr))
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapiid : _IDTERAPI,
			send_cabangid : uCabangID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/ld_datasub";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].is_loading = false;
			setTerapisubload(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.terapisubobject||"[]");

				uDatamainArr[vIdxObj].terapisub_arr = vTmpObj;
				setDataterapisubArr(vTmpObj);
				setJmlTerapisub((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiLoadterapisub) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].is_loading = false;
			setTerapisubload(false);
			console.log("(Terapi - apiLoadterapisub) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanterapi = (_IDTERAPI) => {
		_IDTERAPI = parseInt(_IDTERAPI)||0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Terapi - apiSimpanterapi) LEWaT "); 
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapiid : _IDTERAPI,
			send_namaterapi : uInpnamaterapi,
			send_poinperawat : uInppoinperawat,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTERAPI <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						terapi_caption:uInpnamaterapi,
						poin_perawat: parseInt(uInppoinperawat)||0,
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					setJmlTerapi(uJmlTerapi+1);

					initFormAwal();
					setPgTerapiactive(1);
					apiLoadterapisub(output_string.tabelid||"255")
					if(!isMobile) {
						document.getElementById("inpnamaterapi") && 
							document.getElementById("inpnamaterapi").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vIdxObj].terapi_caption= uInpnamaterapi;
					uDatamainArr[vIdxObj].poin_perawat	= parseInt(uInppoinperawat)||0;

					hdlDlgformtoggle();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiSimpanterapi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Terapi - apiSimpanterapi) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanterapisub = (_IDTERAPISUB) => {
		_IDTERAPISUB = parseInt(_IDTERAPISUB||0);
		if(parseInt(uIDterapiselect) <= 0) {showToast("UNDEFINED","ERROR");return}

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDataterapisubArr,_IDTERAPISUB)
		const vIdxterapi= UFunc.getObjectIndeks(uDatamainArr,uIDterapiselect)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Terapi - apiSimpanterapisub) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapiid : uIDterapiselect,
			send_terapisubid : _IDTERAPISUB,
			send_namaterapisub : uInpnamaterapisub,
			send_cabangidlist: uInpCabangIDArr.join(","),
			send_biayabahan : parseFloat(uInpBiayabahan)||0,
			send_biayajasa : parseFloat(uInpBiayajasa)||0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/pr_simpansub";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTERAPISUB <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						terapisub_caption:uInpnamaterapisub,
						biaya_bahan:parseFloat(uInpBiayabahan)||0,
						biaya_jasa:parseFloat(uInpBiayajasa)||0,
					}
					setDataterapisubArr([vTmpObj,...uDataterapisubArr]);
					uDatamainArr[vIdxterapi].terapisub_arr = [vTmpObj,...uDataterapisubArr];
					setJmlTerapisub(uJmlTerapisub+1);

					initFormAwal();
					setPgTerapisubactive(1);
					if(!isMobile) {
						document.getElementById("inpnamaterapisub") && 
							document.getElementById("inpnamaterapisub").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDataterapisubArr[vIdxObj].terapisub_caption= uInpnamaterapisub;
					uDataterapisubArr[vIdxObj].biaya_bahan	= parseFloat(uInpBiayabahan)||0;
					uDataterapisubArr[vIdxObj].biaya_jasa	= parseFloat(uInpBiayajasa)||0;

					uDatamainArr[vIdxterapi].terapisub_arr 	= uDataterapisubArr;

					hdlDlgformtoggle();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiSimpanterapisub) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Terapi - apiSimpanterapisub) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanharga = () => {
		if(parseInt(uIDterapiselect) <= 0) {showToast("UNDEFINED","ERROR");return}

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxterapi= UFunc.getObjectIndeks(uDatamainArr,uIDterapiselect)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Terapi - apiSimpanharga) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapiid : uIDterapiselect,
			send_cabangidlist: uInpCabangIDArr.join(","),
			send_persenbahan : parseFloat(uInpPersenbahan),
			send_persenjasa : parseFloat(uInpPersenjasa),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/pr_simpanharga";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				uDataterapisubArr.map(vItems=> {
					vItems.biaya_bahan = parseFloat(vItems.biaya_bahan)
						+ Math.round( (parseFloat(uInpPersenbahan)/100)*vItems.biaya_bahan );

					vItems.biaya_jasa = parseFloat(vItems.biaya_jasa)
						+ Math.round( (parseFloat(uInpPersenjasa)/100)*vItems.biaya_jasa );  
				})

				uDatamainArr[vIdxterapi].terapisub_arr = uDataterapisubArr;
				hdlDlgformtoggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiSimpanharga) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Terapi - apiSimpanharga) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapusterapi = (_IDTERAPI) => {
		_IDTERAPI = parseInt(_IDTERAPI)||0;
		if(_IDTERAPI <= 0) return;

		const vPgterapiactive = uPgTerapiactive;
		setPgTerapiactive(0);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTERAPI)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapiid : _IDTERAPI,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setPgTerapiactive(vPgterapiactive);
			if(output_string.sukses) {
				setRowterapiselect(-1);
				uDatamainArr.splice(vIdxObj,1);
				setJmlTerapi((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiHapusterapi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Terapi - apiHapusterapi) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapusterapisub = (_IDTERAPISUB) => {
		_IDTERAPISUB = parseInt(_IDTERAPISUB||0);
		if(_IDTERAPISUB <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDataterapisubArr,_IDTERAPISUB)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_terapisubid : _IDTERAPISUB,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_terapi/pr_hapussub";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDataterapisubArr.splice(vIdxObj,1);
				setRowterapisubselect(-1);
				setJmlTerapisub(parseInt(uDataterapisubArr.length||0));
				
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Terapi - apiHapusterapisub) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Terapi - apiHapusterapisub) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoadterapi();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false); setDlgviewshow(false);
    		setDatamainArr([]);
    		setDataterapisubArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlterapiHal	= Math.ceil(uJmlTerapi / uMaxData);
		setJmlterapiHal(vJmlterapiHal);//-*/
		setPgTerapiactive(uPgTerapiactive<=0?1:uPgTerapiactive);

		/*const vKeyword = uKeywordObj.kwd_terapi || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlTerapi));
	},[uJmlTerapi])
	React.useEffect(()=>{
		//console.log("(Terapi - [uDatamainArr]) LEWAT");
		if(uDatamainArr.length <= 0) {
			setIDterapiselect(0);
			setDataterapisubArr([]); 
			return
		}
		apiLoadterapisub(uDatamainArr[0].id ||"0")
	},[uDatamainArr])
	React.useEffect(()=>{
		let vJmlterapisubHal	= Math.ceil(uJmlTerapisub / uMaxData);
		setJmlterapisubHal(vJmlterapisubHal);//-*/
		setPgTerapisubactive(uPgTerapisubactive<=0?1:uPgTerapisubactive);
	},[uJmlTerapisub])
	React.useEffect(()=>{ 
		if(uPgTerapiactive <= 0) return;

		if(uDatamainArr.length <= 0 || isMobile) {
			setRowterapiselect(-1); return
		}

		const vMin			= (uPgTerapiactive-1)*uMaxData;
		const vIdxObj		= vMin;//(vMin-1);

		const vTerapiID		= uDatamainArr[vIdxObj].id||"0";
		const vDataterapisubArr	= uDatamainArr[vIdxObj].terapisub_arr || [];
		if(vDataterapisubArr.length > 0) {
			const vIdxRow	= UFunc.getTabelRowIndeks("idtabelterapi","idtr"+vTerapiID)
			setRowterapiselect(vIdxRow);

			setTerapilabel(uDatamainArr[vIdxObj].terapi_caption||"");
			setDataterapisubArr(vDataterapisubArr);
			setJmlTerapisub(vDataterapisubArr.length);
			return;
		}

		apiLoadterapisub(vTerapiID)
	},[uPgTerapiactive])//-->Unselect_ROW
	React.useEffect(()=>{ setRowterapisubselect(-1); },[uPgTerapisubactive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setInpkwdterapi("");
		apiLoadterapi();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		hdlKlikTerapitambah();
	},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPgTerapiactive;
		if(vPageNow+1 > uJmlterapiHal) return;
		setPgTerapiactive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			let vElFocus = document.getElementById("inpnamaterapi");
			if(uDlgformjenis==="TERAPISUB")
				vElFocus = document.getElementById("inpnamaterapisub");
			else if(uDlgformjenis==="PERSENHARGA")
				vElFocus = document.getElementById("inppersenbahan");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm])
	React.useEffect(()=>{
		setInpPersenbahan(uInpPersentotal);
		setInpPersenjasa(uInpPersentotal);
	},[uInpPersentotal])
	//console.log("Terapi - uPgTerapiactive => "+JSON.stringify(uPgTerapiactive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	if(isMobile) return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-lg-none text-primary">[{UFunc.formatAngka(uJmlTerapi)}]</small>
				<span className="d-lg-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0 px-md-3">{contentTerapimain()}</CCardBody>

			{(uJmlTerapi > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPgTerapiactive}
				pages={uJmlterapiHal}
				onActivePageChange={(i)=>setPgTerapiactive(i)}
				align="start"/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={isShowForm} 
			dialogHeader={uDlgformheader}
			toggle={hdlDlgformtoggle}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
		</>
	)

	return (
		<>
		<div style={{margin:"auto -35px"}}>
		<CRow className="m-0 ">
		<CCol md="5" className="p-0 pe-md-2 position-relative "
			style={{height: uWindowheight - 205}}>
			<CCard className=" h-100 w-auto classbgcard" style={{width:"auto !important"}}>
			<CCardHeader>{contentTerapiheader()}</CCardHeader>

			<CCardBody className="px-0" style={{overflowY:"auto"}}>
				{contentTerapimain()}
			</CCardBody>

			{(uJmlTerapi > uMaxData) && (
			<CCardFooter className="px-1 py-2 classfontsmaller">
				<MyPagination className="my-0"
					activePage={uPgTerapiactive}
					pages={uJmlterapiHal}
					onActivePageChange={(i)=>setPgTerapiactive(i)}
					align="start"/>
			</CCardFooter>
			)}
			</CCard>
		</CCol>

		<CCol md="7" className="p-0 ps-md-2 position-relative "
			style={{height: uWindowheight - 205}}>
			<CCard className="h-100 w-auto classbgcard" style={{}}>
			<CCardHeader className="px-1 px-md-2">{contentTerapisubheader()}</CCardHeader>
			
			<CCardBody className="px-0" style={{overflowY:"auto"}}>
				{contentTerapisubmain()}
			</CCardBody>

			{(uJmlTerapisub > uMaxData) && (
			<CCardFooter className="py-2">
				<MyPagination className="my-0"
					activePage={uPgTerapisubactive}
					pages={uJmlterapisubHal}
					onActivePageChange={(i)=>setPgTerapisubactive(i)}
					align="start"/>
			</CCardFooter>
			)}
			</CCard>
		</CCol>
		</CRow>
		</div>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={isShowForm} 
			dialogHeader={uDlgformheader}
			toggle={hdlDlgformtoggle}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Terapi