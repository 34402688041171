import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import { isMobile } from "react-device-detect";
import {
	MyHeaderProfile,
	MyPagination,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const isMobile 	= false;
const Previewasuransi = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallowed			= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uInpkeyword,setInpkeyword]	= React.useState("")
	const [uDatamainArr,setDatamainArr]	= React.useState([])
	const [uJmlData,setJmlData]			= React.useState(0)
	const [uJmlHal,setJmlHal]			= React.useState(1)
	const [uPageActive,setPageActive]	= React.useState(1)
	const [uLastinitObj,setLastinitObj]	= React.useState({});
	const [uInitselectedObj,setInitselectedObj]	= React.useState({});
	const [uRowselected,setRowselected]	= React.useState(-1)
	const [uFirstload,setFirstload]		= React.useState("YA")
	const [uTriggerCari,setTriggerCari]	= React.useState(false)

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKlikBack = () => {
		uNavigate(-1);
	}
	const hdlKDownkeyword = (_EV) => {
		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();

			return;
		}
	}
	const hdlKlikReset = async() => {
		const vNextfunction = () => {
			setInpkeyword("");
			uKeywordObj.kwd_previewasuransi = undefined;
			localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

			setTriggerCari(true);
		}

		if(uInpkeyword.trim() !== "") {
			if(await Konfirm("Apakah Anda Yakin Akan Mereset ?")) vNextfunction()
		} else vNextfunction()
	}
	const hdlKlikCari = () => {
		uKeywordObj.kwd_previewasuransi = uInpkeyword.trim();
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		setTriggerCari(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		initSelectrow(_IDTABEL);

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= UFunc.TglAngka(uDatamainArr[vArridx].tgl_bayar)+" - "+
			(uDatamainArr[vArridx].nama_asuransi||"Undf").toUpperCase();

		if(await Konfirm("Hapus Data: <b>"+vCaptionupper+"</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initSelectrow = (_IDTABEL) => {
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselected(vRowidx);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentJmldata = () => {
		const vKeyword 		= (uKeywordObj.kwd_previewasuransi||"").trim();
		if(vKeyword.length < 3) return (<>&#8734;</>);

		return (<>{UFunc.formatAngka(uJmlData)}</>)
	}
	const contentHeadersub = () => {
		return (
		<>
		<div className="d-none d-lg-block" style={{minWidth:90,width:"10%"}}/>

		<CForm>
			<CInputGroup
				style={{minWidth:300,width:"auto"}} 
				size="sm">
			<CFormInput size="sm" 
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKDownkeyword(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeywordObj")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="mx-1">&middot;</span>
			(<b id="idjmldata" className="text-primary">{contentJmldata()}</b> {uBahasaObj.word_data||"Data"})
		</div>
		</>
		)
	}
	const contentCardheader = () => {
		if(uHtml500msg) return (<b>Page 500</b>);

		return (
		<>
			<div>
			<small className="align-middle text-primary">Data Input Pembayaran</small>
			<span className="mx-1 d-md-none">&middot;</span>
			<small className="d-md-none text-primary align-middle">[{contentJmldata()}]</small>
			</div>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
			<small className="d-none">({uJmlData} - {uMaxData})</small>
		</>
		)
	}
	const contentCardmain = () => {
		if(uHtml500msg) return (
			<div className="p-5 px-2 px-lg-5">
			{UFunc.renderHTML(uHtml500msg)}
			</div>
		);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_previewasuransi || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg) === parseInt(uPageActive));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.caption_namaasuransi||"Nama Asuransi"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_nilaibayar||"Nilai Bayar"}<small className="d-block">(Rp)</small></CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-md-none">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_bayar,nama_asuransi,id_asuransi,nama_pasien,keterangan,
				nilai_bayar,is_processing,tgl_input,userinput
			} = vItems;

			vNumber++;
			const vCaptionupper	= UFunc.TglAngka(tgl_bayar)+" - "+(nama_asuransi||"Undf").toUpperCase();

			const vPasiencaption	= vKeyword==="" ? (nama_pasien||"Undf") 
				: (nama_pasien||"Undf").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vAsuransicaption	= vKeyword==="" ? (nama_asuransi||"Undf") 
				: (nama_asuransi||"Undf").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglbayar	= vKeyword==="" ? UFunc.TglAngka(tgl_bayar) 
				: UFunc.TglAngka(tgl_bayar).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: UFunc.WaktuAngka(tgl_input).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter(userinput||"Undf") 
				: UFunc.Usercaptionfilter(userinput||"Undf").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");

			return (
			<tr id={"idtr"+id} className={"align-top text-end"+(uRowselected===vKeys?" classrowselect":"")}
				 key={vKeys}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-md-start d-none d-md-block">
					<div>{UFunc.renderHTML(vTglbayar)}</div>
					<div className="d-block classfontsmaller text-danger">
					<small>[
						{UFunc.TglAngka(tgl_input)!== UFunc.TglAngka(tgl_bayar) && (
						<>
						{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						</>
						)}
						{UFunc.renderHTML(vUserinput)}]</small>
					</div>
				</CCol>
				
				<CCol md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-info">{UFunc.renderHTML(vAsuransicaption)}</div>
					<div className="d-none d-md-block">{UFunc.renderHTML(vPasiencaption)}</div>
					</div>

					{uIsHapusallowed && (
					<div className="d-md-none">
					<CLink onClick={()=>hdlKlikHapus(id)} 
						className="classcursorpointer classikontabel classikonhapus"/>
					</div>
					)}
				</CCol>
				
				<CCol xs="12" className="px-1 my-2 classborderbottom d-md-none"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
				<CCol xs="7" md="4" className="px-1 d-md-none">
					<div className="">{UFunc.renderHTML(vTglbayar)}</div>
				</CCol>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_pasien||"Pasien"}</CCol>
				<CCol xs="7" md="4" className="px-1 d-md-none">
					<div className="">{UFunc.renderHTML(vPasiencaption)}</div>
				</CCol>

				<CCol xs="3" className="px-1 text-start d-md-none classfontsmaller">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				<CCol xs="12" className="px-1 my-2 classborderbottom d-md-none"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_nilaibayar||"Nilai Bayar"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-success">
					{UFunc.formatAngka(nilai_bayar)}
				</CCol>

				<CCol md="1" className="px-1 text-center d-none d-md-block">
					{uIsHapusallowed ? (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} 
						className="classcursorpointer classikontabel classikonhapus"/>
					</CTooltip>
					) : (<>&middot;</>)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}	
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||1;
		setHtml500msg();

		const vKeyword 		= (uKeywordObj.kwd_previewasuransi||"").trim();
		const vHandelview	= (_PAGE <= 1 || uFirstload==="YA") ? "YA":"TIDAK";
		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			send_maxdata: uMaxData,
			send_firstload	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/ld_preview";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				//console.log("(Pasien - apiLoaddata) vTmpArr : "+JSON.stringify(vTmpArr));
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpArr.length);

				setDatamainArr([...vDatamainArr,...vTmpArr]);
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			uDatamainArr.splice(vArridx,1);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirasuransi/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			setJmlData(0);
			setRowselected(-1);
			setPageActive(1);
			setFirstload("YA");
			setDatamainArr([]);
			setInitselectedObj({});
			uKeywordObj.kwd_previewasuransi = undefined;
			localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setInitselectedObj({});
		setRowselected(-1);

		const vDatapageArr = uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||0)===parseInt(uPageActive||1));

		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{
		if(!uTriggerCari) return;

		setTriggerCari(false);

		setJmlData(0);
		setRowselected(-1);
		setPageActive(1);
		setDatamainArr([]);
		setInitselectedObj({});

		apiLoaddata();
	},[uTriggerCari])
	//---END EFFECT--/

	//console.log("(Previewasuransi) uNavigate => "+JSON.stringify(uNavigate))

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2 text-white" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="me-auto text-normal">
				<b>{uBahasaObj.caption_previewpembayaran||"Preview Pembayaran Asuransi"}</b>
			</CHeaderNav>

			<CHeaderNav className="">
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white py-2 px-3 justify-content-center justify-content-lg-between" style={{minHeight:52}}>
			{contentHeadersub()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer fluid className="px-0">
			<div className="d-none">uJmlData => {uJmlData}</div>
			<CCard className="classbgcard mb-3">
			<CCardHeader className="d-flex justify-content-between align-items-center">
				{contentCardheader()}
			</CCardHeader>
			<CCardBody className="px-0">{contentCardmain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination 
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i)=>setPageActive(i)}
					align="start"/> 
			</CCardFooter>
			)}
			</CCard>
		</CContainer>
		</div>

		</div>
		</>
	) 
}	

export default Previewasuransi