import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CCloseButton,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";

const MyDialogview = props => {
	const { show, toggle, renderContent, dialogHeader, options,...rest } = props;

	const [uEfekkomponen,setEfekkomponen] 	= React.useState(false);
	const uBahasaob	= useSelector(state => state.listBahasa);
	const vElBtnOK	= document.getElementById("btnDialogviewok");

	React.useEffect(()=>{
		if(!show) return;

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setEfekkomponen(!uEfekkomponen);
		},350);//-*/	
	},[show]);
	React.useEffect(()=>{
		if(!show) return;

		if(vElBtnOK) 
			{ vElBtnOK.focus(); return }

		setEfekkomponen(!uEfekkomponen);
	},[uEfekkomponen]);

	//console.log("(MyDialogview) options.centered = "+options.centered);
	return (
		<CModal 
			size={options.hasOwnProperty("size")?options.size:"lg"}
			alignment={(options.centered)===true?"center":"top"}
			scrollable={true}
			visible={show}>
		<CModalHeader
			className="p-2 px-3"
			closeButton={false}>
			<CModalTitle>
				<small>{UFunc.renderHTML(dialogHeader||MyDialogview.defaultProps.dialogHeader)}</small>
			</CModalTitle>
			<CCloseButton onClick={toggle}/>
		</CModalHeader>

		<CModalBody style={{
			backgroundColor:options.hasOwnProperty("bgColor")?options.bgColor:MyDialogview.defaultProps.options.bgColor,
			}}>
			{renderContent || MyDialogview.defaultProps.renderContent}
		</CModalBody>
		<CModalFooter className="border-0" style={{
			backgroundColor:options.hasOwnProperty("bgColor")?options.bgColor:MyDialogview.defaultProps.options.bgColor,
			}}>
		<CButton 
			className="me-2 me-md-4 bg-transparent border-light"
			onClick={toggle}
			id="btnDialogviewok">
		<strong className="text-success">{uBahasaob.word_ok||"OK"}</strong>
		</CButton>
		</CModalFooter>
		</CModal>
	)
}
MyDialogview.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "View Data",
	options: {centered:true,size:"lg",bgColor:"white"},
};

MyDialogview.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	options: PropTypes.object,
}

export default MyDialogview;
