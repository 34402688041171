import { createConfirmation } from 'react-confirm';
import { MyDialog } from '../components/index';
import { UFunc } from '../helpers/functions'
 
// create confirm function
export const dialogForm = createConfirmation(MyDialog);
 
// This is optional. But wrapping function makes it easy to use.
export const Konfirm = (dialogContent) => {
	return dialogForm({ dialogContent });
}//-*/
/*export const Konfirm=(dialogContent)=>{
	return window.confirm(UFunc.removeTAGS(UFunc.br2nl(dialogContent)));
}//-*/
	