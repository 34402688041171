import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Antrianexpired 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END VIEWS_VARS--/

	//---DLGPASIEN_VARS--/
	const [uIsDlgpasienshow,setDlgpasienshow]	= React.useState(false);
	const [uDlgPasienID,setDlgPasienID]	= React.useState(0);
	//---END DLGPASIEN_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggleDlgpasien = () => { setDlgpasienshow(false) }
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vPasienID	= uDatamainArr[vArridx].pasien_id || 0;
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);

		setDlgPasienID(vPasienID);
		setDlgpasienshow(true)
	}
	const hdlKlikDaftarantri = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vPasienID	= uDatamainArr[vArridx].pasien_id || 0;
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vRowidx);
		
		const vTmpObj	= { initID:_IDTABEL, initPage: uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})

		uNavigate("/subpasien/pasien/daftarantri/"+vPasienID);
	}
	//--END HANDEL--/

	//--INIT--/
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="6" className="text-start">{uBahasaObj.word_pasien||"Pasien"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_tglantri||"Rencana Tgl"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,dokter_id,dokter_caption,pasien_id,pasien_caption,pasien_telp,
				jam_awal,tgl_antri,
				init_process,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (pasien_caption||"UNDF").toUpperCase();

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="6" className="px-1 text-start d-flex justify-content-between">
					<div>
					<div className="text-capitalize text-info">{pasien_caption||"UNDF"}</div>

					{parseInt(dokter_id||0) > 0 && (
					<small className="text-success fst-italic d-none d-md-block">{dokter_caption||""}</small>
					)}

					{((pasien_telp||"").trim()!=="" && uTokenObj.userhak!=="DOKTER") && (
					<small className="d-none d-md-block">Telp/HP. <b className="text-primary">{pasien_telp}</b></small>
					)}
					</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="me-0" height={18}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
							<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
							Detil Pasien
						</CDropdownItem>
						<CDropdownItem onClick={()=>hdlKlikDaftarantri(id)}>
							<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-1" height={25}/>
							Daftarkan Kembali
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_notelp||"Telp/HP"}</CCol>
				<CCol xs="8" className="px-1 text-primary fw-bolder d-md-none">
					{(uTokenObj.userhak!=="DOKTER") ? (<>{pasien_telp||"-"}</>)
					: (<>&middot;</>)}
				</CCol>

				{parseInt(dokter_id||0) > 0 && (
				<>
				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
				<CCol xs="8" className="px-1 d-md-none text-success fst-italic">{dokter_caption||"-"}</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_rencanatgl||"Rencana Tanggal"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-center">{UFunc.TglAngka(tgl_antri)}</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-center">
					{parseInt(dokter_id||0) <= 0 ? "Antrian UMUM"
						: (<b>{(jam_awal||"").substring(0,5)}</b>)}
				</CCol>

				<CCol md="2" className="px-1 d-none d-md-block text-md-center">
					<CTooltip content={"--"+(uBahasaObj.caption_viewdetil||"Lihat Detil")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikLihatdetil(id)} 
						className="classcursorpointer">
						<CIcon icon="cilFindInPage" className="classikonbox" height={25}/>
					</CLink>
					</CTooltip>

					<CTooltip content={"--"+(uBahasaObj.caption_daftarkembali||"Daftarkan Kembali")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikDaftarantri(id)} 
						className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="mx-1" height={25}/>
					</CLink>
					</CTooltip>
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vBulanIDdefault		= UFunc.leadZero((UFunc.toDate()).getMonth() + 1);
		const vTahundefault			= (UFunc.toDate()).getFullYear();
		const vParamsObj = uKeywordObj.filter_antrianexpired || {};
		const vDATAS	= JSON.stringify({
			send_bulanid : vParamsObj.bulan_id || vBulanIDdefault,
			send_tahun : vParamsObj.tahun || vTahundefault,
			send_cabangid : vParamsObj.cabang_id || uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_expired";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr 		= JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);
				setJmlData((parseInt(vTmpArr.length)||0));

		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianexpired - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianexpired - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ 
		if(JSON.stringify(uInitselectedObj||{}) === "{}") 
			{ setRowselect(-1); return; }

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
		//console.log("(Pasien - [uPageActive]) uInitselectedObj.initID => "+uInitselectedObj.initID)

		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))

		setInitselectedObj({});
	},[uPageActive])//-->Unselect_ROW_UNTUK_JENIS_DATA_LOAD_ALL
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		if(parseInt(uInitselectedObj.initPage||1) > 1)
			{ setPageActive((uInitselectedObj.initPage||1)); return }

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
			
		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])//-->UNTUK_JENIS_DATA_LOAD_ALL

	//console.log("Antrianexpired - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard mb-3">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			<span className="mx-1 d-md-none">&middot;</span>
			<small className="d-md-none text-primary align-middle">({UFunc.formatAngka(uJmlData)})</small>
		</div>

		<div className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</div>
		</CCardHeader>

		<CCardBody className="px-0 px-lg-3">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogpasien show={uIsDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={hdlToggleDlgpasien}
			{...props}/>
		</>
	)
}	

export default Antrianexpired