import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Kompetensidokter 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 50);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uKompetensiIDArr,setKompetensiIDArr] = React.useState([])
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uIsDlgviewshow,setDlgviewshow]			= React.useState(false);
	const [uuDlgviewheader,setuDlgviewheader]			= React.useState("");
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlgview = () =>{ setDlgviewshow(false) }
	const hdlChangekompetensiID = (_EVN) => {
		const vChecked	= _EVN.target.checked;
		const vValueID	= _EVN.target.value;

		apiProsessimpan(vValueID,vChecked)
	}
	const hdlKlikLihatterapi = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;if(_IDTABEL<=0) return;
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper	= (uDatamainArr[vIdxObj].kompetensi_caption||"").toUpperCase();
		const vTerapiArr= uDatamainArr[vIdxObj].items_arr || [];

		setIDselect(_IDTABEL);
		setuDlgviewheader("Terapi Kompetensi: "+vCaptionupper);
		if(vTerapiArr.length > 0) {
			setDlgviewshow(true); return;
		}

		apiLoaditem(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vDokterIDselected = parseInt(uKeywordObj.dokterid_selected)||0;
		if(uTokenObj.userhak === "DOKTER") {
			const vKompetensifilterArr = uDatamainArr.filter(vItems=>
				uKompetensiIDArr.includes((vItems.id||"").toString()) )

			return (
			<CRow className="mx-0 mx-lg-5">
			{vKompetensifilterArr.map((vItems,vKeys)=>{
				return (
				<CCol md="6" className="my-1" key={vKeys}>
				<div className="border bg-light rounded px-2 py-1 d-flex justify-content-between">
					<div>
						<CIcon icon="cilCheckCircle" className="me-2 text-success" height={20}/>
						{(vItems.kompetensi_caption||"").toUpperCase()}
					</div>
					<CTooltip content="--Lihat Terapi Kompetensi..">
						<CLink 
							onClick={()=>hdlKlikLihatterapi(vItems.id)}
							className="classcursorpointer">
						<CIcon icon="cilFindInPage" className="classikonbox" height={25}/>
						</CLink>
					</CTooltip>
				</div>
				</CCol>
				)
			})}
			</CRow>
			)
		}

		return (
		<>
		<CRow className="mx-0 mx-lg-5">
		{uDatamainArr.map((vItems,vKeys)=>{
			return (
			<CCol md="6" className="my-1" key={vKeys}>
				<div className="border bg-light rounded px-2 py-1 d-flex justify-content-between">
				<CFormCheck
					value={vItems.id}
					label={(vItems.kompetensi_caption||"").toUpperCase()}
					checked={uKompetensiIDArr.findIndex(vItemkompetensi=>vItemkompetensi.toString()===(vItems.id||"").toString()) >= 0}
					disabled={(vDokterIDselected <= 0)}
					onChange={(e)=>hdlChangekompetensiID(e)}
					id={"inpkompetensiid"+vItems.id}/>
				<CTooltip content="--Lihat Terapi Kompetensi..">
					<CLink 
						onClick={()=>hdlKlikLihatterapi(vItems.id)}
						className="classcursorpointer">
					<CIcon icon="cilFindInPage" className="classikonbox" height={25}/>
					</CLink>
				</CTooltip>
				</div>
			</CCol>
			)
		})}
		</CRow>
		</>
		)
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)

		const vIdxObj = UFunc.getObjectIndeks(uDatamainArr,uIDselect);
		const vTerapiArr = uDatamainArr[vIdxObj].items_arr || [];

		return (
		<>
		{vTerapiArr.map((vItems,vKeys)=>{
			return (
			<CRow className="mx-lg-5 py-2 classborderbottom" key={vKeys}>
			<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
			<CCol xs="9" className="px-1 text-info fs-italic">{vItems.terapi_caption}</CCol>
			<CCol xs="2" className="px-1 text-end classfontsmaller">Rp {UFunc.formatAngka(vItems.terapi_tarif)}</CCol>
			</CRow>
			)
		})}
		</>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : "",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensidokter - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensidokter - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadselected = () => {
		const vDokterID	= parseInt(uKeywordObj.dokterid_selected) || 0;
		setKompetensiIDArr([]);
		if(vDokterID <= 0) return;

		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Kompetensidokter - apiLoadselected) LEWAT"); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_dokterid : vDokterID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_selected";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.kompetensiidlist||"[]")
				//console.log("(Kompetensidokter - apiLoadselected) output_string.kompetensiidlist : "+output_string.kompetensiidlist);
				setKompetensiIDArr(vTmpArr);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensidokter - apiLoadselected) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgviewshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensidokter - apiLoadselected) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_itemonly : "YA",
			send_tabelid : _IDTABEL,
			send_cabangid : uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr 	= JSON.parse(output_string.itemslist||"[]");
				if(vTmpArr.length <= 0) {
					showToast("Data Tidak Ada !");
					return;
				}
				uDatamainArr[vIdxObj].items_arr 	= vTmpArr;
				setDlgviewshow(true);
			} else  {
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kompetensi - apiLoaditem) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensi - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDVALUE,_isCHECKED) => {
		_IDVALUE 	= parseInt(_IDVALUE)||0; if(_IDVALUE<=0) return;
		_isCHECKED 	= _isCHECKED || false;

		const vDokterID	= parseInt(uKeywordObj.dokterid_selected) || 0;
		const vIdxObj 	= uKompetensiIDArr.findIndex(vItems=>vItems.toString()===_IDVALUE.toString());
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Kompetensidokter - apiProsessimpan) uKompetensiIDArr = "+uKompetensiIDArr); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_valueid : _IDVALUE,
			send_dokterid : vDokterID,
			send_ischecked : _isCHECKED ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/pr_simpankompetensi";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				if(_isCHECKED) {
					setKompetensiIDArr([...uKompetensiIDArr,(_IDVALUE||"0").toString()]);
				}  else {
					uKompetensiIDArr.splice(vIdxObj,1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensidokter - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensidokter - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			setDlgviewshow(false);
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoadselected();
	},[uHandelView])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/

	//console.log("Kompetensidokter - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>
		</CCard>

		<MyDialogview
			options={{centered:false}}
			show={uIsDlgviewshow} 
			dialogHeader={uuDlgviewheader}
			toggle={hdlToggledlgview}
			renderContent={contentDlgview()}/>
		</>
	)
}	

export default Kompetensidokter