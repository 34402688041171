import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CRow,
	CCol,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubkasir = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaob  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenob				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const hdlKeydown = (_EV) => {}
	const hdlKlikCari = () => {}
	const hdlKlikTambah = () => {}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_kasir = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			//---END CLEAR_KEYWORD--/
			
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		vProses();	
	}

	//console.log("Headersubkasir - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-1"
			height={30}/>
		{uBahasaob["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<div className="ms-md-1">
			<CTooltip content={"--"+(uBahasaob.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="mx-1">&middot;</span>

			(<b id="idjmldata" className="text-primary">0</b> {uBahasaob.word_data||"Data"})
		</div>
		</>
	)
}

export default Headersubkasir
