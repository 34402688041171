import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyHeaderProfile,
	MyButton,
	MyDialogform,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Fotopasien 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uDatamainObj,setDatamainObj]		= React.useState({})

	//--DLG_FORM--/
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	const [uISDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikBack = () => { uNavigate(-1); }
	const hdlKlikReset = () => {}
	const hdlKlikDlgformsimpan = () => {}
	const hdlKlikSimpanmain = () => {}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentHeadersub = () => {
		if(uHtml500msg) return (
		<>
		Page 500
		<CLink onClick={()=>hdlKlikReset()} className="classcursorpointer classikon classikonreset"/>
		</>
		)//>

		return (
		<>
			SUB HEADER
			<div>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			<span className="mx-1">&middot;</span>
			<small className="align-top">Rp</small>
			<small className="align-middle text-success fw-bold display-6">0</small>
			</div>
		</>
		)
	}
	const contentNota = () => {}
	const contentCustomer = () => {}
	const contentitemform = () => {}
	const contentitem = () => {}
	const contentDlgform = () => {}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
    	
    	apiLoaddata();

    	return () => {
    		setDatamainObj({});
			//setDlgviewshow(false);
    	}
	},[])
	//---END EFFECT--/

	console.log("(Fotopasien) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto text-normal">
				<b>Form Create Invoice</b>
			</CHeaderBrand>

			<CHeaderNav>
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white p-2 px-3" style={{minHeight:52}}>
			{contentHeadersub()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="px-0">
			{(uHtml500msg) ? (
			<CCard className="classbgcard mb-2">
			<CCardHeader>Error Page 500</CCardHeader>
			<CCardBody>
				<div className="mx-3">{UFunc.renderHTML(uHtml500msg)}</div>
			</CCardBody>
			</CCard>
			) : (
			<>
			<CRow>
			<CCol>
			<CCard className="classbgcard mb-3">
			<CCardBody>{contentNota()}</CCardBody>
			</CCard>
			</CCol>

			<CCol>
			<CCard className="classbgcard mb-3">
			<CCardBody>{contentCustomer()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>

			<CRow>
			<CCol>
			<CCard className="classbgcard mb-3">
			<CCardHeader>{contentitemform()}</CCardHeader>
			<CCardBody>{contentitem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</>
			)}
		</CContainer>
		</div>

		<CFooter className="px-3 text-black-50 justify-content-center" 
			style={{backgroundColor:"#AEAEAE22"}}>
		<MyButton
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaob.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	) 
}	

export default Fotopasien