import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormInput,
	CInputGroup,
	CFormSelect,
	CLink,
	CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubkonfigdata = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit			= parseInt(localStorage.getItem("lastcabangid")||"0")
	const uCabangIDArr			= uTokenObj.user_cabangarr ||[];
	const uCabangallowArr		= uTokenObj.userhak!=="FREEROLE" ? uDatacabangArr.filter(vItems=>
		uCabangIDArr.includes((vItems.id||"").toString()) )
		: uDatacabangArr; 

	const [uInpCabangid,setInpCabangid]	= React.useState(uCabangIDinit);

	const hdlKeydown = (_EV) => {}
	const hdlChangeCabang = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_konfigdata = uInpCabangid; 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_konfigdata = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpCabangid(uCabangIDinit);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		vProses();	
	}

	React.useEffect(()=>{
		return () => { setInpCabangid(uCabangIDinit) }
	},[])
	React.useEffect(()=>{ hdlChangeCabang() },[uInpCabangid])

	//console.log("Headersubkonfigdata - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2 align-middle"
			height={30}/>
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		{uDatacabangArr.length > 1 ? (
		<>
		<CForm>
			<CFormSelect size="sm"
				value={uInpCabangid}
				onChange={(e)=>setInpCabangid(e.target.value)}
				id="inpcabangid">
				{uCabangallowArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>{(vItems.cabang_nama||"").toUpperCase()}</option>
					)
				})}
			</CFormSelect>
		</CForm>
		<div className="d-none d-lg-block" style={{width:"15%"}}/>
		</>
		) : (
			<CLink onClick={hdlChangeCabang} className="classikon classikonreset classcursorpointer"/>
		)}
		</>
	)
}

export default Headersubkonfigdata
