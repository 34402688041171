import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton
} from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json')
const A1profile = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");

	const uUserCabangIDArr			= uTokenObj.user_cabangarr || [];
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 

	//---FORM_VARS--/
	const [uInpusername,setInpusername]		= React.useState(uTokenObj.username||"");
	const [uInpusercaption,setInpusercaption]= React.useState(uTokenObj.usercaption||"");
	const [uInppasswdold,setInppasswdold]	= React.useState("");
	const [uInppasswd1,setInppasswd1]		= React.useState("");
	const [uInppasswd2,setInppasswd2]		= React.useState("");
	const [uBtndisabled,setBtndisabled]		= React.useState(false);
	//---END FORM_VARS--/

	const hdlKlikSimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpusercaption");
		if(uInpusercaption.trim()==="") {
			vElFocus.focus();
			showToast("Nama User Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpusername");
		if(uInpusername.trim()==="") {
			vElFocus.focus();
			showToast("Username Harus Diisi !");return;
		}
		if(uInppasswd1 !=="" || uInppasswd2!=="") {
			vElFocus = document.getElementById("inppasswdold");
			if(uInppasswdold === "") {
				vElFocus.focus();
				showToast("Password Lama Harus Diisi !"); return;
			}

			vElFocus = document.getElementById("inppasswd1");
			if(uInppasswd1 !== uInppasswd2) {
				vElFocus.focus();
				showToast("Password dan Konfirmasi Password Harus Sama !"); return;
			}
		}

		apiProsessimpan();
	}

	const initContentform = () => {
		if(uHtml500msg) return ( UFunc.renderHTML(uHtml500msg) );

		return (
		<CForm className="mx-1 mx-lg-5">
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_usercaption||"Nama User"}</CCol>
		<CCol xs="8" className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpusercaption}
				onChange={(e)=>setInpusercaption(e.target.value)}
				id="inpusercaption"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.word_username||"Username"}</CCol>
		<CCol xs="8" md="5" lg="4">
			<CFormInput size="sm"
				maxLength={50}
				value={uInpusername}
				onChange={(e)=>setInpusername(e.target.value)}
				id="inpusername"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<span className="d-none d-md-block">Password Lama</span>
			<span className="d-md-none classfontsmaller"><small>Password Lama</small></span>
		</CCol>
		<CCol xs="8" md="5" lg="4">
			<CFormInput size="sm" type="password"
				value={uInppasswdold}
				onChange={(e)=>setInppasswdold(e.target.value)}
				id="inppasswdold"/>
			<div className="classpetunjuk px-0">
			&#8627; Harus Diisi Jika MENGGANTI Password
			</div>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<span className="d-none d-md-block">Password</span>
			<span className="d-md-none classfontsmaller"><small>Password</small></span>
		</CCol>
		<CCol xs="8" md="5" lg="4">
			<CFormInput size="sm" type="password"
				value={uInppasswd1}
				onChange={(e)=>setInppasswd1(e.target.value)}
				id="inppasswd1"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<span className="d-none d-md-block">Konfirmasi Password</span>
			<span className="d-md-none classfontsmaller"><small>Konfirm Password</small></span>
		</CCol>
		<CCol xs="8" md="5" lg="4">
			<CFormInput size="sm" type="password"
				value={uInppasswd2}
				onChange={(e)=>setInppasswd2(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				id="inppasswd2"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
		<CCol xs="8" className="">
			<strong>{uBahasaObj["hakuser_"+(uTokenObj.userhak||"entri").toLowerCase()]}</strong>
		</CCol>
		</CRow>
		
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_cabangakses||"Cabang Akses"}</CCol>
		<CCol xs="8" className="">
			{uDatacabangArr.filter(vItems => uUserCabangIDArr.includes(vItems.id)).
				map((vItems,vKeys)=>{
					return (
					<>
						{vKeys > 0 && (
						<span className="mx-1">&middot;</span>
						)}
						<i key={vKeys} className="text-uppercase">{vItems.cabang_nama}</i>
					</>
					)
				})
			}
		</CCol>
		</CRow>
		)}
		</CForm>
		)
	}

	const apiProsessimpan = () => {
		setLoading(true)
		setBtndisabled(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_usercaption : uInpusercaption,
			send_username : uInpusername,
			send_passwdold : uInppasswdold,
			send_passwd1 : uInppasswd1,
			send_passwd2 : uInppasswd1,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/pr_profile";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			setBtndisabled(false)
			setLoading(false)
			if(output_string.sukses) {
				setLoading(false)
				setBtndisabled(false)

				setInppasswdold("");
				setInppasswd1("");
				setInppasswd2("");

				showToast(uBahasaObj.pesan_sukses||"Berhasil Disimpan !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(A1profile - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setBtndisabled(true); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setBtndisabled(false)
			console.log("(A1profile - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		if((uTokenObj.username||"").toUpperCase()==="SUPERADMIN") 
			{uNavigate("/subdasar/user"); return}//-*/

		uDispatch({type: "set", effectApp: !uEfekapp});
		return ()=>{}
	},[])
	//React.useEffect(()=>{ uDispatch({type: "set", effectApp: !uEfekapp}); },[uEfekapp])

	//console.log("A1profile - uActiveroute => "+(uActiveroute));
	return (
		<CCard className="mx-md-2 mx-lg-5 classbgcard">
		<CCardHeader><strong>Edit Akun Anda</strong></CCardHeader>
		<CCardBody>{initContentform()}</CCardBody>
		<CCardFooter className="text-center">
			<MyButton
				disabled={uBtndisabled}
				onClick={()=>hdlKlikSimpan()}
				iconname="cilCheckAlt"
				id="btnSimpan">Simpan</MyButton>
		</CCardFooter>
		</CCard>
	)
}	

export default A1profile