import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { UFunc } from '../helpers/functions'

const MyToaster = props => {
    const { toastData } 		= props;
	const uDispatch				= useDispatch();
  	const [toaser,setToaser]	= useState(toastData);
  	const [uActionclose,setActionclose]	= useState(false);

	//React.useEffect(() => { console.log("(MyToaster - [] "); },[]);//*/
	React.useEffect(() => { 
		if(!uActionclose) return;

		//console.log("(MyToaster - [uActionclose] 2"); 
		uDispatch({type: "set", listToast: []});
		setActionclose(!uActionclose);
	},[uActionclose]);
	React.useEffect(() => {
		//console.log("(MyToaster - [toastData] => "+JSON.stringify(toastData));
		setToaser([...toastData]);

		return () => {
			setToaser([])
		}
	},[toastData]);//*/
	
	//console.log("(MyToaster)  toaser = "+toaser.length);
	return (
		<>
		<CToaster placement="bottom-end">
		{toaser.map((result, key)=>{
			var resultType = null;
			switch(result.type.toLowerCase()) {
				case "info":
					resultType = { class: "bg-info", title: "Informasi", }
					break;
				case "error":
					resultType = { class: "bg-danger", title: "Error", }
					break;
				case "sukses":
					resultType = { class: "bg-success", title: "Berhasil", }
					break;
				default:
					resultType = { class: "", title: "Informasi", }
			}

			return(
				<div key={key}>
				<CToast onClose={()=>setActionclose(!uActionclose)}
					animation={true} 
					delay={3000}
					visible={true}>
					<CToastHeader
						className={resultType.class+" text-white font-weight-bolder"} 
						closeButton>
						<strong className="me-auto">{(resultType.title)}</strong>
						<small>&nbsp;</small>
					</CToastHeader>
					<CToastBody>
					{UFunc.renderHTML(result.teksIsi)}
					</CToastBody>
				</CToast>
				</div>
			)
		})}
		</CToaster>
		</>
	);
}
MyToaster.propTypes = {
	toastData: PropTypes.array,
}


export default MyToaster;
