import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Payment 	= (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangID 					= parseInt(uTokenObj.user_cabangid)||0;
	const uCabangIDselected				= parseInt(localStorage.getItem("cabangidselect")||uCabangID)
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uInpnamapayment,setInpnamapayment] = React.useState("");
	const [uInpjenis,setInpjenis] 			= React.useState("RATE");
	const [uInpnilairate,setInpnilairate] 	= React.useState(0);
	const [uInpnilaifixed,setInpnilaifixed] = React.useState(0);
	const [uHeaderdlg,setHeaderdlg]			= React.useState("");
	const [uInpCabangIDArr,setInpCabangIDArr] = React.useState([]);
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setShowForm(false) }
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].payment_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		const vJenis = uDatamainArr[vIdxObj].payment_jenis||"RATE";

		setInpnamapayment(uDatamainArr[vIdxObj].payment_caption||"");
		setInpnilaifixed(vJenis!=="RATE"?parseFloat(uDatamainArr[vIdxObj].nilai||0) : 0);
		setInpnilairate(vJenis==="RATE"?parseFloat(uDatamainArr[vIdxObj].nilai||0) : 0);
		setInpjenis(vJenis);

		setHeaderdlg("Edit Data: "+vCaptionUpper);
		setShowForm(true)
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].payment_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikCabangaksesall = () => {
		let vIscheckedall = true;
		uDatacabangArr.forEach(vItems => {
			if(!uInpCabangIDArr.includes(vItems.id)) {
				vIscheckedall = false;
			} 
		});

		uInpCabangIDArr.splice(0,uInpCabangIDArr.length); 
		if(vIscheckedall) {
			setEfekview(!uEfekview); return;
		}

		let vTmpAll	= []
		uDatacabangArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems.id]);
			
		setInpCabangIDArr(vTmpAll);
		setEfekview(!uEfekview);
	}
	const hdlChangeCabangakses = (_EV) => {
		const vValueID	= _EV.target.value;
		const vIschecked= _EV.target.checked;

		//showToast("vValueID = "+vValueID+" | vIschecked = "+vIschecked)
		if(vIschecked) {
			setInpCabangIDArr([...uInpCabangIDArr,vValueID])
		} else {
			const vIdx = uInpCabangIDArr.findIndex(vItems => vItems===vValueID);
			uInpCabangIDArr.splice(vIdx,1);
		}
		setEfekview(!uEfekview);
	}
	const hdlKlikDlgsimpan = () => {
		let vElFocus = document.getElementById("inpnamapayment");
		if(uInpnamapayment.trim()==="") {
			vElFocus && vElFocus.focus();
			showToast("Nama Payment Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpnilairate");
		if(parseFloat(uInpnilairate) < 0) {
			vElFocus && vElFocus.focus();
			showToast("Nilai Biaya (RATE) Tidak Valid !");return;
		}
		vElFocus = document.getElementById("inpnilaifixed");
		if(parseFloat(uInpnilaifixed) < 0) {
			vElFocus && vElFocus.focus();
			showToast("Nilai Biaya (FIXED) Tidak Valid !");return;
		}

		apiProsessimpan(uIDselect);
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(Payment - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)

		setInpnamapayment("")
		setInpjenis("RATE")
		setInpnilairate(0)
		setInpnilaifixed(0)
		setInpCabangIDArr([uCabangID.toString()]);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_payment || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div className="mx-lg-5">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={40} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="6" className="text-start">{uBahasaObj.caption_metodepayment||"Metode Pembayaran"}</CCol>
			<CCol md="2" className="">Jenis</CCol>
			<CCol md="2" className="">Nilai Biaya</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,payment_caption,
				init_process,payment_jenis,nilai
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (payment_caption||"").toUpperCase();

			const vPaymentcaption	= vKeyword==="" ? (payment_caption||"") 
				: (payment_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenis	= vKeyword==="" ? (payment_jenis||"") 
				: (payment_jenis||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="6" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<span className="text-capitalize text-info">{UFunc.renderHTML(vPaymentcaption)}</span>
						
						{(uIsHapusallow) && (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" 
							color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							
							{uTokenObj.userhak==="FREEROLE" && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
						)}
					</div>

					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>
						[{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
						</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
			
				<CCol xs="5" className="px-1 d-md-none text-start">{"Jenis"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center">
					<small>{UFunc.renderHTML(vJenis)}</small>
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{"Nilai"}</CCol>
				<CCol xs="8" md="2" className="px-1">
					<b className={payment_jenis==="FIXED"?"text-success":"text-primary"}>
					{payment_jenis==="FIXED" && (<>Rp</>)} {UFunc.formatAngka(nilai)} {payment_jenis!=="FIXED" && (<>%</>)}
					</b>
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="8" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				<CCol md="2" className="px-0 text-md-center d-none d-md-block">
					{uIsHapusallow && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>

					{(uTokenObj.userhak==="FREEROLE" && !uISDemoapp) && (
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
					</CTooltip>
					)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>

		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>)

		const vTokencabangIDArr = uTokenObj.user_cabangarr || [];
		const vCabangshowArr = uTokenObj.userhak !== "FREEROLE"
			? uDatacabangArr.filter(vItems => 
				vTokencabangIDArr.includes(vItems.id.toString()))
			: uDatacabangArr;

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_metodepayment||"Nama Payment"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpnamapayment}
				onChange={(e)=>setInpnamapayment(e.target.value)}
				id="inpnamapayment"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_jenisbiaya||"Jenis Biaya"}</CCol>
		<CCol xs="8" md="5" lg="4" className="">
			<CFormSelect size="sm"
				value={uInpjenis}
				onChange={(e)=>setInpjenis(e.target.value)}
				id="inpjenispayment">
			<option value="RATE">RATE</option>
			<option value="FIXED">FIXED</option>
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_nilaibiaya||"Nilai Biaya"}</CCol>
		{uInpjenis==="RATE" && (
		<CCol xs="8" md="3" lg="2" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				pattern="^\d*(\,\d{0,2})?$"
				max={100}
				min={0}
				step={0.1}
				className="text-end"
				value={uInpnilairate}
				onFocus={(e)=>e.target.select()}
				onChange={(e)=>setInpnilairate(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnilairate"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
		</CCol>
		)}

		{uInpjenis!=="RATE" && (
		<CCol xs="8" md="5" lg="4" className="">
			<NumberFormat 
				value={uInpnilaifixed}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => setInpnilaifixed(values.value)}
				renderText={values => (
				<CFormInput size="sm"
					value={values}
					className="text-end"
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>setInpnilaifixed(e.target.value)}
					onKeyDown={(e)=>{
						if(parseInt(uIDselect) <= 0) UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					}
					id="inpnilaifixed"/>
				)} 
				prefix={isMobile?"":"Rp"}/>
		</CCol>
		)}
		</CRow>

		{parseInt(uIDselect) > 0 && (
		<>
			{uDatacabangArr.length > 1 && (
			<>
			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end">
				<CLink
					onClick={()=>hdlKlikCabangaksesall()}
					className="classcursorpointer">
				{uBahasaObj.caption_pilihcabang||"Pilih Cabang"}
				</CLink>
				<div className="classpetunjuk">&#8627; Pilih Cabang yg Akan DIEDIT Datanya..</div>
			</CCol>
			<CCol className="">
				<CInputGroup size="sm">
				{uDatacabangArr.map((vItems,vKeys)=>{
				return (
					<CInputGroupText
						key={vKeys}
						className="p-0 ps-2 pt-1 me-3 mb-2 bg-light rounded text-dark"
						style={{minWidth:175}}>
					<CFormCheck inline size="sm"
						value={vItems.id}
						id={"inpcabangid"+vItems.id} 
						label={vItems.cabang_nama}
						onChange={(e)=>hdlChangeCabangakses(e)}
						checked={uInpCabangIDArr.includes(vItems.id)}
						className="classcursorpointer"/>
					</CInputGroupText>
				)
				})}
				</CInputGroup>
			</CCol>
			</CRow>
			</>
			)}
		</>
		)}
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg("");

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_payment || "";
		const vCabangIDselect = uCabangIDselected;
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_cabangid : vCabangIDselect,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payment - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Payment - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_namapayment : uInpnamapayment,
			send_jenisbiaya : uInpjenis,
			send_nilairate : parseFloat(uInpnilairate)||0,
			send_nilaifixed : parseFloat(uInpnilaifixed)||0,
			send_cabangidlist : uInpCabangIDArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						payment_caption:uInpnamapayment,
						payment_jenis:uInpjenis,
						nilai:uInpjenis==="RATE" ? parseFloat(uInpnilairate) : parseFloat(uInpnilaifixed),
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					setJmlData(uJmlData+1);

					initFormAwal();
					setPageActive(1);
					setRowselect(-1);
					if(!isMobile) {
						document.getElementById("inpnamapayment") && 
							document.getElementById("inpnamapayment").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vIdxObj].payment_caption= uInpnamapayment;
					uDatamainArr[vIdxObj].payment_jenis	= uInpjenis;
					uDatamainArr[vIdxObj].nilai 		=
						uInpjenis==="RATE" ? parseFloat(uInpnilairate) : parseFloat(uInpnilaifixed);

					hdlToggledlg();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Payment - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payment/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vIdxObj,1);
				setRowselect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payment - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payment - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
    		setDatamainArr([]);
   		 	localStorage.removeItem("cabangidselect");
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_payment || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;
		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		setHeaderdlg((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true)
	},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpnamapayment");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm])
	React.useEffect(()=>{
		if(!isShowForm) return;

		//setInpnilaifixed(0); setInpnilairate(0);
	},[uInpjenis])
	//console.log("Payment - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={isShowForm} 
			dialogHeader={uHeaderdlg}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Payment