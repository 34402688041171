import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton
} from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json')
const Laplabarugi = (props) => {
	const { setToken, setLoading,showToast,prosesExpired } = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenob					= JSON.parse(localStorage.getItem("token")||"{}");

	const [uHtml500msg,setHtml500msg]	= React.useState(); 

	//---FORM_VARS--/
	const [uInpusername,setInpusername]		= React.useState(uTokenob.username||"");
	const [uInpusercaption,setInpusercaption]= React.useState(uTokenob.usercaption||"");
	const [uInppasswdold,setInppasswdold]	= React.useState("");
	const [uInppasswd1,setInppasswd1]		= React.useState("");
	const [uInppasswd2,setInppasswd2]		= React.useState("");
	const [uBtndisabled,setBtndisabled]		= React.useState(false);
	//---END FORM_VARS--/

	const hdlKlikSimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpusercaption");
		if(uInpusercaption.trim()==="") {
			vElFocus.focus();
			showToast("Nama User Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpusername");
		if(uInpusername.trim()==="") {
			vElFocus.focus();
			showToast("Username Harus Diisi !");return;
		}
		if(uInppasswd1 !=="" || uInppasswd2!=="") {
			vElFocus = document.getElementById("inppasswdold");
			if(uInppasswdold === "") {
				vElFocus.focus();
				showToast("Password Lama Harus Diisi !"); return;
			}

			vElFocus = document.getElementById("inppasswd1");
			if(uInppasswd1 !== uInppasswd2) {
				vElFocus.focus();
				showToast("Password dan Konfirmasi Password Harus Sama !"); return;
			}
		}

		apiProsessimpan();
	}

	const initContentform = () => {
		if(uHtml500msg) return ( UFunc.renderHTML(uHtml500msg) );

		return (
		<CForm className="mx-1 mx-lg-5">
		<CRow className="my-2">
		<CCol md="4" className="text-end">{uBahasaob.caption_usercaption||"Nama User"}</CCol>
		<CCol md="8" lg="7" className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpusercaption}
				onChange={(e)=>setInpusercaption(e.target.value)}
				id="inpusercaption"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-end">{uBahasaob.word_username||"Username"}</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm"
				maxLength={50}
				value={uInpusername}
				onChange={(e)=>setInpusername(e.target.value)}
				id="inpusername"/>
		</CCol>
		</CRow>

		<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-end">Password Lama</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswdold}
				onChange={(e)=>setInppasswdold(e.target.value)}
				id="inppasswdold"/>
		</CCol>
		</CRow>
		<CRow className="my-0 ms-0">
		<CCol md="4" className="text-end"/>
		<CCol md="8" className="classpetunjuk">
			&#8627; Harus Diisi Jika MENGGANTI Password
		</CCol>
		</CRow>

		<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-end">Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswd1}
				onChange={(e)=>setInppasswd1(e.target.value)}
				id="inppasswd1"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-end">Konfirmasi Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswd2}
				onChange={(e)=>setInppasswd2(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				id="inppasswd2"/>
		</CCol>
		</CRow>
		
		<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-end">{uBahasaob.caption_hakakses||"Hak Akses"}</CCol>
		<CCol md="6" lg="4" className="">
			<strong>{uBahasaob["hakuser_"+(uTokenob.userhak||"entri").toLowerCase()]}</strong>
		</CCol>
		</CRow>
		</CForm>
		)
	}

	const apiProsessimpan = () => {
		setLoading(true)
		setBtndisabled(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false)
			setBtndisabled(false)

			setInppasswdold("");
			setInppasswd1("");
			setInppasswd2("");

			showToast(uBahasaob.pesan_sukses||"Berhasil Disimpan !","SUKSES");
		},2000); return
		//--END TESTING_FRONTEND--*/

		if((uTokenob.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_usercaption : uInpusercaption,
			send_username : uInpusername,
			send_passwdold : uInppasswdold,
			send_passwd1 : uInppasswd1,
			send_passwd2 : uInppasswd1,
			send_tokenauth : uTokenob.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/pr_profile";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setBtndisabled(false)
			setLoading(false)
			if(output_string.sukses) {
				setLoading(false)
				setBtndisabled(false)

				setInppasswdold("");
				setInppasswd1("");
				setInppasswd2("");

				showToast(uBahasaob.pesan_sukses||"Berhasil Disimpan !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laplabarugi - apiProsessimpan) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setBtndisabled(true); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setBtndisabled(false)
			console.log("(Laplabarugi - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		if((uTokenob.username||"").toUpperCase()==="SUPERADMIN") 
			{uNavigate("/subdasar/user"); return}//-*/

		uDispatch({type: "set", effectApp: !uEfekapp});
		return ()=>{}
	},[])
	//React.useEffect(()=>{ uDispatch({type: "set", effectApp: !uEfekapp}); },[uEfekapp])

	//console.log("Laplabarugi - uActiveroute => "+(uActiveroute));
	return (
		<CCard className="mx-md-2 mx-lg-5">
		<CCardHeader><strong>Edit Akun Anda</strong></CCardHeader>
		<CCardBody>{initContentform()}</CCardBody>
		<CCardFooter className="text-center">
			<MyButton
				disabled={uBtndisabled}
				onClick={()=>hdlKlikSimpan()}
				iconname="cilCheckAlt"
				id="btnSimpan">Simpan</MyButton>
		</CCardFooter>
		</CCard>
	)
}	

export default Laplabarugi