import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	CSidebar,
	CSidebarBrand,
	CSidebarNav,
	CSidebarToggler,
	CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ContSidebarNav } from './ContSidebarNav'
const pjson 	= require('../../package.json');

// sidebar nav config
//import navigation from '../_nav'

const ContSidebar = () => {
	const uDispatch 	= useDispatch()
	const uUnfoldable 	= useSelector((state) => state.sidebarUnfoldable)
	const uSidebarshow	= useSelector((state) => state.sidebarShow)
	const uListnav 		= useSelector((state) => state.listNav)
	const uConfigappsObj= useSelector(state => state.uConfigappsObj) || {}; 

	//console.log("(ContSidebar) uListnav => "+JSON.stringify(uListnav));
	//console.log("(ContSidebar) navigation => "+JSON.stringify(navigation));
	return (
		<CSidebar position="fixed" unfoldable={uUnfoldable}
			visible={uSidebarshow}
			onVisibleChange={(visible) => {
				uDispatch({ type: 'set', sidebarShow: visible })
			}}
			className="bg-secondary">
		<CSidebarBrand className="d-none d-md-flex" to="/">
			<CImage src="/images/icon_logo_t_white.png" 
				className="sidebar-brand-narrow" height={40}/>

			<div className="classfontbrand sidebar-brand-full fs-2 text-white">
			<CImage src="/images/icon_logo_t.png" className="align-top" height={40}/>
			RIS&middot;Ecare
			</div>
		</CSidebarBrand>

		<CSidebarNav>
			<ContSidebarNav items={uListnav}/>
		</CSidebarNav>

		<CSidebarToggler className="d-none d-lg-flex"
			onClick={() => uDispatch({ type: 'set', sidebarUnfoldable: !uUnfoldable })}/>
		</CSidebar>
	)
}

export default React.memo(ContSidebar)