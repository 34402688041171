import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,CButton,CFormInput,CInputGroup,
	CLink,
	CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
	MyButton,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersublappiutang = (props) => {
	const { 
		setToken,setLoading, showToast 
	} = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	
	const hdlKlikView = () => {
		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		uHeaderActionObj.isHeaderreset = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("Headersublappiutang - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2 align-middle"
			height={30}/>
		<b>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</b>
		</div>

		<div className="ms-1">
			<MyButton className="py-0"
				iconname="cilFeaturedPlaylist"
				onClick={()=>hdlKlikView()}
				id="btnFilter">
			{uBahasaObj.word_filter || "Filter"}
			</MyButton>

			<span className="mx-1 d-none d-lg-inline">&middot;</span>
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="me-2 d-none d-md-inline">&middot;</span>
			(<strong id="idjmldata" className="text-primary">0</strong>&nbsp;
			{uBahasaObj.word_data||"Data"})
		</div>
		</>
	)
}

export default Headersublappiutang
