import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import PropTypes from 'prop-types';

const MyDialogsession = props => {	
	const {show,toggle,Countertime,onKlikyes,...rest} = props;
	const uBahasaob  = useSelector(state => state.listBahasa);

	return (
		<CModal
			alignment="center"
			visible={show}
			scrollable 
			{...rest}>
		<CModalHeader 
			className="bg-danger text-white"
			closeButton>
			<CModalTitle>
				<div className="d-none d-md-block">Alert: Waktu Session !</div>
				<small className="d-md-none">Alert: Waktu Session !</small>
			</CModalTitle>
		</CModalHeader>

		<CModalBody 
			style={{
			maxHeight:(window.innerHeight - 195),overflowY:"auto",overflowX:"hidden"
			}}>
			<div>
				Waktu Session Anda Tinggal:{" "}
				<big><b className={parseInt(Countertime)>=0?"text-primary":"text-danger"}>{UFunc.formatAngka(Countertime)}</b></big>
				{" "}detik...
			</div>

			<small className="d-block m-3">
				<p className="my-1">
				Ketika waktu Session <b>SUDAH <span className="text-danger">MINUS</span></b> dan berwarna <b className="text-danger">MERAH</b>,
				itu menandakan waktu Session ANDA <b>SUDAH HABIS</b>.
				</p>
				<p className="my-2 fst-italic">Jika SUDAH MINUS, Anda <b>TIDAK DAPAT</b> melakukan Pembaruan SESSION, dan <b className="text-primary">WAJIB melakukan LOGIN Ulang</b>..</p>
				<p className="my-1">
				Segera Lakukan Pembaruan untuk Mengupdate SESSION Anda, sebelum waktu anda habis dan <b className="text-danger">Berwarna MERAH</b>..
				</p>
			</small>

			<div>
			Tekan <b className="text-primary">YA</b> Untuk Pembaruan SESSION !
			</div>
		</CModalBody>
		<CModalFooter>
		<MyButton
			onClick={()=>onKlikyes()}
			id="btnDlgsessionYa">
			{uBahasaob.word_ya||"YA"}
		</MyButton>{" "}
		<CButton color="light" onClick={toggle}>{uBahasaob.word_tidak||"Tidak"}</CButton>
		</CModalFooter>
		</CModal>
	)
}
MyDialogsession.defaultProps = {
	show: false,
	toggle: () => { return false }
};
MyDialogsession.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	onKlikyes: PropTypes.func,
}

export default MyDialogsession;