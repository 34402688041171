import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CRow,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CImage,
  CPagination,
  CPaginationItem,
  CLink,
  CTooltip,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Userakses = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uIsShowview,setShowDlgview]			= React.useState(false);
	const [uDlgviewheader,setHeaderdlg]			= React.useState("");
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlgview = () =>{ setShowDlgview(false) }

	const hdlKlikRole = (_IDTABEL) => {}
	const hdlChangeCabangall	= (_EVN,_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL||0)
		if(_IDTABEL <= 0) return;

		const vIscheckedall = _EVN.target.checked;
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		//--PROSES_API--/
		apiProsescheckall(_IDTABEL,vIscheckedall);
		//--END PROSES_API--/
	}
	const hdlChangeCabangakses	= (_EVN,_IDTABEL) => {
		const vValueID	= _EVN.target.value;
		const vIschecked= _EVN.target.checked;

		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		//--PROSES_API--/
		apiProsescheckrow(_IDTABEL,vValueID,vIschecked)
		//--END PROSES_API--/
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(User - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_userakses || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		const vTokencabangArr = uTokenObj.user_cabangarr || uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		const vCabangAllowArr = uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems => 
				vTokencabangArr.includes((vItems.id||"").toString()) )

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1 text-start">{uBahasaObj.caption_datapengguna||"Data User"}</th>
			<th width="5%" className="px-1">&middot;</th>
			<th width="67%" className="px-1 d-none d-md-table-cell text-start">
				{uBahasaObj.word_cabang||"Unit Cabang"}
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,username,usercaption,hak,cabang_arr,init_process
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (usercaption||"").toUpperCase();

			const vLangshak	= uBahasaObj["hakuser_"+(hak||"").toLowerCase()] || (hak||"UNDF");

			const vUsername	= vKeyword==="" ? (username||"") 
				: (username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsercaption	= vKeyword==="" ? (usercaption||"") 
				: (usercaption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserhak	= vKeyword==="" ? (vLangshak) 
				: (vLangshak).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vCabangArr= cabang_arr || [];
			let vIscheckedall = true;
			vCabangAllowArr.forEach(vItems => {
				if(!vCabangArr.includes(vItems.id)) {
					vIscheckedall = false;
				} 
			});
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top"+(uRowselect===vKeys?"classrowselect":"")}>
				<td className="px-1 text-end">{vNumber}.</td>
				<td className="px-1 text-start">
					<span className="text-info">{UFunc.renderHTML(vUsercaption)}</span>
					{" "}&middot;{" "}
					<small className="text-primary fw-bold">({UFunc.renderHTML(vUsername)})</small>

					<div>{uBahasaObj.caption_hakakses||"Hak Akses"}: <span className="text-success">{UFunc.renderHTML(vUserhak)}</span></div>
				</td>
				
				<td className="px-1 text-center">
					<div className="d-none d-md-block">
					{(init_process||false)===true && (
						<CSpinner color="dark" size="sm"/>
					)}
					{(init_process||false)===false && (
						<CFormCheck size="sm"
							value="ALL"
							checked={vIscheckedall}
							onChange={(e)=>hdlChangeCabangall(e,id)}
							id={"inpcabangall"+id}/>
					)}
					</div>

					<div className="d-md-none">
						<CLink
							onClick={()=>{setIDselect(id);setShowDlgview(true)}}
							className="classcursorpointer py-1 px-2 border rounded bg-dark text-white">
							<CIcon icon="cilBuilding" height={15}/>
						</CLink>
					</div>
				</td>

				<td className="px-1 px-md-0 text-md-start d-none d-md-table-cell">
				{vCabangAllowArr.map((vItemcabang,vKeycabang)=>{
					return (
					<div style={{minWidth:200}}
						key={vKeycabang}
						className="d-inline-block ps-2 pe-1 bg-light border rounded me-2 mb-2">
					<CFormCheck size="sm" inline
						value={vItemcabang.id}
						label={vItemcabang.cabang_nama}
						checked={vCabangArr.includes(vItemcabang.id)}
						onChange={(e)=>hdlChangeCabangakses(e,id)}
						disabled={(init_process||false)}
						name={"inpcabangid"+id}
						id={"inpcabangid"+id+"_"+vItemcabang.id}/>
					</div>
					)
				})}
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uIsShowview) return (<></>)
		if(parseInt(uIDselect) <= 0) return (<></>);

		const vIdxObj		= UFunc.getObjectIndeks(uDatamainArr,uIDselect);
		const vCabangArr	= uDatamainArr[vIdxObj].cabang_arr || [];

		const vInisproses	= uDatamainArr[vIdxObj].init_process || false;

		const vTokencabangArr = uTokenObj.user_cabangarr || uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		//const vTokencabangArr = uTokenObj.user_cabangarr || [];
		const vCabangAllowArr = uTokenObj.userhak==="FREEROLE" ? uDatacabangArr :
			uDatacabangArr.filter(vItems => 
				vTokencabangArr.includes((vItems.id||"").toString())
			)

		let vIscheckedall = true;
		vCabangAllowArr.forEach(vItems => {
			if(!vCabangArr.includes(vItems.id)) {
				vIscheckedall = false;
			} 
		});

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="12" className="d-flex justify-content-between py-2">
			{uBahasaObj.caption_aksescabang||"Akses Unit Cabang"}
			<div>
				{vInisproses===true && (
					<CSpinner color="dark" size="sm"/>
				)}
				{vInisproses===false && (
				<CFormCheck inline size="sm"
					value="ALL"
					id={"inpformcabangall"}
					checked={vIscheckedall}
					onChange={(e)=>hdlChangeCabangall(e,uIDselect)}
					className=""/>
				)}
			</div>
		</CCol>
		<CCol xs="12" className="">
			<CInputGroup size="sm">
			{vCabangAllowArr.map((vItems,vKeys)=>{
			return (
				<div
					key={vKeys}
					className="d-inline-block ps-2 pe-1 bg-light border rounded me-2 mb-2"
					style={{minWidth:150}}>
				<CFormCheck inline size="sm"
					value={vItems.id}
					id={"inpformcabangid"+vItems.id}
					disabled={vInisproses} 
					label={vItems.cabang_nama}
					checked={vCabangArr.includes(vItems.id)}
					onChange={(e)=>hdlChangeCabangakses(e,uIDselect)}
					className="classcursorpointer"/>
				</div>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []

		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_userakses || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_userakses/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsescheckall = (_IDTABEL,_VALUECHECKED) => {
		_IDTABEL		= parseInt(_IDTABEL||0);
		_VALUECHECKED	= _VALUECHECKED||false;

		const vBtnsimpan= document.getElementById("btnDialogviewok");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)

		const vTokencabangArr = uTokenObj.userhak!=="FREEROLE" 
			? uTokenObj.user_cabangarr || []
			: uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		const vCabanginvisibleArr 	= uDatamainArr[vIdxObj].cabanginvisible_arr || [];

		let vTmpAll		= vCabanginvisibleArr;
		if(_VALUECHECKED) {
			vTokencabangArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems]);
		}

		uDatamainArr[vIdxObj].init_process	= true;
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangidlist : vTmpAll.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_userakses/pr_checked";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				uDatamainArr[vIdxObj].cabang_arr = vTmpAll;
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Userakses - apiProsescheckall) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setShowDlgview(false); setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setEfekview2(!uEfekview2);

			console.log("(Userakses - apiProsescheckall) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsescheckrow = (_IDTABEL,_VALUEID,_ISCHECKED) => {
		_IDTABEL		= parseInt(_IDTABEL||0);
		_VALUEID		= parseInt(_VALUEID||0).toString();
		_ISCHECKED		= _ISCHECKED||false;

		const vBtnsimpan= document.getElementById("btnDialogviewok");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		let vTmpChkArr	= uDatamainArr[vIdxObj].cabang_arr || [];
		if(_ISCHECKED) {
			vTmpChkArr	= [...vTmpChkArr,_VALUEID]
		} else {
			const vIdx = vTmpChkArr.findIndex(vItems => vItems.toString()===_VALUEID.toString());
			vTmpChkArr.splice(vIdx,1);
		}

		uDatamainArr[vIdxObj].init_process	= true;
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		//console.log("(Userakses - apiProsescheckrow) vTmpChkArr = "+JSON.stringify(vTmpChkArr))
		let vTimeout = setTimeout(()=>{
			uDatamainArr[vIdxObj].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)

			showToast(JSON.stringify(vTmpChkArr));
			uDatamainArr[vIdxObj].cabang_arr = vTmpChkArr;
			setEfekview2(!uEfekview2);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangidlist : vTmpChkArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_userakses/pr_checked";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				uDatamainArr[vIdxObj].cabang_arr = vTmpChkArr;
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Userakses - apiProsescheckrow) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setShowDlgview(false); setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setEfekview2(!uEfekview2);
			console.log("(Userakses - apiProsescheckrow) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			setShowDlgview(false);
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	/*React.useEffect(()=>{
		if(!uHandelTambah) return;

		setHeaderdlg((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setShowDlgview(true);
	},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!uIsShowview) { initFormAwal(); return; }

		const vIdxObj = UFunc.getObjectIndeks(uDatamainArr,uIDselect)
		setHeaderdlg("Akses User: "+uDatamainArr[vIdxObj].usercaption);

		//console.log("("+cComponentName+"[uIsShowview]) uIsShowview 1: "+uIsShowview);
	},[uIsShowview])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;

		const vTokencabangArr = uTokenObj.userhak!=="FREEROLE" 
			? uTokenObj.user_cabangarr || []
			: uDatacabangArr.map(vItems=>(vItems.id||"0").toString());

		uDatamainArr.map((vItems,vKeys)=>{
			const vCabangIDArr =  vItems.cabang_arr || [];
			const vCabangIDinvisibleArr = vCabangIDArr.filter(vItems=>
				!vTokencabangArr.includes(vItems.toString())
			)

			uDatamainArr[vKeys].cabanginvisible_arr = vCabangIDinvisibleArr;
		})
	},[uDatamainArr])
	//console.log("User - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>
		
		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uIsShowview} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggledlgview}
			renderContent={contentDlgview()}/>
		</>
	)
}	

export default Userakses