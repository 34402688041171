import React from 'react'
import { confirmable } from 'react-confirm'
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { UFunc } from "../helpers/functions"

const MyDialog = props => {
	//const cComponentname	= MyDialog.name;
	const { 
		show,proceed,dialogContent,dialogHeader,options,...rest
	} = props;

	const vBtnExecute	= options.tipe==="KONFIRM" ? "OK" : "Simpan";
	const vTeksHeader	= (dialogHeader==="" || options.tipe==="KONFIRM") ? "Konfirmasi" : dialogHeader;
	const vTeksIsi		= dialogContent==="" ? "{UNDEFINED}" : dialogContent;
	const [uEfekkomponen,setEfekkomponen] 	= React.useState(false);

	React.useEffect(()=>{
		if(!show) return;

		let vTimeout = setTimeout(() => {
			clearTimeout(vTimeout);
			setEfekkomponen(!uEfekkomponen)
		},350);	
	},[show]);//-*/
	React.useEffect(()=>{
		const vElBtnOK = document.getElementById("btnconfirmok");
		vElBtnOK && vElBtnOK.focus();
	},[uEfekkomponen]);
	const hdlToggle = () =>{ proceed(false); }

	return (
		<CModal scrollable 
			alignment={(options.centered)===true?"center":"top"}
			visible={show}
			{...rest}>
		<CModalHeader className="border-bottom border-bottom-warning"
			closeButton>
			<CModalTitle className="text-warning">{vTeksHeader}</CModalTitle>
		</CModalHeader>

		<CModalBody className="">{UFunc.renderHTML(vTeksIsi)}</CModalBody>
		<CModalFooter >
		<CButton 
			color={options.tipe!=="KONFIRM"? "info" : "warning" } 
			onClick={()=>proceed(true)}
			className="fw-bolder"
			id="btnconfirmok">{vBtnExecute}</CButton>{' '}
		<CButton color="light" onClick={()=>proceed(false)}>Batal</CButton>
		</CModalFooter>
		</CModal>
	);
}
MyDialog.defaultProps = {
	show: false,
	dialogContent: "{UNDEFINED}..",
	dialogHeader: "Konfirmasi",
	options: {tipe:"KONFIRM"},
};
MyDialog.propTypes = {
	show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
	proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
	dialogContent: PropTypes.string,  // arguments of your confirm function
	dialogHeader: PropTypes.string,  // arguments of your confirm function
	options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(MyDialog);